import React, { useEffect, useState, useRef } from "react";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
} from "reactstrap";

import {Line} from "react-chartjs-2";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchChartData } from "../services/map/mapService";
import { chart1_2_options } from "../variables/charts";
import {formatDateType} from "../utils/expressionForm";
import {DataMngTable} from "../components/Table/DataMngTable";
import {MockSensorHistory} from "../mockData/api/sensors/mockSensorHistory";

function DataGraph() {
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        const getData = async () => {
            try {
                const apiData = await fetchChartData(); // API 호출
                const formattedData = {
                    labels: apiData.labels,
                    datasets: apiData.datasets.map((dataset, index) => ({
                        ...dataset,
                        borderColor: ["#1f8ef1", "#d048b6", "#00d6b4"][index],
                        backgroundColor: ["rgba(29,140,248,0.2)", "rgba(72,72,176,0.1)", "rgba(0,214,180,0.2)"][index],
                        borderWidth: 2,
                        fill: index === 0 || index === 1,
                        pointBackgroundColor: ["#1f8ef1", "#d048b6", "#00d6b4"][index],
                    })),
                };
                setChartData(formattedData);
            } catch (error) {
                console.error("Error while fetching chart data:", error);
            }
        };

        getData();
    }, []);

    const startDateRef = useRef(null);
    const endDateRef = useRef(null);

    const options = chart1_2_options;

    const openStartDatePicker = () => {
        if (startDateRef.current) {
            startDateRef.current.flatpickr.open();
        }
    };

    const openEndDatePicker = () => {
        if (endDateRef.current) {
            endDateRef.current.flatpickr.open();
        }
    };

    const containerStyle = {
        display: "flex",
        justifyContent: "flex-start", // 각 항목 사이의 간격 조정
        alignItems: "center", // 세로 축 정렬
        gap: "20px", // 항목 간 간격
        margin: "20px 0", // 상하 여백 추가
    };

    if (!chartData) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <div className="content" style={{padding:"100px"}}>
                <div className="content">
                    <div>
                        <div>
                            <div style={containerStyle}>
                                <div style={containerStyle}>
                                    <div>지점위치</div>
                                    <select name="" id="">
                                        <option value="">A</option>
                                        <option value="">B</option>
                                        <option value="">C</option>
                                        <option value="">D</option>
                                    </select>
                                </div>
                                <div style={containerStyle}>
                                    <div>데이터구분</div>
                                    <select name="" id="">
                                        <option value="">분</option>
                                        <option value="">시</option>
                                    </select>
                                </div>
                                <div style={containerStyle}>
                                    <div>
                                        <Flatpickr
                                            ref={startDateRef}
                                            id="startDate"
                                            options={{
                                                dateFormat: "Y. m. d",
                                                altFormat: "Y. m. d",
                                                altInput: true,
                                            }}
                                            className="date-picker-input"
                                            placeholder="Select start date"
                                        />
                                        <button
                                            id="calendarIcon"
                                            onClick={openStartDatePicker}
                                            style={{
                                                marginLeft: "10px",
                                                cursor: "pointer",
                                                border: "none",
                                                background: "none",
                                            }}
                                        >
                                            📅
                                        </button>
                                    </div>

                                    {/* 종료 날짜 */}
                                    <div>
                                        <Flatpickr
                                            ref={endDateRef}
                                            id="endDate"
                                            options={{
                                                dateFormat: "Y. m. d",
                                                altFormat: "Y. m. d",
                                                altInput: true,
                                            }}
                                            className="date-picker-input"
                                            placeholder="Select end date"
                                        />
                                        <button
                                            id="calendarIconEnd"
                                            onClick={openEndDatePicker}
                                            style={{
                                                marginLeft: "10px",
                                                cursor: "pointer",
                                                border: "none",
                                                background: "none",
                                            }}
                                        >
                                            📅
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    <button>검색</button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Line data={chartData} options={options}/>
                        </div>
                    </div>
                </div>
                <div className="bw-scrollbar" style={{margin:"0 auto", width:"95%", height:"400px", overflowY:"auto"}}>
                    <div>
                        <table className="table" style={{textAlign: "center"}}>
                            <thead>
                            <tr>
                                <th>시간</th>
                                <th>수위</th>
                                <th>1분 예측</th>
                                <th>10분 예측</th>
                                <th>30분 예측</th>
                                <th>온도(°C)</th>
                                <th>습도(%)</th>
                                {/*<th>수위(mm)</th>*/}
                                <th>맨홀</th>
                            </tr>
                            </thead>
                            <DataMngTable dataRows={MockSensorHistory} />
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DataGraph;
