
/* manhole step 색상 정의 */

const stepColors = {
    1: '#007bff',
    2: '#ffc107',
    3: '#fd7e14',
    4: '#dc3545',
};
const stepBackgroundColors = {
    1: 'rgba(0,123,255, 0.5)',
    2: 'rgb(255,200,26)',
    3: 'rgb(251,137,40)',
    4: 'rgba(220,53,69)',
};

// manhole spot list, 지점 단계별 글꼴 색상
export const stepColor = (step) => {
    return stepColors[step] || '#808080';
}

// manhole marker background, 맨홀의 마커 색 지정
export const stepBackgroundColor = (step) => {
    return stepBackgroundColors[step] || '#808080';
}
