import React from "react";

function InsertTime({ insertTime }) {
    // 날짜 포맷팅 함수
    const formatInsertTime = (time) => {
        const date = new Date(time);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // 월은 0부터 시작
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");
        return `${year}년 ${month}월 ${day}일 ${hours}:${minutes}:${seconds}`;
    };

    return (
        <>
            <p className="c-insertTime">{formatInsertTime(insertTime)}</p>
        </>
    );
}

export default InsertTime;