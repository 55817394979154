import React, { useState } from 'react';
import PropTypes from 'prop-types';

/**
 * TabsLine 컴포넌트
 *
 * 사용법:
 * <TabsLine
 *   size="large" // (선택) "large"를 설정하면 큰 탭 스타일이 적용됩니다.
 *   tabs={[
 *     { id: 'tab1', label: '탭 1', content: <div>탭 1 내용</div> },
 *     { id: 'tab2', label: '탭 2', content: <div>탭 2 내용</div> },
 *     { id: 'tab3', label: '탭 3', content: <div>탭 3 내용</div> },
 *   ]}
 *   defaultTab="tab1" // (선택) 초기 활성 탭의 id
 *   onTabChange={(id) => console.log('활성 탭:', id)} // (선택) 탭 전환 시 호출되는 콜백 함수
 * />
 *
 * props:
 * - size: "large"를 전달하면 큰 탭 스타일 적용. (기본값 없음)
 * - tabs: 각 탭에 대한 정보를 담은 배열. 각 탭 객체는 { id, label, content } 형식입니다.
 * - defaultTab: (선택 사항) 초기 활성 탭의 id. 전달되지 않으면 tabs 배열의 첫 번째 탭이 활성화됩니다.
 * - onTabChange: (선택 사항) 탭 전환 시 호출되는 콜백 함수. 탭의 id를 인자로 받습니다.
 */
const TabsLine = ({ tabs = [], defaultTab, onTabChange }) => {
    // 초기 활성 탭 설정
    const initialTab = defaultTab || (tabs.length > 0 ? tabs[0].id : null);
    const [activeTab, setActiveTab] = useState(initialTab);


    const handleTabClick = (id) => {
        setActiveTab(id);
        if (onTabChange) {
            onTabChange(id);
        }
    };

    // 현재 활성 탭의 내용
    const activeTabData = tabs.find((tab) => tab.id === activeTab);
    const activeContent = activeTabData ? activeTabData.content : null;

    return (
        <div className="c-tabs-line">
            <div className="c-tabs-line__header">
                {tabs.map((tab) => (
                    <button
                        key={tab.id}
                        className={`c-tabs-line__button ${activeTab === tab.id ? 'active' : ''}`}
                        onClick={() => handleTabClick(tab.id)}
                    >
                        {tab.label}
                    </button>
                ))}
            </div>

            {activeContent && (
                <div className="c-tabs-line__content">
                    {activeContent}
                </div>
            )}
        </div>
    );
};

TabsLine.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.node.isRequired,
            content: PropTypes.node,
        })
    ),
    defaultTab: PropTypes.string,
    size: PropTypes.oneOf(['large']),
    onTabChange: PropTypes.func,
};

export default TabsLine;
