import { post } from "../../utils/api.js";

export const signIn = async (email, password) => {
  return post("api/auth/signin", {
    email: email,
    password: password,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const signOut = async () => {
  post("api/auth/signout")
    .then((response) => {
      console.log(response);
    })
    .catch((error) => {
      console.log(error);
    });
};
