import React, {useEffect, useState} from "react";
import {
    MockManholeMonitoring1, MockManholeMonitoring2, MockManholeMonitoring3,
    MockManholeNameList
} from "../mockData/api/monitoring/mockMonitoringInfo";
import ExampleMap from "../components/OpenLayersMap/ExampleMap";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import {MonitoringInfoTable} from "../components/Table/MonitoringTable";
import Select from "react-select";
import AddMarkerList from "../components/OpenLayersMap/AddMarkerList";
import {fromLonLat} from "ol/proj";



export default function Monitoring () {
    const [map, setMap] = useState(null);
    const [manholeNameList, setManholeNameList] = useState([]); //옵션 목록에 추가될 맨홀목록 (ajax)
    const [manholeData, setManholeData] = useState([]); //옵션 목록에서 선택한 맨홀지점
    const [monitoringInfo, setMonitoringInfo] = useState([]);//선택한 맨홀 정보 (ajax)

    //지도 위치 기반 manholeNameList 호출
    //지도 이동 감지 이벤트 추가 필요 > 지도 기반 맨홀 목록만 리렌더링
    useEffect(() => {
        setManholeNameList(MockManholeNameList);
        // const coordinate = 좌표값 인식
        // $.ajax("api/monitoring/spot", 'GET', coordinate, function~
        //     setManholeNameList(data.map((item) => ({
        //         value: item,
        //         label: item
        //     })));
        //     })
        //     .catch(err => console.log("맨홀 목록 호출 실패 :"+err));
    }, [manholeNameList]);

    useEffect(() => {
        if (!map) return;
        if (!manholeData) return;

        console.log("Updating manholeData for:", manholeData);

        switch (manholeData) { //선택한 select 옵션값 기준으로 ajax 호출 > 상세내용 받아옴
            case "A6": setMonitoringInfo(MockManholeMonitoring1); break;
            case "A20": setMonitoringInfo(MockManholeMonitoring2); break;
            case "A1": setMonitoringInfo(MockManholeMonitoring3); break;
            default: setMonitoringInfo([]); break;
        }

        // $.ajax(`api/monitoring/manhole/${manholeData}`, 'GET', function~ { })
        // setMonitoringInfo(data);
        // .catch((err) => console.log("맨홀 모니터링 상세정보 호출 실패 : "+err));
    },[manholeData]);


    //선택 맨홀 좌표값 가져와 지도 중심으로 적용함
    useEffect(() => {
        if(!monitoringInfo || monitoringInfo.length === 0) return;

        const {lon, lat} = monitoringInfo[0];
        const view = map.getView();
        view.setCenter(fromLonLat([lon, lat]));
    }, [monitoringInfo]);

return (<>
    <div className="content-center px-3">
        <Row className="bw-dashboardContent">
            <Card>
                <CardBody>
                    <Row>
                        <Col xs="6">
                            <Container className="mt-4 monitoring">
                                <Row style={{alignItems: "center"}}>
                                    <Col><h3>⦁ 측정소 정보 관리</h3></Col>
                                    <Col xs="3"><Select
                                        options={manholeNameList.sort((a,b) => { //지점명 오름차순 정렬
                                        const numA = parseInt(a.label.match(/\d+/)?.[0] || "0", 10);
                                        const numB = parseInt(b.label.match(/\d+/)?.[0] || "0", 10);
                                        return numA - numB;})}
                                        placeholder="지점을 선택해주세요"
                                        onChange={(selectedOption) => setManholeData(selectedOption?.value)} />
                                    </Col>
                                </Row>
                                <MonitoringInfoTable monitoringInfo={monitoringInfo}/>

                            </Container>
                        </Col>
                        <Col xs="6">
                            <ExampleMap onMapReady={setMap}>
                                {map && monitoringInfo.length > 0
                                    &&<AddMarkerList mapRef={map} data={monitoringInfo} />}
                            </ExampleMap>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Row>
    </div>
</>);
};