import React, { useEffect } from "react";
import Logo from "../../components/util/Logo";
import RainData from "../../components/util/RainData";
import { signIn } from "../../services/account/signInOutService.js";
import Notification from "./Notification";
import Menu from "./Menu"; // 메뉴 컴포넌트

/**
 * Header 컴포넌트
 * - 좌측: 로고
 * - 중앙: 날짜/시간 및 강수량 (RainData), 알림, 롤링메시지
 * - 우측: 메뉴 아이콘
 */
const Header = () => {
  useEffect(async () => {
    async function fetchAccountName() {
      await signIn("admin4@admin.com", "!Q2w3e4r")
        .then((accountDto) => {
          console.log(accountDto.name);
        })
        .catch((e) => console.log(e));
    }

    await fetchAccountName();
  }, []);

  return (
    <header className="header">
      <div className="header__logo">
        <Logo
          iconName="floodGuardFill"
          color="gray"
          size="medium"
          mainText="반지하 침수안전 시스템"
          subText="FloodGuard"
          href="/"
        />
      </div>
      <div className="header__center">
        {/* 날짜/시간과 강수량 정보를 한 곳에 표시 */}
        <RainData />
        {/* 알림과 롤링메시지 */}
        <Notification
          message="주의: 테스트 메시지가 길면 계속 롤링됩니다. 계속 롤링됩니다. 계속 롤링됩니다."
          rolling={true}
        />
      </div>
      <div className="header__right">
        <Menu />
      </div>
    </header>
  );
};

export default Header;
