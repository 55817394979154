import ReactDOMServer from 'react-dom/server';
import {stepBackgroundColor} from "../../assets/js/stepStyle";


//font awesome 아이콘 > 문자열로 변환
export const convertIconToSvg = (IconComponent, step) => {
    const svgString = ReactDOMServer.renderToStaticMarkup(
        <IconComponent className="popup" style={{
            color: stepBackgroundColor(step),
            stroke: "white",
            overflow: "visible"}}/>);
    return `data:image/svg+xml;base64,${btoa(svgString)}`;
};