import React, { useEffect, useState } from "react";
import {Card, CardBody, CardHeader, CardTitle, Col, Row, Table} from "reactstrap";
import ActiveDevice from "../components/TB/ActiveDevice";
import DeviceManagement from "views/DeviceManagement.js";
import EventHistory from "./EventHistory";
import SimpleTable from "../components/Table/SimpleTable";
import DeviceList from "./DeviceList";
import RssiGaugeGraph from "./RssiGaugeGraph";
import {getMockPredictionMonitoringData4} from "../mockData/api/prediction/mockPredictionMonitoringData";
import {getMockSensorData2} from "../mockData/api/sensors/mockSensorData";

export default function FacilityManagement(props) {
    const [deviceManagementHistory, setDeviceManagementHistory] = useState([]);
    const [eventHistory, setEventHistory] = useState([]);
    const [deviceData, setDeviceData] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState(null);


    useEffect(()=> {
        // 작업히스토리, 이벤트발생 히스토리
    },[])

    useEffect(() => {
        setDeviceData(getMockSensorData2());
        const interval = setInterval(() => {
            setDeviceData(getMockSensorData2());
        }, 1000);

        return () => clearInterval(interval); // Cleanup: 컴포넌트 언마운트 시 인터벌 제거
    }, []);

    useEffect(()=> {
        // 맨홀 데이터 (가져올때마다 갱신하기)
    },[deviceData])

    return (
        <>
            <div className="content-center px-3">
                <Row className="bw-dashboardContent">
                    {/* 왼쪽 영역 */}
                    <Col xs="4" className="bw-flexColumn">
                        <Card className="card-chart" /*style={{ height: "415%" }}*/>
                            <CardBody>
                                <DeviceList deviceData={deviceData} selectedDevice={selectedDevice} setSelectedDevice={setSelectedDevice}></DeviceList>
                            </CardBody>
                        </Card>
                    </Col>
                    {/* 중앙 영역 */}
                    <Col xs="4" className="bw-flexColumn">
                        <Row className="bw-flex--5" /*style={{ height: "100%" }}*/>
                            <ActiveDevice/>
                        </Row>
                        <Row className="bw-flex--5" /*style={{ height: "100%" }}*/>
                            <Card /*style={{ height: "95%" }}*/>
                                <CardBody>
                                    <RssiGaugeGraph rssiData={selectedDevice?.rssi ?? 0}></RssiGaugeGraph>
                                    <Table className="tablesorter" responsive>
                                        <thead className="text-primary">
                                        <tr>
                                            <td>RSSI</td>
                                            <td>battery</td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <td>{selectedDevice?.rssi ?? 0}</td>
                                        <td>{selectedDevice?.battery ?? 0}</td>
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Row>
                    </Col>
                    {/* 오른쪽 영역 */}
                    <Col xs="4" className="bw-flexColumn">
                        <Row className="bw-flex--5">
                            <Col xs="12" className="bw-height--100">
                                <Card className="card-chart">
                                    <CardBody>
                                        <DeviceManagement></DeviceManagement>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="bw-flex--5">
                            <Col xs="12">
                                <Card className="card-chart" /*style={{ height: "415%" }}*/>
                                    <CardBody>
                                        <EventHistory></EventHistory>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
    )
}