import React, { useState } from 'react';
import Button from '../components/util/Button';
import Tabs from '../components/util/Tabs';
import TabsLine from '../components/util/TabsLine';
import InputText from '../components/util/InputText';
import Dropdown from '../components/util/Dropdown';
import DateInput from '../components/util/Date';
import Period from '../components/util/Period';
import Checkbox from '../components/util/Checkbox';
import Radio from '../components/util/Radio';
import Switch from '../components/util/Switch';
import RadioGroup from '../components/util/RadioGroup';
import CheckboxGroup from '../components/util/CheckboxGroup';
import Badge from '../components/util/Badge';
import BadgeGroup from '../components/util/BadgeGroup';
import Card from '../components/util/Card';
import AccordionItem from '../components/util/AccordionItem';
import StepLine from '../components/util/StepLine';
import GroupMonitoringCard from '../components/util/GroupMonitoringCard';
import Table from '../components/util/Table';

// 예시 탭 데이터
const tabData = [
    {
        id: 'tab1',
        label: '탭 1',
        content: <div>탭 1의 내용입니다.</div>,
    },
    {
        id: 'tab2',
        label: '탭 2',
        content: <div>탭 2의 내용입니다.</div>,
    },
    {
        id: 'tab3',
        label: '탭 3',
        content: <div>탭 3의 내용입니다.</div>,
    },
];

const tabData2 = [
    {
        id: 'tab1',
        label: '탭 1',
        content: <div>탭 1의 내용입니다.</div>,
    },
    {
        id: 'tab2',
        label: '탭 2',
        content: <div>탭 2의 내용입니다.</div>,
    },
    {
        id: 'tab3',
        label: '탭 3',
        content: <div>탭 3의 내용입니다.</div>,
    },
    {
        id: 'tab4',
        label: '탭 4',
        content: <div>탭 4의 내용입니다.</div>,
    },
];

// Dropdown
const options = [
    { label: '안전단계', value: 'safe' },
    { label: '관심단계', value: 'concern' },
    { label: '주의단계', value: 'caution' },
    { label: '경계단계', value: 'warning' },
    { label: '심각단계', value: 'critical' },
];

// StepLine 예시 데이터
const stepData = [
    { label: "현재시간", value: "2025.03.10 23:10", subLabel: "현재수위", subValue: "5.2m" },
    { label: "주소", value: "서울특별시 광진구 긴고랑로 12" },
    {
      label: "예측수위",
      items: [
        { time: "1분", value: "6.2m" },
        { time: "10분", value: "6.5m" },
        { time: "30분", value: "6.9m" },
      ]
    },
    { label: "재해약자", value: "이*말", subLabel: "동행파트너", subValue: "김*원" },
    { label: "돌봄공무원", value: "이*말" },
  ];

function TestComponents() {
    // input text
    const [value, setValue] = useState("");

    // input date, period
    const [singleDate, setSingleDate] = useState('2025-03-25');
    const [startDate, setStartDate] = useState('2025-03-25');
    const [endDate, setEndDate] = useState('2025-03-30');

    // input checkbox, radio, siwch
    const [chk1, setChk1] = useState(false);
    const [radVal, setRadVal] = useState('option1');
    const [sw1, setSw1] = useState(false);

    // radio group, checkbox group
    const [radioValue, setRadioValue] = useState('option2');
    const [checkboxValues, setCheckboxValues] = useState(['A']);

    const radioOptions = [
        { label: '옵션 1', value: 'option1' },
        { label: '옵션 2', value: 'option2' },
        { label: '옵션 3', value: 'option3' },
    ];

    const checkboxOptions = [
        { label: '옵션 A', value: 'A' },
        { label: '옵션 B', value: 'B' },
        { label: '옵션 C', value: 'C' },
    ];

    // table data
    const columns = [
        { key: 'point', label: '지점' },
        { key: 'center', label: '센터' },
        { key: 'status', label: '상태' },
        { key: 'time', label: '시간' },
    ];

    const data = [
        { point: 'A206', center: 'C0', status: '이상', time: '2024-04-01 09:06:00' },
        { point: 'A207', center: 'C1', status: '정상', time: '2024-04-01 09:07:00' },
        { point: 'A208', center: 'C2', status: '경고', time: '2024-04-01 09:08:00' },
    ];

    return (
        <div className="test-page">
            {/* 버튼 섹션 */}
            <section className="test-block">
                <h2>Button Tests</h2>
                <div className="test-group">
                    <h4>button 기본</h4>
                    <Button
                        text="default"
                        onClick={() => console.log('기본 버튼')}
                    />
                </div>

                <div className="test-group">
                    <h4>button primary color</h4>
                    <Button
                        text="primary color"
                        variant="primary"
                        onClick={() => console.log('컬러 버튼')}
                    />
                </div>

                <div className="test-group">
                    <h4>button disabled</h4>
                    <Button
                        text="disabled"
                        variant="disabled"
                        onClick={() => console.log('disabled 버튼')}
                    />
                </div>

                <div className="test-group">
                    <h4>button icon primary</h4>
                    <Button
                        iconName="data"
                        iconColor="#fff"
                        variant="primary"
                        onClick={() => console.log('primary icon 버튼')}
                    />
                </div>

                <div className="test-group">
                    <h4>button icon basic</h4>
                    <Button
                        iconName="chart"
                        iconColor="#0094fa"
                        onClick={() => console.log('icon 버튼')}
                    />
                </div>

                <div className="test-group">
                    <h4>button icon + text</h4>
                    <Button
                        text="iconText"
                        iconName="data"
                        iconColor="#ff0000"
                        onClick={() => console.log('icon text 버튼')}
                    />
                </div>

                <div className="test-group">
                    <h4>button icon left + text</h4>
                    <Button
                        text="iconText"
                        iconName="down"
                        iconColor="#007bff"
                        iconPosition="left"
                        onClick={() => console.log('icon text 버튼')}
                    />
                </div>

                <div className="test-group">
                    <h4>button large</h4>
                    <Button
                        text="primary color"
                        variant="primary"
                        size="large"
                        onClick={() => console.log('큰 버튼')}
                    />
                </div>
            </section>

            {/* 탭 섹션 */}
            <section className="test-block">
                <h2>Tabs Tests</h2>
                <div className="test-group">
                    <h4>medium Tabs</h4>
                    <Tabs
                        tabs={tabData}
                        defaultTab="tab1"
                        onTabChange={(id) => console.log("활성 탭:", id)}
                    />
                </div>
                <div className="test-group">
                    <h4>Large Tabs</h4>
                    <Tabs
                        size="large"
                        tabs={tabData2}
                        defaultTab="tab2"
                        onTabChange={(id) => console.log("활성 탭:", id)}
                    />
                </div>
                <div className="test-group">
                    <h4>line Tabs</h4>
                    <TabsLine
                        tabs={tabData}
                        defaultTab="tab1"
                        onTabChange={(id) => console.log("활성 탭:", id)}
                    />
                </div>
            </section>

            {/* InputText 섹션 */}
            <section className="test-block">
                <h2>Input Text Tests</h2>
                <div className="test-group">
                    <h4>Small Input</h4>
                    <InputText
                        size="small"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        placeholder="Small Input"
                    />
                </div>
                <div className="test-group">
                    <h4>Medium Input</h4>
                    <InputText
                        size="medium"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        placeholder="Medium Input"
                    />
                </div>
                <div className="test-group">
                    <h4>Large Input</h4>
                    <InputText
                        size="large"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        placeholder="Large Input"
                    />
                </div>

                <div className="test-group">
                    <h4>Placeholder Variant</h4>
                    <InputText
                        size="medium"
                        variant="placeholder"
                        value=""
                        onChange={() => {
                        }}
                        placeholder="Placeholder Variant"
                    />
                </div>
                <div className="test-group">
                    <h4>Disabled Variant</h4>
                    <InputText
                        size="medium"
                        variant="disabled"
                        value="Disabled Input"
                        onChange={() => {
                        }}
                        placeholder="Disabled Input"
                    />
                </div>
                <div className="test-group">
                    <h4>Error Variant</h4>
                    <InputText
                        size="medium"
                        variant="error"
                        value="Error Input"
                        onChange={(e) => setValue(e.target.value)}
                        placeholder="Error Input"
                    />
                </div>
                <div className="test-group">
                    <h4>Success Variant</h4>
                    <InputText
                        size="medium"
                        variant="success"
                        value="Success Input"
                        onChange={(e) => setValue(e.target.value)}
                        placeholder="Success Input"
                    />
                </div>
            </section>

            {/* Dropdown 섹션 */}
            <section className="test-block">
                <h2>Dropdown Tests</h2>
                <div className="test-group">
                    <h4>dropdown small</h4>
                    <Dropdown
                        size="small"
                        placeholder="경계단계"
                        options={options}
                        defaultValue="warning"
                        onChange={(opt) => console.log('선택됨:', opt)}
                    />
                </div>
                <div className="test-group">
                    <h4>dropdown medium</h4>
                    <Dropdown
                        size="medium"
                        placeholder="주의단계"
                        options={options}
                        defaultValue="caution"
                        onChange={(opt) => console.log('선택됨:', opt)}
                    />
                </div>
                <div className="test-group">
                    <h4>dropdown large disabled</h4>
                    <Dropdown
                        size="large"
                        disabled
                        placeholder="경계단계"
                        options={options}
                        defaultValue="warning"
                        onChange={(opt) => console.log('선택됨:', opt)}
                    />
                </div>
            </section>

            {/* Date, Period */}
            <section className="test-block">
                <h2>Date, Period Tests</h2>
                <div className="test-group">
                    <h4>Date</h4>
                    <DateInput
                        size="small"
                        value={singleDate}
                        onChange={(val) => setSingleDate(val)}
                    />
                </div>
                <div className="test-group">
                    <h4>Period</h4>
                    <Period
                        size="medium"
                        startValue={startDate}
                        endValue={endDate}
                        onStartChange={(val) => setStartDate(val)}
                        onEndChange={(val) => setEndDate(val)}
                    />
                </div>
                <div className="test-group">
                    <h4>Disabled Period</h4>
                    <Period
                        size="large"
                        startValue="2025-04-01"
                        endValue="2025-04-10"
                        disabled
                    />
                </div>
            </section>

            {/* Checkbox, Radio, Switch*/}
            <section className="test-block">
                <h2>checkbox, radio, switch Tests</h2>
                <div className="test-group">
                    <h4>checkbox</h4>
                    <Checkbox checked={chk1} onChange={setChk1} label="체크박스"/>
                    <br/>
                    <Checkbox checked disabled label="비활성화 체크박스"/>
                </div>
                <div className="test-group">
                    <h4>checkbox group</h4>
                    <CheckboxGroup
                        options={checkboxOptions}
                        selectedValues={checkboxValues}
                        onChange={(newValues) => setCheckboxValues(newValues)}
                    />
                </div>
                <div className="test-group">
                    <h4>Radio</h4>
                    <Radio
                        checked={radVal === 'option1'}
                        onChange={() => setRadVal('option1')}
                        label="옵션 1"
                        name="radioGroup"
                    />
                    <br/>
                    <Radio
                        checked={radVal === 'option2'}
                        onChange={() => setRadVal('option2')}
                        label="옵션 2"
                        name="radioGroup"
                    />
                    <br/>
                    <Radio checked disabled label="비활성화 라디오" name="radioGroup"/>
                </div>
                <div className="test-group">
                    <h4>Radio Group</h4>
                    <RadioGroup
                        name="exampleRadioGroup"
                        options={radioOptions}
                        selectedValue={radioValue}
                        onChange={(newValue) => setRadioValue(newValue)}
                    />
                </div>
                <div className="test-group">
                    <h4>Switch</h4>
                    <Switch checked={sw1} onChange={setSw1}/>
                    <br/>
                    <Switch checked disabled/>
                </div>
            </section>

            {/* Badge */}
            <section className="test-block">
                <h2>Badge Tests</h2>
                <div className="test-group">
                    <h4>Solid / Small</h4>
                    <Badge text="정상 : 6" grade={0} variant="solid" size="small"/>
                    <Badge text="관심 : 6" grade={1} variant="solid" size="small"/>
                    <Badge text="주의 : 6" grade={2} variant="solid" size="small"/>
                    <Badge text="경계 : 6" grade={3} variant="solid" size="small"/>
                    <Badge text="심각 : 6" grade={4} variant="solid" size="small"/>
                </div>
                <div className="test-group">
                    <h4>Border / Medium</h4>
                    <Badge text="정상 : 6" grade={0} variant="border" size="medium"/>
                    <Badge text="관심 : 6" grade={1} variant="border" size="medium"/>
                    <Badge text="주의 : 6" grade={2} variant="border" size="medium"/>
                    <Badge text="경계 : 6" grade={3} variant="border" size="medium"/>
                    <Badge text="심각 : 6" grade={4} variant="border" size="medium"/>
                </div>
                <div className="test-group">
                    <h4>Solid / Medium</h4>
                    <Badge text="열림" grade={2} variant="solid" size="medium"/>
                    <Badge text="닫힘" grade={0} variant="solid" size="medium"/>
                </div>
                <div className="test-group">
                    <h4>Solid / Large</h4>
                    <Badge text="정상 : 6" grade={0} variant="solid" size="large"/>
                    <Badge text="관심 : 6" grade={1} variant="solid" size="large"/>
                    <Badge text="주의 : 6" grade={2} variant="solid" size="large"/>
                    <Badge text="경계 : 6" grade={3} variant="solid" size="large"/>
                    <Badge text="심각 : 6" grade={4} variant="solid" size="large"/>
                </div>
            </section>

            {/* Badge Group */}
            <section className="test-block">
                <h2>Badge Tests</h2>
                <div className="test-group">
                    <h4>1개 / 가운데 정렬</h4>
                    <div style={{width: '200px', border: '1px solid #ddd', padding: '10px'}}>
                        <BadgeGroup
                            items={[
                                {
                                    badgeProps: {
                                        text: '주의',
                                        grade: 2,
                                        variant: 'solid',
                                        size: 'small',
                                    },
                                },
                            ]}
                        />
                    </div>
                </div>
                <div className="test-group">
                    <h4>2개</h4>
                    <BadgeGroup
                        items={[
                            {
                                title: '현재',
                                badgeProps: {
                                    text: '주의',
                                    grade: 2,
                                    variant: 'solid',
                                    size: 'small',
                                },
                            },
                            {
                                title: '예측',
                                badgeProps: {
                                    text: '주의',
                                    grade: 2,
                                    variant: 'solid',
                                    size: 'small',
                                },
                            },
                        ]}
                    />
                </div>
                <div className="test-group">
                    <h4>3개 이상</h4>
                    <BadgeGroup
                        items={[
                            {
                                // title: '현재',
                                badgeProps: {
                                    text: '주의',
                                    grade: 2,
                                    variant: 'solid',
                                    size: 'small',
                                },
                            },
                            {
                                // title: '예측',
                                badgeProps: {
                                    text: '경계',
                                    grade: 3,
                                    variant: 'solid',
                                    size: 'small',
                                },
                            },
                            {
                                // title: '예상',
                                badgeProps: {
                                    text: '심각',
                                    grade: 4,
                                    variant: 'solid',
                                    size: 'small',
                                },
                            },
                        ]}
                    />
                </div>
            </section>

            {/* Card */}
            <section className="test-block">
                <h2>기본</h2>
                <div className="test-group">
                    <h4>Badge Card Tyoe</h4>
                    <div style={{width: '260px'}}>
                        <Card>
                            <Card.Header
                                title="타이틀"
                                titleVariant="success"
                                subTitle="서브타이틀"
                                // badgeProps={{text: 'Badge', grade: 2, variant: 'solid', size: 'small'}}
                            />
                            <Card.Body>
                                <BadgeGroup
                                    items={[
                                        {
                                            title: '현재',
                                            badgeProps: {
                                                text: '주의',
                                                grade: 2,
                                                variant: 'solid',
                                                size: 'small',
                                            },
                                        },
                                        {
                                            title: '예측',
                                            badgeProps: {
                                                text: '주의',
                                                grade: 2,
                                                variant: 'solid',
                                                size: 'small',
                                            },
                                        },
                                    ]}
                                />
                            </Card.Body>
                        </Card>
                    </div>
                    <div style={{width: '200px', marginTop: '20px'}}>
                        <Card>
                            <Card.Header
                                title="타이틀"
                                titleAlign="center"
                            />
                            <Card.Body>
                                <BadgeGroup
                                    items={[
                                        {
                                            badgeProps: {
                                                text: '안전',
                                                grade: 0,
                                                variant: 'solid',
                                                size: 'medium',
                                            },
                                        },
                                    ]}
                                />
                            </Card.Body>
                        </Card>
                    </div>

                </div>
                    <div className="test-group">
                        <h4>에러/심각</h4>
                        <div style={{width: '260px'}}>
                            <Card status="error">
                                <Card.Header
                                    iconName="notice"
                                    // iconColor="#FF6347"
                                    title="error"
                                    // subTitle="센서 1"
                                    badgeProps={{text: 'error', grade: 4, variant: 'solid', size: 'small'}}
                                />
                                <Card.Body>
                                    <p style={{margin: 0, fontSize: '1.5rem'}}>N/A</p>
                                </Card.Body>
                                <Card.Footer>
                                    <button onClick={() => console.log('자세히 보기')}>자세히 보기</button>
                                </Card.Footer>
                            </Card>
                        </div>
                    </div>
                    <div className="test-group">
                        <h4>성공/주의</h4>
                        <div style={{width: '300px'}}>
                            <Card status="success">
                                <Card.Header
                                    iconName="notice"
                                    // iconColor="#1E90FF"
                                    title="success"
                                    subTitle="상태 양호"
                                    badgeProps={{text: 'success', grade: 2, variant: 'border', size: 'medium'}}
                                />
                                <Card.Body>
                                    <p style={{margin: 0, fontSize: '2rem'}}>792 <span
                                        style={{fontSize: '1rem'}}>kW</span>
                                    </p>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                    <div className="test-group">
                        <h4>경계</h4>
                        <div style={{width: '300px'}}>
                            <Card status="warning">
                                <Card.Header
                                    iconName="notice"
                                    // iconColor="#1E90FF"
                                    title="warning"
                                    // subTitle="상태 양호"
                                    badgeProps={{text: 'warning', grade: 3, variant: 'solid', size: 'medium'}}
                                />
                                <Card.Body>
                                    <p style={{margin: 0, fontSize: '2rem'}}>792 <span
                                        style={{fontSize: '1rem'}}>kW</span>
                                    </p>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
            </section>

            {/* AccordionItem */}
            <section className="test-block">
                <h2>기본</h2>
                <div className="test-group">
                    <h4>Badge Carc Tyoe</h4>
                    <div style={{width: '260px'}}>
                        <AccordionItem
                            title="A5"
                            stateLevel ="2"
                            staticMode="true"
                            content={<div>경계 단계 상세 정보...</div>}
                        />
                        <AccordionItem
                            title="A6"
                            stateLevel ="3"
                            content={<div>심각 단계 상세 정보...</div>}
                        />
                        <AccordionItem
                            title="B3"
                            stateLevel ="4"
                            content={<div>안전 단계 상세 정보...</div>}
                        />
                    </div>
                </div>
            </section>

            {/* StepLine 섹션 */}
            <section className="test-block">
                <h2>StepLine Tests</h2>
                <div className="test-group">
                    <h4>StepLine Example</h4>
                    <div style={{ border: '1px solid #ddd', padding: '10px' }}>
                        {/* 
                            stateLevel: 0 ~ 4에 해당하는 단계 값에 따라 색상이 적용됩니다.
                            data: 각 항목에 대한 정보를 배열 형태로 전달합니다.
                        */}
                        <StepLine stateLevel={3} data={stepData} />
                    </div>

                    <AccordionItem
                        title="수위 및 예측 정보"
                        stateLevel ="3"  // 단계에 따라 색상이 적용됩니다.
                        defaultOpen={true}
                        content={<StepLine stateLevel={3} data={stepData} />}
                    />
                </div>
            </section>

            {/* Group Monitoring Card 섹션 */}
            <section className="test-block">
                <h2>Group Monitoring Card Tests</h2>
                <div className="test-group">
                    <h4>Group Monitoring Card Example</h4>
                    <div>
                        {/* 예: 배지가 2개 */}
                        <GroupMonitoringCard
                            title="효서로 145번길"
                            totalPoints={6}
                            badges={[
                                { text: "정상 : 6", grade: 0, variant: "solid", size: "small" },
                                { text: "이상 : 1", grade: 4, variant: "solid", size: "small" },
                            ]}
                        />

                        {/* 예: 배지가 4개 */}
                        <GroupMonitoringCard
                            title="효서로 145번길"
                            totalPoints={6}
                            badges={[
                                { text: "관심 : 6", grade: 1, variant: "solid", size: "small" },
                                { text: "주의 : 6", grade: 2, variant: "solid", size: "small" },
                                { text: "경계 : 6", grade: 3, variant: "solid", size: "small" },
                                { text: "심각 : 6", grade: 4, variant: "solid", size: "small" },
                            ]}
                        />
                    </div>
                </div>
            </section>

            {/* Table 섹션 */}
            <section className="test-block">
                <h2>Table Tests</h2>
                <div className="test-group">
                    <h4>Table Example</h4>
                    <div>
                        <Table columns={columns} data={data} />

                        <Table columns={columns} data={data} rowHeaderKey="point" />
                    </div>
                </div>
            </section>
        </div>
    );
}

export default TestComponents;
