import React from 'react';
import PropTypes from 'prop-types';
import Radio from './Radio';

/**
 * RadioGroup 컴포넌트
 *
 * 사용 예시:
 * <RadioGroup
 *   name="exampleRadioGroup"
 *   options={[
 *     { label: '옵션 1', value: 'option1' },
 *     { label: '옵션 2', value: 'option2' },
 *     { label: '옵션 3', value: 'option3' },
 *   ]}
 *   selectedValue="option2"
 *   onChange={(newValue) => console.log('선택된 값:', newValue)}
 *   disabled={false}
 * />
 */
const RadioGroup = ({ options, name, selectedValue, onChange, disabled }) => {
    return (
        <div className="c-radio-group">
            {options.map(option => (
                <Radio
                    key={option.value}
                    name={name}
                    label={option.label}
                    checked={selectedValue === option.value}
                    onChange={() => onChange(option.value)}
                    disabled={disabled}
                />
            ))}
        </div>
    );
};

RadioGroup.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
        })
    ).isRequired,
    name: PropTypes.string.isRequired,
    selectedValue: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

RadioGroup.defaultProps = {
    selectedValue: '',
    disabled: false,
};

export default RadioGroup;
