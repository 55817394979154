import React from 'react';
import PropTypes from 'prop-types';

/**
 * Radio 컴포넌트
 *
 * 사용 예시:
 * <Radio
 *   checked={checked}
 *   onChange={(newVal) => setChecked(newVal)}
 *   disabled={false}
 *   label="라디오 예시"
 />
 */
const Radio = ({ checked, onChange, disabled, label, name }) => {
    const handleChange = (e) => {
        if (!disabled && onChange) {
            onChange(true); // 라디오는 true/false 대신 "선택됨"만 반환
        }
    };

    return (
        <label className={`c-radio ${checked ? 'c-radio--checked' : ''} ${disabled ? 'c-radio--disabled' : ''}`}>
            <input
                type="radio"
                className="c-radio__input"
                checked={checked}
                onChange={handleChange}
                disabled={disabled}
                name={name}
            />
            <span className="c-radio__circle" />
            {label && <span className="c-radio__label">{label}</span>}
        </label>
    );
};

Radio.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string, // 라디오 그룹을 구분하기 위해 name을 사용
};

Radio.defaultProps = {
    checked: false,
    disabled: false,
    label: '',
    name: '',
};

export default Radio;
