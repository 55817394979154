import React from 'react';
import PropTypes from 'prop-types';
import icons from './Icons';

/**
 * Rainfall 컴포넌트
 *
 * 사용 예시:
 * <Rainfall
 *   iconLeft="rain"        // 왼쪽 아이콘 (Icons.js에서 key)
 *   iconRight="doubleArrow"    // 오른쪽 아이콘 (Icons.js에서 key) up: doubleArrow, down: doubleArrowDown, line: noneLine
 *   color="#3CB371"        // 텍스트 및 아이콘 색상 녹색: #3dd990 파랑: #0094fa 빨강: #ff6462
 *   label="강수량"          // "강수량", "강수확률" 등 원하는 텍스트
 *   value={200}            // 강수량(숫자)
 *   unit="mm"              // 단위 ("mm", "m", ...)
 * />
 */
function Rainfall({
                      iconLeft = 'rain',
                      iconRight = 'upArrow',
                      color = '#3dd990',
                      label = '강수량',
                      value = 200,
                      unit = 'mm',
                  }) {
    const IconLeftComponent = icons[iconLeft];
    const IconRightComponent = icons[iconRight];

    return (
        <div className="c-rainfall" style={{ color }}>
            {/* 왼쪽 아이콘 */}
            {IconLeftComponent && (
                <span className="c-rainfall__icon-left">
                  <IconLeftComponent style={{ fill: color, stroke: color }} />
                </span>
            )}

            {/* 라벨 (강수량 등) */}
            <span className="c-rainfall__label">{label}</span>

            {/* 값 + 단위 */}
            <span className="c-rainfall__value">
                {value}
                {unit}
            </span>

            {/* 오른쪽 아이콘 (상승/하강/변동 없음 등) */}
            {IconRightComponent && (
                <span className="c-rainfall__icon-right">
          <IconRightComponent style={{ fill: color, stroke: color }} />
        </span>
            )}
        </div>
    );
}

Rainfall.propTypes = {
    iconLeft: PropTypes.string,
    iconRight: PropTypes.string,
    color: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.number,
    unit: PropTypes.string,
};

export default Rainfall;
