import React, { useState, useRef, useEffect } from "react";
import { Table, FormGroup, Label, Input } from "reactstrap";
import ToggleSelect from "./ToggleSelect";
import {formatAnonymous, formatDateType, formatWaterLevel} from "../../utils/expressionForm";
import {stepColor} from "../../assets/js/stepStyle";

const Accordion = ({ items }) => {
    const [openIndex, setOpenIndex] = useState(null);
    const contentRefs = useRef([]);

    useEffect(() => {
        if (openIndex !== null && contentRefs.current[openIndex]) {
            contentRefs.current[openIndex].style.height = `${contentRefs.current[openIndex].scrollHeight}px`;
        }
        stepColor();
    }, [openIndex]);

    const toggleAccordion = (index) => {
        if (openIndex === index) {
            contentRefs.current[index].style.height = "0px"; // 닫을 때
            setTimeout(() => setOpenIndex(null), 300); // 애니메이션 후 상태 변경
        } else {
            setOpenIndex(index);
        }
    };

    return (
        <div className="accordion-container">
            {items.map((item, index) => (
                <div key={item.name} data-key={item.name} className="accordion-item">
                    {/* 아코디언 제목 */}
                    <button
                        className={`accordion-header ${openIndex === index ? "active" : ""}`}
                        onClick={() => toggleAccordion(index)}
                    >
                        {item.name}
                        <span className="arrow">{openIndex === index ? "▲" : "▼"}</span>
                    </button>

                    {/* 아코디언 컨텐츠 (동적 높이 적용) */}
                    <div
                        ref={(el) => (contentRefs.current[index] = el)}
                        className="accordion-content"
                        style={{ height: openIndex === index ? `${contentRefs.current[index].scrollHeight}px` : "0px" }}
                    >
                        <div className="accordion-inner">
                            <Content spot={item} />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export function Content({spot}) {
    return (<>
        <Table>
            <tbody>
            <tr>
                <th>현재시간</th>
                <td>{formatDateType(spot.time)}</td>
                <th>현재수위</th>
                <td>{formatWaterLevel(spot.waterLevel)}</td>
            </tr>
            <tr>
                <th colSpan="2" rowSpan="3">예측수위</th>
                <th>1분</th>
                <td>{formatWaterLevel(spot.afterOne)}</td>
            </tr>
            <tr>
                <th>10분</th>
                <td>{formatWaterLevel(spot.afterTen)}</td>
            </tr>
            <tr>
                <th>30분</th>
                <td>{formatWaterLevel(spot.afterThirty)}</td>
            </tr>
            <tr>
                <th>재해약자</th>
                <td>{formatAnonymous(spot.rep)}</td>
                <th>동행파트너</th>
                <td>{formatAnonymous(spot.accompanyingPartner)}</td>
            </tr>
            <tr>
                <th>동행서비스 알림</th>
                <td colSpan="3">
                    <ToggleSelect
                        options={[1, 2, 3, 4]}
                        defaultSwitchState={true} // 기본적으로 ON
                        defaultSelectedOption={spot.step} // 기본 선택 옵션
                    />
                </td>
            </tr>
            </tbody>
        </Table>
    </>);
}
//
//     return (
//         <div className="accordion-container">
//             {items.map((item, index) => (
//                 <div key={index} className="accordion-item">
//                     {/* 아코디언 제목 */}
//                     <button
//                         className={`accordion-header ${openIndex === index ? "active" : ""}`}
//                         onClick={() => toggleAccordion(index)}
//                     >
//                         {item.title}
//                         <span className="arrow">{openIndex === index ? "▲" : "▼"}</span>
//                     </button>
//
//                     {/* 아코디언 컨텐츠 (동적 높이 적용) */}
//                     <div
//                         ref={(el) => (contentRefs.current[index] = el)}
//                         className="accordion-content"
//                         style={{ height: openIndex === index ? `${contentRefs.current[index].scrollHeight}px` : "0px" }}
//                     >
//                         <div className="accordion-inner">{item.content}</div>
//                     </div>
//                 </div>
//             ))}
//         </div>
//     );
// };

export default Accordion;
