/*!

=========================================================
* Black Dashboard React v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import Icons from "views/Icons.js";
import Notifications from "views/Notifications.js";
import TableList from "views/TableList.js";
import Typography from "views/Typography.js";
import UserProfile from "views/UserProfile.js";
import FacilityManagement from "views/FacilityManagement.js";
import TestComponents from "views/TestComponents";
import DesignLayout from "./views/DesignLayout";
import Login from "views/Login";
// import ExampleMap from "./components/OpenLayersMap/ExampleMap.js";
import ExampleMap from "./components/OpenLayersMap/ExampleMap3.js";
import UserManagement from "./views/UserManagement.js";
import DataGraph from "./views/DataGraph";
import Map from "./views/Map";
import Monitoring from "./views/Monitoring";
import TestIcons from "./views/TestIcons";


var routes = [
  {
    path: "/dashboard",
    name: "대시보드",
    icon: "tim-icons icon-chart-pie-36",
    component: <Dashboard />,
    layout: "/admin",
  },
  {
    path: "/icons",
    name: "아이콘 모음",
    icon: "tim-icons icon-atom",
    component: <Icons />,
    layout: "/admin",
  },
  {
    path: "/map",
    name: "지도",
    icon: "tim-icons icon-pin",
    component: <Map />,
    layout: "/admin",
  },
  // {
  //   path: "/map2",
  //   name: "지도2",
  //   icon: "tim-icons icon-pin",
  //   component: <ExampleMap />,
  //   layout: "/admin",
  // },
  {
    path: "/facility-management",
    name: "시설 관리",
    icon: "tim-icons icon-single-02",
    component: <FacilityManagement />,
    layout: "/admin",
  },
  {
    path: "/monitoring",
    name: "맨홀 모니터링",
    icon: "tim-icons icon-tv-2",
    component: <Monitoring />,
    layout: "/admin",
  },
  {
    path: "/notifications",
    name: "알림",
    icon: "tim-icons icon-bell-55",
    component: <Notifications />,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: "내 정보",
    icon: "tim-icons icon-single-02",
    component: <UserProfile />,
    layout: "/admin",
  },
  {
    path: "/user-management",
    name: "사용자 관리",
    icon: "tim-icons icon-single-02",
    component: <UserManagement />,
    layout: "/admin",
  },
  {
    path: "/tables",
    name: "표 예제",
    icon: "tim-icons icon-puzzle-10",
    component: <TableList />,
    layout: "/admin",
  },
  {
    path: "/typography",
    name: "타이포그래피",
    icon: "tim-icons icon-align-center",
    component: <Typography />,
    layout: "/admin",
  },
  {
    path: "/data",
    name: "데이터 관리",
    icon: "tim-icons icon-align-center",
    component: <DataGraph />,
    layout: "/admin",
  },
  {
    path: "/testComponents",
    name: "디자인 변경 컴포넌트",
    icon: "tim-icons icon-align-center",
    component: <TestComponents />,
    layout: "/admin",
  },
  {
    path: "/testIcons",
    name: "svg icon test",
    icon: "tim-icons icon-align-center",
    component: <TestIcons />,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "로그인",
    icon: "tim-icons icon-align-center",
    component: <Login />,
    layout: "/login",
  },
  {
    path: "/design",
    name: "Design Layout",
    icon: "tim-icons icon-align-center",
    component: <DesignLayout />,
    layout: "/design",
  },
];
export default routes;
