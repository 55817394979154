// Login.js
import React, { useState } from 'react';
import Logo from '../components/util/Logo';
import Button from '../components/util/Button';
import InputText from '../components/util/InputText';
import "../assets/css/design/login.css";

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        // 로그인 처리 로직 추가 (예: API 호출)
        console.log('로그인 시도:', { username, password });
    };

    return (
        <div className="login">
            <div className="login__header">
                <ul className="login__logos">
                    <li className="login__logos-item">
                        <Logo
                            iconName="floodGuardFill"
                            color="gray"
                            size="large"
                            mainText="반지하 침수안전 시스템"
                            subText="FloodGuard"
                            href="/"
                        />
                    </li>
                    <li className="login__logos-item">
                        <Logo
                            iconName="gwangjin"
                            width="11.25rem"
                            height="3.125rem"
                            href="/"
                        />
                    </li>
                </ul>
            </div>
            <div className="login__content">
                <div className="login__bg-img"></div>
                <div className="login__container">
                    <div className="login__box">
                        <h2 className="login__title">Login</h2>
                        <form className="login__form" onSubmit={handleSubmit}>
                            <div className="login__field">
                                <label className="login__label" htmlFor="username">
                                    아이디
                                </label>
                                <InputText
                                    id="username"
                                    size="login"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </div>
                            <div className="login__field">
                                <label className="login__label" htmlFor="password">
                                    비밀번호
                                </label>
                                <InputText
                                    id="password"
                                    type="password"
                                    size="login"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <Button
                                text="로그인"
                                variant="primary"
                                fullWidth={true}
                                size="large"
                                onClick={() => console.log('로그인')}
                            />
                        </form>
                    </div>
                    <p className="login__contact">
                        <span className="login__contact-title">담당자 문의</span>
                        <span className="login__contact-num">010-0000-0000</span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Login;
