import monotoneChainConvexHull from 'monotone-chain-convex-hull';
import {useEffect} from "react";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import {Feature} from "ol";
import {Fill, Icon, Stroke, Style} from "ol/style";
import {LineString, Point, Polygon} from "ol/geom";
import {FaMapMarker } from "react-icons/fa";
import {convertIconToSvg} from "./IconExtract";
import CircleStyle from "ol/style/Circle";
import Cluster from 'ol/source/Cluster.js';
import {createEmpty, extend, getHeight, getWidth} from "ol/extent";
import {fromLonLat} from "ol/proj";
import Text from 'ol/style/Text.js';


const AddMarkerList = ({mapRef, data, setSelectedManhole}) => {
    useEffect(() => {
        if(!mapRef) return;

        // 개별 응닶값
        const vectorSource = new VectorSource({});
        createFeature(vectorSource, data || []);

        // 개별 응답값을 그룹화
        const clusterSource = new Cluster({
            distance: 50,                   //그룹마커 간격 조정
            source: vectorSource,           //ajax 응답값
        });

        // clusterHulls를 이용하여 hover 효과 지정
        const clusterHulls = new VectorLayer({
            source: clusterSource,
            style: clusterHullStyle,
        });

        // length로 그룹마커 or 단일마커 style 지정
        const clusters = new VectorLayer({
            source: clusterSource,
            style: clusterStyle,
        });
        //group marker click 시 지도 확장 및 단일마커로 변경
        const clusterCircles = new VectorLayer({
            source: clusterSource,
            style: clusterCircleStyle,
        });

        mapRef.addLayer(clusterHulls);
        mapRef.addLayer(clusters);
        mapRef.addLayer(clusterCircles);

        // 이벤트 등록
        if (typeof setSelectedManhole === "function") handleAction(mapRef, clusters, clusterHulls, clusterCircles, data, setSelectedManhole, "on");

        return () => {
            mapRef.removeLayer(clusterHulls);
            mapRef.removeLayer(clusters);
            mapRef.removeLayer(clusterCircles);
            if (typeof setSelectedManhole === "function") handleAction(mapRef, clusters, clusterHulls, clusterCircles, data, setSelectedManhole, "off");
        };

    }, [mapRef, data, setSelectedManhole]);
    return null;
};

export default AddMarkerList;

const circleDistanceMultiplier = 1; //마커 배치의 간격 배율
const circleFootSeparation = 28; //마커 기본 간격
const circleStartAngle = Math.PI / 2; //마커 원형 배치 각도 (기본값 90도)

const convexHullFill = new Fill({ color: 'rgba(255, 153, 0, 0.4)',});
const convexHullStroke = new Stroke({ color: 'rgb(0,10,204)', width: 1.5, }); //클러스터 중심과 마커를 잇는 선스타일
const outerCircleFill = new Fill({ color: 'rgba(199,102,255,0.3)', });
const innerCircleFill = new Fill({ color: 'rgba(191,118,218,0.7)', });

const markerTextStyle = {
    textFill: new Fill({color: "#fff"}),
    textStroke: new Stroke({ color: 'rgba(0,0,0,0.5)', width: 3, }),
    font: "bold 14px Arial",
};

const innerCircle = new CircleStyle({ radius: 14, fill: innerCircleFill, });
const outerCircle = new CircleStyle({ radius: 20, fill: outerCircleFill, });
let clickFeature, clickResolution; //clickFeature: 사용자가 클릭한 클러스터 객체, clickResolution: 클릭 당시의 맵 해상도

//단일마커 위치, 모양, 색상 지정
function clusterMemberStyle (clusterMember) {
    return new Style({
        geometry: clusterMember.getGeometry(),
        image: new Icon({
            src: convertIconToSvg(FaMapMarker , clusterMember.get("step")),
            scale: 3,
        }),
        text: new Text({
            text: clusterMember.get("name") || "",
            fill: markerTextStyle.textFill,
            stroke: markerTextStyle.textStroke,
            font: markerTextStyle.font,
            offsetY: -5,
        })
    });
}

//클러스터 length에 따른 마커 모양 지정 (단일마커, 그룹마커)
function clusterStyle(feature) {
    const size = feature.get('features').length;
    if(size>1) {
        return [
            new Style({
                image: outerCircle,
            }),
            new Style({
                image: innerCircle,
                text: new Text({
                    text: size.toString(),
                    fill: markerTextStyle.textFill,
                    stroke: markerTextStyle.textStroke,
                    font: markerTextStyle.font,
                }),
            }),
        ];
    }
    return clusterMemberStyle(feature.get('features')[0]);
}

//그룹마커 클릭 시 > 단일 마커 재배치
function clusterCircleStyle (cluster, resolution) {
    if( cluster !== clickFeature || resolution !== clickResolution ) {
        return null;
    }
    const clusterMembers = cluster.get('features'); //그룹마커의 단일마커 목록
    const centerCoordinates = cluster.getGeometry().getCoordinates(); //그룹마커 중심 좌표

    return generatePointsCircle( //좌표 배열 계산식
        clusterMembers.length,
        cluster.getGeometry().getCoordinates(),
        resolution,
    ).reduce((styles, coordinates, i) => { //중심 좌표를 기준으로 단일마커 재배치
        const line = new LineString([centerCoordinates, coordinates]); //선 스타일
        styles.unshift(new Style({
            geometry:line,
            stroke:convexHullStroke,
        }), );

        const point = new Point(coordinates); //점 스타일
        styles.push(clusterMemberStyle(new Feature({
            ...clusterMembers[i].getProperties(),
            geometry: point,
        }),),);

        return styles;
    }, []);
}

//n개의 마커 중심에 그룹마커를 배치하기 위한 좌표 배열 계산식
function generatePointsCircle(count, clusterCenter, resolution) {
    const circumference = circleDistanceMultiplier * circleFootSeparation * (2 + count); //둘레 계산
    let leglength = circumference / (Math.PI * 2); //반지름 게산
    const angleStep = (Math.PI * 2) / count; //각도 간격
    const res = [];
    let angle;

    //반지름 최소값 35 pixel > 마커가 서로 겹치지 않게 함 (resolution 해상도를 곱해서 동적으로 조정함)
    leglength = Math.max(leglength, 35) * resolution;

    for(let i = 0; i< count; ++i) {
        //나선형, 시계방향으로 계산
        angle = circleStartAngle + i * angleStep;
        res.push([
            clusterCenter[0] + leglength * Math.cos(angle), //x좌표
            clusterCenter[1] + leglength * Math.sin(angle), //y좌표
        ]);
    }
    return res;
}

//hover 스타일 지정
let hoverFeature;
function clusterHullStyle(cluster) {
    if(cluster !== hoverFeature) {
        return null;
    }
    const points = cluster.get('features').map((feature) => feature.getGeometry().getCoordinates());
    return new Style({
        geometry: new Polygon([monotoneChainConvexHull(points)]),
        fill: convexHullFill,
        stroke: convexHullStroke
    });
}

//마우스 포인터 설정
function pointerMove(mapRef, clusters, clusterHulls, event) {
    clusters.getFeatures(event.pixel).then((features) => {
        // 클릭 가능하다는 표시를 나타내는 style 지정
        mapRef.getTargetElement().style.cursor = hoverFeature ? 'pointer' : '';

        if (features[0] !== hoverFeature) {
            hoverFeature = features[0];
            clusterHulls.setStyle(clusterHullStyle); // 그룹마커 마우스 hover 효과 (n개의 마커 위치를 보여줌)
        }
    });
}

//마커 클릭 시의 동작
function clickMarker(mapRef, clusters, clusterCircles, event, data, setSelectedManhole) {
    clusters.getFeatures(event.pixel)
        .then((features) => {
            const feature = features[0].get('features');

            //그룹마커 클릭
            if (feature && feature.length > 1) {
                setSelectedManhole(null);
                expandMarker(mapRef, features, clusterCircles);
                return;
            }

            //단일마커 클릭 > 선택 맨홀 정보를 테이블로 전달
            const clickedFeatureName = feature[0].get("name");
            if (data && clickedFeatureName) {
                const findSpot = data.find(manhole => manhole.name === clickedFeatureName);
                if (findSpot) setSelectedManhole(findSpot);
                return;
            }
        })
        .catch(() => {
            setSelectedManhole(null);
        });
}

//그룹마커 클릭 > 지도확대, 마커 펼치기
function expandMarker(mapRef, features, clusterCircles) {
    const clusterMembers = features[0].get('features');

    if (clusterMembers.length > 1) { // 클릭한 클러스터 범위 계산
        const extent = createEmpty();
        clusterMembers.forEach((feature) =>
            extend(extent, feature.getGeometry().getExtent()),
        );
        const view = mapRef.getView();
        const resolution = mapRef.getView().getResolution();

        if (
            view.getZoom() === view.getMaxZoom() ||
            (getWidth(extent) < resolution && getHeight(extent) < resolution)
        ) {
            // 그룹 마커 확장
            clickFeature = features[0];
            clickResolution = resolution;
            clusterCircles.setStyle(clusterCircleStyle);
        } else {
            // 클릭한 클러스터 범위로 확대
            view.fit(extent, {
                duration: 500,
                padding: [50, 50, 50, 50],
                maxZoom: 20, //최대 확대(줌) 제한
            });
        }
    }
}

//이벤트 관리
function handleAction(mapRef, clusters, clusterHulls, clusterCircles, data, setSelectedManhole, mode = "on") {
    if (!mapRef) return;

    // 목록
    const eventHandlers = [
        { event: ["movestart", "wheel"], handler: () => setSelectedManhole(null) },
        { event: "pointermove", handler: (event) => pointerMove(mapRef, clusters, clusterHulls, event) },
        { event: "click", handler: (event) => clickMarker(mapRef, clusters, clusterCircles, event, data, setSelectedManhole) },
    ];

    eventHandlers.forEach(({ event, handler }) => {
        if (mode === "on") {
            mapRef.on(event, handler);
        } else if (mode === "off") {
            mapRef.un(event, handler);
        }
    });
}

//ajax 호출을 통해 받은 맨홀 마커들의 정보로 feature 생성, vectorSource에 추가함
function createFeature(vectorSource, data) {

    data.forEach((manhole) => {
        const feature = new Feature(
            Object.fromEntries(
                Object.entries({
                    geometry: new Point(fromLonLat([manhole.lon, manhole.lat])),
                    name: manhole.name,
                    step: manhole.step,
                }).filter(([_, value]) => value != null)
        ));


        vectorSource.addFeature(feature);
    });
}
