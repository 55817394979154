import React from 'react';
import PropTypes from 'prop-types';
import icons from './Icons';

const Button = ({
                    text,                                 // 버튼에 들어갈 텍스트
                    iconName = null,                      // iconName을 prop으로 전달 (예: 'add', 'edit', 'delete')
                    iconColor = 'var(--primary-color)',   // 아이콘에 적용할 컬러 (CSS 변수나 직접 색상 코드 전달 가능)
                    iconPosition = 'right',               // 아이콘 위치: 'left' 또는 'right' (기본은 오른쪽)
                    variant = 'basic',                    // 버튼 스타일: 'basic', 'primary', 'disable' 등 (기본은 basic)
                    disabled = false,                     // 비활성화 여부
                    size = 'default',                     // 버튼 크기: 'default', 'large' 등 (기본은 default)
                    fullWidth = false,                    // 새 prop: fullWidth가 true이면 버튼 너비를 100%로 적용
                    onClick,                              // 클릭 이벤트 핸들러
                }) => {
    // 클래스 배열을 활용해 조건부로 클래스 추가하기
    const classes = ['c-button'];
    if (variant) classes.push(`c-button--${variant}`);
    if (size) classes.push(`c-button--${size}`);
    if (disabled) classes.push('c-button--disabled');
    if (fullWidth) classes.push('c-button--full'); // fullWidth가 true일 경우 추가
    if (iconName && text) {
        classes.push('c-button--icontext');
    } else if (iconName) {
        classes.push('c-button--icon');
    }

    const className = classes.join(' ');

    // iconName이 전달되었고, icons 매핑에 해당 컴포넌트가 있다면 iconComponent 생성
    let iconComponent = null;
    if (iconName && icons[iconName]) {
        const IconComponent = icons[iconName];
        // React.cloneElement를 사용해 fill, stroke 스타일을 override
        iconComponent = (
            <IconComponent style={{ fill: iconColor, stroke: iconColor }} />
        );
    }

    return (
        <button
            className={className}
            disabled={disabled}
            onClick={disabled ? undefined : onClick}
        >
            {iconComponent && iconPosition === 'left' && (
                <div className="btn-icon">{iconComponent}</div>
            )}
            {text && <span className="btn-text">{text}</span>}
            {iconComponent && iconPosition === 'right' && (
                <div className="btn-icon">{iconComponent}</div>
            )}
        </button>
    );
};

Button.propTypes = {
    text: PropTypes.string,
    iconName: PropTypes.string,
    iconColor: PropTypes.string,
    iconPosition: PropTypes.oneOf(['left', 'right']),
    variant: PropTypes.string,
    disabled: PropTypes.bool,
    size: PropTypes.string,
    fullWidth: PropTypes.bool,
    onClick: PropTypes.func,
};

export default Button;
