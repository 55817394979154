import React from 'react';
import PropTypes from 'prop-types';

/**
 * Checkbox 컴포넌트
 *
 * 사용 예시:
 * <Checkbox
 *   checked={checked}
 *   onChange={(newVal) => setChecked(newVal)}
 *   disabled={false}
 *   label="체크박스 예시"
 />
 */
const Checkbox = ({ checked, onChange, disabled, label }) => {
    const handleInputChange = (e) => {
        if (!disabled && onChange) {
            onChange(e.target.checked);
        }
    };

    return (
        <label className={`c-checkbox ${checked ? 'c-checkbox--checked' : ''} ${disabled ? 'c-checkbox--disabled' : ''}`}>
            <input
                type="checkbox"
                className="c-checkbox__input"
                checked={checked}
                onChange={handleInputChange}
                disabled={disabled}
            />
            <span className="c-checkbox__box" />
            {label && <span className="c-checkbox__label">{label}</span>}
        </label>
    );
};

Checkbox.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    label: PropTypes.string,
};

Checkbox.defaultProps = {
    checked: false,
    disabled: false,
    label: '',
};

export default Checkbox;
