import React from 'react';
import PropTypes from 'prop-types';
import Badge from './Badge'; // 앞서 만든 Badge 컴포넌트

/**
 * BadgeGroup 컴포넌트
 *
 * 사용 예시:
 * <BadgeGroup
 *   items={[
 *     { title: '현재', badgeProps: { text: '주의', grade: 2, variant: 'solid', size: 'small' } },
 *     { title: '예측', badgeProps: { text: '주의', grade: 2, variant: 'solid', size: 'small' } },
 *   ]}
 * />
 */
function BadgeGroup({ items }) {
    const isSingle = items.length === 1;

    return (
        <div className={`c-badge-group ${isSingle ? 'c-badge-group--single' : ''}`}>
            {items.map((item, index) => (
                <div key={index} className="c-badge-group__item">
                    {/* 제목(옵션) */}
                    {item.title && (
                        <span className="c-badge-group__title">
              {item.title}
            </span>
                    )}
                    {/* Badge 컴포넌트 */}
                    <Badge {...item.badgeProps} />

                    {/* 구분선: 마지막 아이템 뒤에는 표시 안 함 */}
                    {index < items.length - 1 && (
                        <span className="c-badge-group__divider" />
                    )}
                </div>
            ))}
        </div>
    );
}

BadgeGroup.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            // title은 선택
            title: PropTypes.string,
            // badgeProps는 Badge 컴포넌트에 전달할 props
            badgeProps: PropTypes.object.isRequired,
        })
    ).isRequired,
};

export default BadgeGroup;
