import SimpleTable from "../components/Table/SimpleTable.js";

export default function () {
    const name = "침수 예측";
    const header = ["시간", "수심"];
    const data = [
        {
            "시간": "1분",
            "수심": "3.3m",
        },
        {
            "시간": "10분",
            "수심": "4.0m",
        },
        {
            "시간": "30분",
            "수심": "3.0m",
        },
    ];
    return SimpleTable(name, header, data);
}
