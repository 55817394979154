
//날짜 형식 2025.01.01 20:00
export function formatDateType(date) {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0'); // 0부터 시작하므로 1을 더함
    const day = String(d.getDate()).padStart(2, '0');
    const hours = String(d.getHours()).padStart(2, '0');
    const minutes = String(d.getMinutes()).padStart(2, '0');
    return `${year}.${month}.${day} ${hours}:${minutes}`;
}

//수위
export function formatWaterLevel(value) {
    // todo DB 수위 단위가 표준화되어 있는가? 소수점 첫째자리까지 표기?
    return parseFloat(value).toFixed(1) + 'm';
}

//습도 (%)
export function formatHumidity(value) {
    return (parseFloat(value)*100).toFixed(1) + '%';
}

//익명처리
export function formatAnonymous(name) {
    if(name.length <= 2) {
        return name[0] + '*';
    } else {
        let anonymous = '';
        for(let i=1; i < name.length-1; i++) {
            anonymous +='*';
        }
        return name[0] + anonymous + name[name.length - 1];
    }
}

//맨홀 개폐여부
export function formatOpenCheck(checked) {
    return checked ? 'OPEN' : 'CLOSE';
}