import React, { useState } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import ToggleSwitch from "./ToggleSwitch";

const ToggleSelect = ({ options, defaultSwitchState = false, defaultSelectedOption = "" }) => {
    const [isSwitchOn, setIsSwitchOn] = useState(defaultSwitchState);
    const [selectedOption, setSelectedOption] = useState(defaultSelectedOption);

    const handleToggle = () => {
        setIsSwitchOn(!isSwitchOn);
    };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    return (
        <div className="toggleSelect">
            {/* On/Off 스위치 */}
            <ToggleSwitch isChecked={isSwitchOn} onChange={handleToggle} />

            {/* 셀렉트 박스 (스위치가 On일 때만 활성화) */}
            <Input
                type="select"
                name="select"
                id="exampleSelect"
                disabled={!isSwitchOn}
                value={selectedOption}
                onChange={handleSelectChange}
            >
                {options.map((option, index) => (
                    <option key={index} value={option}>
                        {option}단계
                    </option>
                ))}
            </Input>
        </div>
    );
};

export default ToggleSelect;
