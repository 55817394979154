import SimpleTable from "../components/Table/SimpleTable";

export default function (data) {
    const name = "최근 작업관리";
    const header = ["일시", "작업내역"];
    const exampleData = [
        {
            "일시": "24-02-04 09:06:00",
            "작업내역": "센서교체",
        },
        {
            "일시": "24-02-04 09:06:00",
            "작업내역": "통신모듈 교체",
        },
        {
            "일시": "24-02-04 09:06:00",
            "작업내역": "통신모듈 교체",
        },
        {
            "일시": "24-02-04 09:06:00",
            "작업내역": "통신모듈 교체",
        },
    ];
    return SimpleTable(name, header, exampleData); // todo 데이터를 가져오면 exampleData -> data 수정
}
