export const get = (url) => {
  return fetchData(url, "GET");
};

export const post = (url, body) => {
  return fetchData(url, "POST", body);
};

export const put = (url, body) => {
  return fetchData(url, "PUT", body);
};

export const remove = (url, body) => {
  return fetchData(url, "DELETE", body);
};

export const patch = (url, body) => {
  return fetchData(url, "PATCH", body);
};

const fetchData = async (url, method, body = null) => {
  const stringifyBodyIfHasBody = body ? JSON.stringify(body) : null;
  const parseUrlIfIsInternal = isInternalServer(url)
    ? parseInternalServerUrl(url)
    : url;

  return fetch(parseUrlIfIsInternal, {
    method: method,
    headers: {
      "Content-Type": "application/json",
    },
    body: stringifyBodyIfHasBody,
    credentials: "include",
  })
    .then((response) => {
      if (response.status >= 400) {
        return Promise.reject(
          new Error(`HTTP error! Status: ${response.status}`),
        );
      }
      return response;
    })
    .then((data) => {
      return data ? data.json() : null;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const isInternalServer = (url) => {
  return !url.startsWith("http");
};

const parseInternalServerUrl = (url) => {
  const gateway = "https://floodguard.broadwave-lab.com"; // todo 환경변수로 넣기
  const slash = url.startsWith("/") ? "" : "/";
  return `${gateway}${slash}${url}`;
};
