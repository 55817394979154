/*!

=========================================================
* Black Dashboard React v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library that concatenates classes
import classNames from "classnames";
import React from "react";
import {Button} from 'reactstrap';
import Logo from "../util/Logo.js"

function NavInfo() {
    return (
        <>
            <div className="bw-dashboardFooter">
                <div className="bw-dashboardFooter__item">
                    {/* 아이콘과 텍스트를 각각 설정 */}
                    <Logo iconClass="tim-icons icon-bank" text="서울시 테스트베드사업"/>
                </div>
                <div className="bw-dashboardFooter__item" style={{marginLeft: "auto"}}>
                    <Button>접속자 통계 이력</Button>
                </div>
            </div>
        </>
    );
}

export default NavInfo;