import React from "react";
import { MockRainData } from "../../mockData/api/rain/mockRainData.js";
import InsertTime from "./InsertTime";
import Rainfall from "./Rainfall";

function RainData() {
    const rainData = MockRainData.data[0]; // 첫 번째 데이터 가져오기

    return (
        <div className="c-rainData">
            <InsertTime insertTime={rainData.insert_time}/>
            <Rainfall
                iconLeft="rain"
                iconRight="doubleArrow"
                color="#3dd990"
                label="강수량"
                value={200}
                unit="mm"
            />
        </div>
    );
}

export default RainData;