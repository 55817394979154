import React from 'react';
import PropTypes from 'prop-types';
import logoIcons from './LogoIcons';  // 위에서 만든 LogoIcons.js

/**
 * Logo 컴포넌트
 *
 * 사용 예시:
 * <Logo
 *   iconName="floodguard"
 *   color="navy"              // "white" | "gray" | "navy"
 *   size="medium"             // "small" | "medium" | "large"
 *   mainText="반지하 침수안전 시스템"
 *   subText="FloodGuard"
 *   href="/"                  // 링크 URL (기본값 "/")
 *   width="40px"              // 아이콘 커스텀 너비 (옵션)
 *   height="40px"             // 아이콘 커스텀 높이 (옵션)
 * />
 */
const Logo = ({
                  iconName = 'floodguard',
                  color = 'navy',
                  size = 'medium',
                  mainText,
                  subText,
                  href = '/',
                  width,
                  height,
                  ...props
              }) => {
    const IconComponent = logoIcons[iconName];

    return (
        <h1 className={`c-logo c-logo--${color} c-logo--${size}`} {...props}>
            <a className="c-logo__link" href={href}>
                {IconComponent && (
                    <span className="c-logo__icon">
            <IconComponent
                style={{
                    fill: 'currentColor',
                    ...(width ? { width } : {}),
                    ...(height ? { height } : {}),
                }}
            />
          </span>
                )}
                {(mainText || subText) && (
                    <div className="c-logo__text">
                        {mainText && <div className="c-logo__main">{mainText}</div>}
                        {subText && <div className="c-logo__sub">{subText}</div>}
                    </div>
                )}
            </a>
        </h1>
    );
};

Logo.propTypes = {
    iconName: PropTypes.string,
    color: PropTypes.oneOf(['white', 'gray', 'navy']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    mainText: PropTypes.string,
    subText: PropTypes.string,
    href: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
};

export default Logo;