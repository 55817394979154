import React from 'react';
import PropTypes from 'prop-types';
import DateInput from './Date';

/**
 * Period 컴포넌트: 시작일과 종료일을 함께 표시
 *
 * 사용 예시:
 * <Period
 *   size="medium"
 *   startValue="2025-03-25"
 *   endValue="2025-03-30"
 *   onStartChange={(val) => console.log('시작일:', val)}
 *   onEndChange={(val) => console.log('종료일:', val)}
 * />
 */
const Period = ({
                    size = 'medium',
                    startValue,
                    endValue,
                    onStartChange,
                    onEndChange,
                    disabled = false,
                }) => {
    return (
        <div className={`c-period c-period--${size}`}>
            <DateInput
                size={size}
                value={startValue}
                disabled={disabled}
                onChange={onStartChange}
            />
            <span className="c-period__dash">-</span>
            <DateInput
                size={size}
                value={endValue}
                disabled={disabled}
                onChange={onEndChange}
            />
        </div>
    );
};

Period.propTypes = {
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    startValue: PropTypes.string,
    endValue: PropTypes.string,
    onStartChange: PropTypes.func,
    onEndChange: PropTypes.func,
    disabled: PropTypes.bool,
};

export default Period;
