export const MockSensorHistory = [
    {
        time: "2025-02-10 23:10:00.000000",
        waterLevel:"5.23",
        afterOne: "6.2",
        afterTen: "6.5",
        afterThirty: "6.9",
        temperature: 20,
        humidity: 0.68,
        open: false,
        //6번 ppt 수위 중복
    },
    {
        time: "2025-02-10 23:00:00.000000",
        waterLevel:"4.1",
        afterOne: "5.2",
        afterTen: "6.1",
        afterThirty: "6.5",
        temperature: 20,
        humidity: 0.67,
        open: false,
    },
    {
        time: "2025-02-10 22:50:00.000000",
        waterLevel:"3.9",
        afterOne: "4.0",
        afterTen: "4.3",
        afterThirty: "5.0",
        temperature: 21,
        humidity: 0.66,
        open: false,
    },
    {
        time: "2025-02-10 22:40:00.000000",
        waterLevel:"3.9",
        afterOne: "4.0",
        afterTen: "4.2",
        afterThirty: "5.3",
        temperature: 21,
        humidity: 0.66,
        open: false,
    },
    {
        time: "2025-02-10 22:30:00.000000",
        waterLevel:"3.9",
        afterOne: "3.9",
        afterTen: "3.9",
        afterThirty: "4.0",
        temperature: 21,
        humidity: 0.64,
        open: false,
    },
    {
        time: "2025-02-10 22:20:00.000000",
        waterLevel:"3.9",
        afterOne: "3.9",
        afterTen: "3.9",
        afterThirty: "4.0",
        temperature: 21,
        humidity: 0.64,
        open: false,
    },
    {
        time: "2025-02-10 22:10:00.000000",
        waterLevel:"3.8",
        afterOne: "3.9",
        afterTen: "3.8",
        afterThirty: "3.8",
        temperature: 22,
        humidity: 0.64,
        open: false,
    },
    {
        time: "2025-02-10 22:00:00.000000",
        waterLevel:"3.8",
        afterOne: "3.8",
        afterTen: "4.0",
        afterThirty: "4.0",
        temperature: 22,
        humidity: 0.63,
        open: false,
    },

    {
        time: "2025-02-10 23:00:00.000000",
        waterLevel:"4.1",
        afterOne: "5.2",
        afterTen: "6.1",
        afterThirty: "6.5",
        temperature: 20,
        humidity: 0.67,
        open: false,
    },
    {
        time: "2025-02-10 22:50:00.000000",
        waterLevel:"3.9",
        afterOne: "4.0",
        afterTen: "4.3",
        afterThirty: "5.0",
        temperature: 21,
        humidity: 0.66,
        open: false,
    },
    {
        time: "2025-02-10 22:40:00.000000",
        waterLevel:"3.9",
        afterOne: "4.0",
        afterTen: "4.2",
        afterThirty: "5.3",
        temperature: 21,
        humidity: 0.66,
        open: false,
    },
    {
        time: "2025-02-10 22:30:00.000000",
        waterLevel:"3.9",
        afterOne: "3.9",
        afterTen: "3.9",
        afterThirty: "4.0",
        temperature: 21,
        humidity: 0.64,
        open: false,
    },
    {
        time: "2025-02-10 22:20:00.000000",
        waterLevel:"3.9",
        afterOne: "3.9",
        afterTen: "3.9",
        afterThirty: "4.0",
        temperature: 21,
        humidity: 0.64,
        open: false,
    },
    {
        time: "2025-02-10 22:10:00.000000",
        waterLevel:"3.8",
        afterOne: "3.9",
        afterTen: "3.8",
        afterThirty: "3.8",
        temperature: 22,
        humidity: 0.64,
        open: false,
    },
    {
        time: "2025-02-10 22:00:00.000000",
        waterLevel:"3.8",
        afterOne: "3.8",
        afterTen: "4.0",
        afterThirty: "4.0",
        temperature: 22,
        humidity: 0.63,
        open: false,
    },
    {
        time: "2025-02-10 23:00:00.000000",
        waterLevel:"4.1",
        afterOne: "5.2",
        afterTen: "6.1",
        afterThirty: "6.5",
        temperature: 20,
        humidity: 0.67,
        open: false,
    },
    {
        time: "2025-02-10 22:50:00.000000",
        waterLevel:"3.9",
        afterOne: "4.0",
        afterTen: "4.3",
        afterThirty: "5.0",
        temperature: 21,
        humidity: 0.66,
        open: false,
    },
    {
        time: "2025-02-10 22:40:00.000000",
        waterLevel:"3.9",
        afterOne: "4.0",
        afterTen: "4.2",
        afterThirty: "5.3",
        temperature: 21,
        humidity: 0.66,
        open: false,
    },
    {
        time: "2025-02-10 22:30:00.000000",
        waterLevel:"3.9",
        afterOne: "3.9",
        afterTen: "3.9",
        afterThirty: "4.0",
        temperature: 21,
        humidity: 0.64,
        open: false,
    },
    {
        time: "2025-02-10 22:20:00.000000",
        waterLevel:"3.9",
        afterOne: "3.9",
        afterTen: "3.9",
        afterThirty: "4.0",
        temperature: 21,
        humidity: 0.64,
        open: false,
    },
    {
        time: "2025-02-10 22:10:00.000000",
        waterLevel:"3.8",
        afterOne: "3.9",
        afterTen: "3.8",
        afterThirty: "3.8",
        temperature: 22,
        humidity: 0.64,
        open: false,
    },
    {
        time: "2025-02-10 22:00:00.000000",
        waterLevel:"3.8",
        afterOne: "3.8",
        afterTen: "4.0",
        afterThirty: "4.0",
        temperature: 22,
        humidity: 0.63,
        open: false,
    },
    {
        time: "2025-02-10 23:00:00.000000",
        waterLevel:"4.1",
        afterOne: "5.2",
        afterTen: "6.1",
        afterThirty: "6.5",
        temperature: 20,
        humidity: 0.67,
        open: false,
    },
    {
        time: "2025-02-10 22:50:00.000000",
        waterLevel:"3.9",
        afterOne: "4.0",
        afterTen: "4.3",
        afterThirty: "5.0",
        temperature: 21,
        humidity: 0.66,
        open: false,
    },
    {
        time: "2025-02-10 22:40:00.000000",
        waterLevel:"3.9",
        afterOne: "4.0",
        afterTen: "4.2",
        afterThirty: "5.3",
        temperature: 21,
        humidity: 0.66,
        open: false,
    },
    {
        time: "2025-02-10 22:30:00.000000",
        waterLevel:"3.9",
        afterOne: "3.9",
        afterTen: "3.9",
        afterThirty: "4.0",
        temperature: 21,
        humidity: 0.64,
        open: false,
    },
    {
        time: "2025-02-10 22:20:00.000000",
        waterLevel:"3.9",
        afterOne: "3.9",
        afterTen: "3.9",
        afterThirty: "4.0",
        temperature: 21,
        humidity: 0.64,
        open: false,
    },
    {
        time: "2025-02-10 22:10:00.000000",
        waterLevel:"3.8",
        afterOne: "3.9",
        afterTen: "3.8",
        afterThirty: "3.8",
        temperature: 22,
        humidity: 0.64,
        open: false,
    },
    {
        time: "2025-02-10 22:00:00.000000",
        waterLevel:"3.8",
        afterOne: "3.8",
        afterTen: "4.0",
        afterThirty: "4.0",
        temperature: 22,
        humidity: 0.63,
        open: false,
    },
    {
        time: "2025-02-10 23:00:00.000000",
        waterLevel:"4.1",
        afterOne: "5.2",
        afterTen: "6.1",
        afterThirty: "6.5",
        temperature: 20,
        humidity: 0.67,
        open: false,
    },
    {
        time: "2025-02-10 22:50:00.000000",
        waterLevel:"3.9",
        afterOne: "4.0",
        afterTen: "4.3",
        afterThirty: "5.0",
        temperature: 21,
        humidity: 0.66,
        open: false,
    },
    {
        time: "2025-02-10 22:40:00.000000",
        waterLevel:"3.9",
        afterOne: "4.0",
        afterTen: "4.2",
        afterThirty: "5.3",
        temperature: 21,
        humidity: 0.66,
        open: false,
    },
    {
        time: "2025-02-10 22:30:00.000000",
        waterLevel:"3.9",
        afterOne: "3.9",
        afterTen: "3.9",
        afterThirty: "4.0",
        temperature: 21,
        humidity: 0.64,
        open: false,
    },
    {
        time: "2025-02-10 22:20:00.000000",
        waterLevel:"3.9",
        afterOne: "3.9",
        afterTen: "3.9",
        afterThirty: "4.0",
        temperature: 21,
        humidity: 0.64,
        open: false,
    },
    {
        time: "2025-02-10 22:10:00.000000",
        waterLevel:"3.8",
        afterOne: "3.9",
        afterTen: "3.8",
        afterThirty: "3.8",
        temperature: 22,
        humidity: 0.64,
        open: false,
    },
    {
        time: "2025-02-10 22:00:00.000000",
        waterLevel:"3.8",
        afterOne: "3.8",
        afterTen: "4.0",
        afterThirty: "4.0",
        temperature: 22,
        humidity: 0.63,
        open: false,
    },
];