import React from 'react';
import PropTypes from 'prop-types';

/**
 * Table 컴포넌트
 *
 * 사용 예시 (일반 테이블):
 * const columns = [
 *   { key: 'point', label: '지점' },
 *   { key: 'center', label: '센터' },
 *   { key: 'status', label: '상태' },
 *   { key: 'time', label: '시간' },
 * ];
 *
 * const data = [
 *   { point: 'A206', center: 'C0', status: '이상', time: '2024-04-01 09:06:00' },
 *   { point: 'A207', center: 'C1', status: '정상', time: '2024-04-01 09:07:00' },
 *   { point: 'A208', center: 'C2', status: '경고', time: '2024-04-01 09:08:00' },
 * ];
 *
 * <Table columns={columns} data={data} size="40" align="center" />
 *
 * Row Header 테이블 사용 예시:
 * <Table columns={columns} data={data} rowHeaderKey="point" size="60" align="center" />
 *
 * Props:
 * - columns: 각 컬럼의 key와 label 배열.
 * - data: 행 데이터 배열.
 * - rowHeaderKey (선택): 해당 key의 데이터는 행 헤더(<th>)로 표시.
 * - size: "40", "50", "60" 중 하나, 상단 헤더의 높이를 결정.
 * - align: "left", "center", "right" 중 하나, 텍스트 정렬. (기본 "left")
 */
function Table({ columns, data, rowHeaderKey, size, align }) {
    return (
        <div className={`c-table c-table--size${size} c-table--align-${align}`}>
            <table>
                <thead>
                <tr>
                    {columns.map((col) => (
                        <th key={col.key} className="c-table__thead-th">
                            {col.label}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {data.length === 0 ? (
                    <tr>
                        <td colSpan={columns.length} className="c-table__empty">
                            데이터가 없습니다
                        </td>
                    </tr>
                ) : (
                    data.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {columns.map((col) => {
                                if (rowHeaderKey && col.key === rowHeaderKey) {
                                    return (
                                        <th key={col.key} className="c-table__row-th">
                                            {row[col.key]}
                                        </th>
                                    );
                                }
                                return <td key={col.key}>{row[col.key]}</td>;
                            })}
                        </tr>
                    ))
                )}
                </tbody>
            </table>
        </div>
    );
}

Table.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ).isRequired,
    data: PropTypes.arrayOf(PropTypes.object),
    rowHeaderKey: PropTypes.string,
    /** 헤더 높이: "40", "50", "60" */
    size: PropTypes.oneOf(["40", "50", "60"]),
    /** 텍스트 정렬: "left", "center", "right" */
    align: PropTypes.oneOf(["left", "center", "right"]),
};

Table.defaultProps = {
    data: [],
    rowHeaderKey: null,
    size: "40",
    align: "left",
};

export default Table;
