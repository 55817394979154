// 아이콘 파일들을 ReactComponent 형태로 import (Create React App의 SVGR 설정을 활용)
import { ReactComponent as FloodGuardFill } from 'assets/img/logo/floodguard__logo--fill.svg';
import { ReactComponent as FloodGuardLine } from 'assets/img/logo/floodguard__logo--line.svg';
import { ReactComponent as Gwangjin } from 'assets/img/logo/gwangjin__logo.svg';

const logoIcons  = {
    floodGuardFill: FloodGuardFill,
    floodGuardLine: FloodGuardLine,
    gwangjin: Gwangjin,
};

export default logoIcons ;
