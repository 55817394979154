import React from 'react';
import PropTypes from 'prop-types';
import icons from '../../components/util/Icons';

/**
 * Notification 컴포넌트
 *
 * 사용 예시:
 * <Notification
 *   message="긴급알림: 현재 침수 위험 지역입니다. 즉시 대피하시기 바랍니다."
 *   rolling={true}  // rolling prop이 true일 때만 c-notification__rolling 클래스 적용
 * />
 */
const Notification = ({ message, rolling }) => {
    const IconComponent = icons.alarm;

    return (
        <div className="c-notification">
            <div className="c-notification__icon-box">
                {IconComponent && (
                    <span className="c-notification__icon">
            <IconComponent />
          </span>
                )}
            </div>
            <div className="c-notification__message">
        <span className={rolling ? 'c-notification__rolling' : ''}>
          {message}
        </span>
            </div>
        </div>
    );
};

Notification.propTypes = {
    message: PropTypes.string.isRequired,
    rolling: PropTypes.bool,
};

Notification.defaultProps = {
    rolling: false,
};

export default Notification;
