//Map > 지도 위치 기반 spot list를 호출
export const dataSpotDetailList = [
    {
        name: "A6",
        time: "2025-02-10 23:10:12.123456",
        waterLevel:5.23,
        afterOne: 6.2,
        afterTen: 6.5,
        afterThirty: 6.9,
        rep: "이주말",
        accompanyingPartner: "김평일",
        step: 4,
        lon: 127.077973,
        lat: 37.563996,
    },
    {
        name: "A16",
        time: "2025-02-10 23:10:12.123456",
        waterLevel:5.1,
        afterOne: 6.01,
        afterTen: 6.2,
        afterThirty: 6.5,
        rep: "김시원",
        accompanyingPartner: "이겨울",
        step: 4,
        lon: 127.078902,
        lat: 37.563652,
    },
    {
        name: "A20",
        time: "2025-02-10 23:10:12.123456",
        waterLevel:4.6,
        afterOne: 4.7,
        afterTen: 4.9,
        afterThirty: 5.4,
        rep: "안지금",
        accompanyingPartner: "최시작",
        step: 3,
        lon: 127.078877,
        lat: 37.563279,
    },
    {
        name: "A1",
        time: "2025-02-10 23:10:12.123456",
        waterLevel:3.9,
        afterOne: 3.8,
        afterTen: 3.8,
        afterThirty: 3.7,
        rep: "조금만",
        accompanyingPartner: "추지훈",
        step: 2,
        lon: 127.077922,
        lat: 37.562983,
    },
    {
        name: "A9",
        time: "2025-02-10 23:10:12.123456",
        waterLevel:3.23,
        afterOne: 3.33,
        afterTen: 4.01,
        afterThirty: 3.05,
        rep: "이순희",
        accompanyingPartner: "이경자",
        step: 1,
        lon: 127.077097,
        lat: 37.563956,
    },

    {
        name: "A7",
        time: "2025-02-10 23:10:12.123456",
        waterLevel:5.23,
        afterOne: 6.2,
        afterTen: 6.5,
        afterThirty: 6.9,
        rep: "이주말",
        accompanyingPartner: "김평일",
        step: 4,
        lon: 127.078912,
        lat: 37.563552,
    },
    {
        name: "A17",
        time: "2025-02-10 23:10:12.123456",
        waterLevel:5.1,
        afterOne: 6.01,
        afterTen: 6.2,
        afterThirty: 6.5,
        rep: "김시원",
        accompanyingPartner: "이겨울",
        step: 4,
        lon: 127.078677,
        lat: 37.563229,
    },
    {
        name: "A21",
        time: "2025-02-10 23:10:12.123456",
        waterLevel:4.6,
        afterOne: 4.7,
        afterTen: 4.9,
        afterThirty: 5.4,
        rep: "안지금",
        accompanyingPartner: "최시작",
        step: 3,
        lon: 127.077942,
        lat: 37.562883,
    },
    {
        name: "A2",
        time: "2025-02-10 23:10:12.123456",
        waterLevel:3.9,
        afterOne: 3.8,
        afterTen: 3.8,
        afterThirty: 3.7,
        rep: "조금만",
        accompanyingPartner: "추지훈",
        step: 2,
        lon: 127.077573,
        lat: 37.563991,
    },
    {
        name: "A11",
        time: "2025-02-10 23:10:12.123456",
        waterLevel:3.23,
        afterOne: 3.33,
        afterTen: 4.01,
        afterThirty: 3.05,
        rep: "이순희",
        accompanyingPartner: "이경자",
        step: 1,
        lon: 127.078102,
        lat: 37.563752,
    },

    {
        name: "A4",
        time: "2025-02-10 23:10:12.123456",
        waterLevel:3.9,
        afterOne: 3.8,
        afterTen: 3.8,
        afterThirty: 3.7,
        rep: "조금만",
        accompanyingPartner: "추지훈",
        step: 4,
        lon: 127.078847,
        lat: 37.563219,
    },
    {
        name: "A12",
        time: "2025-02-10 23:10:12.123456",
        waterLevel:3.23,
        afterOne: 3.33,
        afterTen: 4.01,
        afterThirty: 3.05,
        rep: "이순희",
        accompanyingPartner: "이경자",
        step: 4,
        lon: 127.077962,
        lat: 37.562980,
    },
];

// const data = [
//     {
//         name: "A6",
//         time: "2025-02-04 23:10:12.123456",
//         waterLevel: 3.2,
//         afterOne: 3.33,
//         afterTen: 4.01,
//         afterThirty: 3.05,
//         rep: "이ㅁ빈",
//         accompanyingPartner: "김ㄱ철",
//         step: 4
//     },
//     // {
//     //     //단일 맨홀 테이블에 재해약자가 2명 이상인 경우를 보여주기 위한 예시 데이터임
//     //     name: "A11",
//     //     time: "2025-10-18 20:00:12.123456",
//     //     waterLevel:"1.23",
//     //     afterOne: "2.01",
//     //     afterTen: "3.13",
//     //     afterThirty: "1.64",
//     //     rep: ["김라라라라", "홍성"],
//     //     accompanyingPartner: "김해",
//     //     step: "1단계"
//     // }
//
// ];



// Map 의 AddMarkerList에서 마커 띄우기에 이용
// export const MarkerInfoList = [
//     //manhole identification_code
//     { name: "A6", step: 4, lon: 127.077973, lat: 37.563996 },
//     { name: "A16", step: 4, lon: 127.078902, lat: 37.563652 },
//     { name: "A20", step: 3, lon: 127.078877, lat: 37.563279 },
//     { name: "A1", step: 2, lon: 127.077922, lat: 37.562983 },
//     { name: "A9", step: 1, lon: 127.077097, lat: 37.563956 },
//
//     { name: "A7", step: 4, lon: 127.078912, lat: 37.563552 },
//     { name: "A17", step: 4, lon: 127.078677, lat: 37.563229 },
//     { name: "A21", step: 3, lon: 127.077942, lat: 37.562883 },
//     { name: "A2", step: 2, lon: 127.077573, lat: 37.563991 },
//     { name: "A11", step: 1, lon: 127.078102, lat: 37.563752 },
//
//     { name: "A4", step: 4, lon: 127.078847, lat: 37.563219 },
//     { name: "A12", step: 4, lon: 127.077962, lat: 37.562980 },
// ];



//Map 의 ManholeList
// export const dataSpotDetailList = [
//     {
//         name: "A6",
//         time: "2025-02-10 23:10:12.123456",
//         waterLevel:5.23,
//         afterOne: 6.2,
//         afterTen: 6.5,
//         afterThirty: 6.9,
//         rep: "이주말",
//         accompanyingPartner: "김평일",
//         step: 4
//     },
//     {
//         name: "A16",
//         time: "2025-02-10 23:10:12.123456",
//         waterLevel:5.1,
//         afterOne: 6.01,
//         afterTen: 6.2,
//         afterThirty: 6.5,
//         rep: "김시원",
//         accompanyingPartner: "이겨울",
//         step: 4
//     },
//     {
//         name: "A20",
//         time: "2025-02-10 23:10:12.123456",
//         waterLevel:4.6,
//         afterOne: 4.7,
//         afterTen: 4.9,
//         afterThirty: 5.4,
//         rep: "안지금",
//         accompanyingPartner: "최시작",
//         step: 3
//     },
//     {
//         name: "A1",
//         time: "2025-02-10 23:10:12.123456",
//         waterLevel:3.9,
//         afterOne: 3.8,
//         afterTen: 3.8,
//         afterThirty: 3.7,
//         rep: "조금만",
//         accompanyingPartner: "추지훈",
//         step: 2
//     },
//     {
//         name: "A9",
//         time: "2025-02-10 23:10:12.123456",
//         waterLevel:3.23,
//         afterOne: 3.33,
//         afterTen: 4.01,
//         afterThirty: 3.05,
//         rep: "이순희",
//         accompanyingPartner: "이경자",
//         step: 1
//     },
//
//     {
//         name: "A7",
//         time: "2025-02-10 23:10:12.123456",
//         waterLevel:5.23,
//         afterOne: 6.2,
//         afterTen: 6.5,
//         afterThirty: 6.9,
//         rep: "이주말",
//         accompanyingPartner: "김평일",
//         step: 4
//     },
//     {
//         name: "A17",
//         time: "2025-02-10 23:10:12.123456",
//         waterLevel:5.1,
//         afterOne: 6.01,
//         afterTen: 6.2,
//         afterThirty: 6.5,
//         rep: "김시원",
//         accompanyingPartner: "이겨울",
//         step: 4
//     },
//     {
//         name: "A21",
//         time: "2025-02-10 23:10:12.123456",
//         waterLevel:4.6,
//         afterOne: 4.7,
//         afterTen: 4.9,
//         afterThirty: 5.4,
//         rep: "안지금",
//         accompanyingPartner: "최시작",
//         step: 3
//     },
//     {
//         name: "A2",
//         time: "2025-02-10 23:10:12.123456",
//         waterLevel:3.9,
//         afterOne: 3.8,
//         afterTen: 3.8,
//         afterThirty: 3.7,
//         rep: "조금만",
//         accompanyingPartner: "추지훈",
//         step: 2
//     },
//     {
//         name: "A11",
//         time: "2025-02-10 23:10:12.123456",
//         waterLevel:3.23,
//         afterOne: 3.33,
//         afterTen: 4.01,
//         afterThirty: 3.05,
//         rep: "이순희",
//         accompanyingPartner: "이경자",
//         step: 1
//     },
//
//     {
//         name: "A4",
//         time: "2025-02-10 23:10:12.123456",
//         waterLevel:3.9,
//         afterOne: 3.8,
//         afterTen: 3.8,
//         afterThirty: 3.7,
//         rep: "조금만",
//         accompanyingPartner: "추지훈",
//         step: 4
//     },
//     {
//         name: "A12",
//         time: "2025-02-10 23:10:12.123456",
//         waterLevel:3.23,
//         afterOne: 3.33,
//         afterTen: 4.01,
//         afterThirty: 3.05,
//         rep: "이순희",
//         accompanyingPartner: "이경자",
//         step: 4
//     },
// ];


//마커 클릭 시 지도 화면 하단 중앙 맨홀 정보 테이블
// export function ManholeInfoByTable() {
//     return <ManholeInfoTable header={header} data={data} />;
// }
//
// export function ManholeInfoByModal() {
//     return <ManholeInfoModal header={header} data={data} />;
// }

//예은 지도2
// export const ManholeInfoByList = () => {
//     return <ManholeInfoList dataSpotList={dataSpotList}/>;
// }

//지도2 작업 중지
// export const dataSpotList = [
//     {name:"A6", step: 4},
//     {name:"A16", step: 4},
//     {name:"A20", step: 3},
//     {name:"A1", step: 2},
//     {name:"A9", step: 1},
//     {name:"-", step: 1},
//     {name:"-", step: 1},
//     {name:"-", step: 1},
// ];