import React, { useState } from 'react';
import PropTypes from 'prop-types';

/**
 * Tabs 컴포넌트
 *
 * 사용법:
 * <Tabs
 *   size="large" // (선택) "large"로 설정하면 .c-tabs--large 클래스가 추가되어 큰 탭 스타일이 적용됩니다.
 *   tabs={[
 *     { id: 'tab1', label: '탭 1', content: <div>탭 1 내용</div> },
 *     { id: 'tab2', label: '탭 2', content: <div>탭 2 내용</div> },
 *     // ... 추가 탭
 *   ]}
 *   defaultTab="tab1" // (선택) 초기 활성 탭의 id
 *   onTabChange={(id) => console.log('활성 탭:', id)} // (선택) 탭 전환 시 호출되는 콜백 함수
 * />
 *
 * props:
 * - size: "large"를 전달하면 큰 탭 스타일 적용. 전달하지 않으면 기본 크기.
 * - tabs: 각 탭에 대한 정보를 담은 배열. 각 탭 객체는 { id, label, content } 형식입니다.
 * - defaultTab: (선택 사항) 초기 활성 탭의 id. 전달되지 않으면 tabs 배열의 첫 번째 탭을 활성화합니다.
 * - onTabChange: (선택 사항) 탭 전환 시 호출되는 콜백 함수. 탭의 id를 인자로 받습니다.
 */
const Tabs = ({ tabs = [], defaultTab, size, onTabChange }) => {
    // 초기 활성 탭 설정
    const initialTab = defaultTab || (tabs.length > 0 ? tabs[0].id : null);
    const [activeTab, setActiveTab] = useState(initialTab);

    // 탭 컨테이너 클래스: size가 "large"이면 c-tabs--large 추가
    const containerClass = size === 'large' ? 'c-tabs c-tabs--large' : 'c-tabs';

    // 탭 클릭 핸들러
    const handleTabClick = (id) => {
        setActiveTab(id);
        if (onTabChange) {
            onTabChange(id);
        }
    };

    // 현재 활성 탭의 내용 찾기
    const activeTabData = tabs.find((tab) => tab.id === activeTab);
    const activeContent = activeTabData ? activeTabData.content : null;

    return (
        <div className={containerClass}>
            {/* 탭 버튼 영역 */}
            <div className="c-tabs__header">
                {tabs.map((tab) => (
                    <button
                        key={tab.id}
                        className={`c-tabs__button ${activeTab === tab.id ? 'active' : ''}`}
                        onClick={() => handleTabClick(tab.id)}
                    >
                        {tab.label}
                    </button>
                ))}

            </div>

            {/* activeContent가 존재할 때만 탭 내용 영역 렌더링 */}
            {activeContent && (
                <div className="c-tabs__content">
                    {activeContent}
                </div>
            )}
        </div>
    );
};

Tabs.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.node.isRequired,
            content: PropTypes.node,
        })
    ),
    defaultTab: PropTypes.string,
    size: PropTypes.oneOf(['large']), // "large" 외 다른 값은 없다고 가정
    onTabChange: PropTypes.func,
};

export default Tabs;