import React, { useState } from 'react';
import icons from '../components/util/Icons';       // 일반 아이콘 모듈
import LogoIcons from '../components/util/LogoIcons'; // 로고 아이콘 모듈
import '../assets/css/design/test/testIcons.css';     // 이 페이지 전용 CSS

/**
 * TestIcons 컴포넌트
 * 일반 아이콘과 로고 아이콘 모두를 그리드 형태로 렌더링하며,
 * 상단의 컬러 입력을 통해 아이콘의 fill 및 stroke 색상을 동적으로 변경합니다.
 *
 * 사용법:
 * <TestIcons />
 */
const TestIcons = () => {
    // iconColor 상태: 아이콘에 적용할 색상 (기본값: #007bff)
    const [iconColor, setIconColor] = useState('#007bff');

    // 컬러 입력 변경 핸들러: iconColor 상태 업데이트
    const handleColorChange = (e) => {
        setIconColor(e.target.value);
    };

    return (
        <div className="test-icons">
            <h1 className="test-icons__title">SVG 아이콘 테스트 페이지</h1>
            <div className="test-icons__controls">
                <label htmlFor="iconColor">아이콘 색상: </label>
                <input
                    type="color"
                    id="iconColor"
                    value={iconColor}
                    onChange={handleColorChange}
                />
                <span className="test-icons__color-code">{iconColor}</span>
            </div>

            {/* 일반 아이콘 섹션 */}
            <h2 className="test-icons__subtitle">일반 아이콘</h2>
            <div className="test-icons__grid">
                {Object.keys(icons).map((key) => {
                    const IconComponent = icons[key];
                    return (
                        <div className="test-icons__item" key={key}>
                            <div className="test-icons__preview">
                                <IconComponent style={{ fill: iconColor, stroke: iconColor }} />
                            </div>
                            <div className="test-icons__name">{key}</div>
                        </div>
                    );
                })}
            </div>

            {/* 로고 아이콘 섹션 */}
            <h2 className="test-icons__subtitle">로고 아이콘</h2>
            <div className="test-icons__grid">
                {Object.keys(LogoIcons).map((key) => {
                    const LogoIconComponent = LogoIcons[key];
                    return (
                        <div className="test-icons__item" key={key}>
                            <div className="test-icons__preview">
                                <LogoIconComponent style={{ fill: iconColor, stroke: iconColor }} />
                            </div>
                            <div className="test-icons__name">{key}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default TestIcons;
