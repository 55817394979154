import React from 'react';
import PropTypes from 'prop-types';

/**
 * Badge 컴포넌트
 *
 * 사용 예시:
 * <Badge
 *   text="정상 : 6"
 *   grade={0}              // 0,1,2,3,4
 *   variant="solid"        // "solid" | "border"
 *   size="small"           // "small" | "medium" | "large"
 * />
 */
const Badge = ({
                   text,
                   grade,
                   variant,
                   size,
               }) => {
    // BEM + prop 조합
    // 예: c-badge c-badge--grade0 c-badge--solid c-badge--small
    const classes = [
        'c-badge',
        `c-badge--grade${grade}`,
        `c-badge--${variant}`,
        `c-badge--${size}`,
    ].join(' ');

    return (
        <span className={classes}>
      {text}
    </span>
    );
};

Badge.propTypes = {
    /** 표시할 텍스트 */
    text: PropTypes.string.isRequired,
    /** 색상 단계: 0~4 */
    grade: PropTypes.oneOf([0, 1, 2, 3, 4]),
    /** 배경색 여부(solid) / 테두리(border) 여부 */
    variant: PropTypes.oneOf(['solid', 'border']),
    /** 높이: small(28px), medium(42px), large(50px) */
    size: PropTypes.oneOf(['small', 'medium', 'large']),
};

Badge.defaultProps = {
    grade: 0,
    variant: 'solid',
    size: 'small',
};

export default Badge;
