import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import icons from './Icons';

/**
 * Date 컴포넌트
 * 기본 input date를 사용하며, 네이티브 캘린더 아이콘을 숨기고 커스텀 아이콘을 통해 달력을 열 수 있도록 함.
 *
 * 사용 예시:
 * <Date
 *   size="medium"         // "small", "medium", "large"
 *   value="2025-03-25"    // 날짜 값 (ISO 포맷)
 *   disabled={false}      // 비활성화 여부
 *   onChange={(newVal) => console.log(newVal)}
 * />
 */
const DateInput = ({
                       size = 'medium',
                       value,
                       disabled = false,
                       onChange,
                       ...props
                   }) => {
    const DateIcon = icons.date; // Icons.js에서 "date" 아이콘 사용
    const inputRef = useRef(null);

    // BEM 클래스 구성
    const classes = ['c-date', `c-date--${size}`];
    if (disabled) {
        classes.push('c-date--disabled');
    }

    const handleInputChange = (e) => {
        if (!disabled && onChange) {
            onChange(e.target.value);
        }
    };

    // 아이콘 클릭 시 input에 포커스하거나 showPicker() 호출
    const handleIconClick = () => {
        if (!disabled && inputRef.current) {
            if (typeof inputRef.current.showPicker === 'function') {
                inputRef.current.showPicker();
            } else {
                inputRef.current.focus();
            }
        }
    };

    return (
        <div className={classes.join(' ')} {...props}>
            <input
                type="date"
                ref={inputRef}
                className="c-date__input"
                value={value}
                onChange={handleInputChange}
                disabled={disabled}
            />
            <span className="c-date__icon" onClick={handleIconClick}>
        <DateIcon />
      </span>
        </div>
    );
};

DateInput.propTypes = {
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    value: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};

export default DateInput;