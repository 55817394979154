/*!

=========================================================
* Black Dashboard React v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library that concatenates classes
import React from "react";
// react plugin used to create charts
import { Line } from "react-chartjs-2";

// reactstrap components
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";


import { chartExample1 } from "variables/charts.js";
import ExampleMap from "../components/OpenLayersMap/ExampleMap.js";
import ActiveDevice from "../components/TB/ActiveDevice.js";
import Battery from "../components/TB/Battery.js";
import Guage from "../components/TB/Guage.js";
import { signIn } from "../services/account/signInOutService.js";
import UserManagement from "./UserManagement.js";
import FloodForecast from "./FloodForecast.js";
import DashboardFooter from "../components/Footer/DashboardFooter.js";
import ExampleGridMap from "../components/leafletMap/GridMap.js";
import GridMap from "../components/leafletMap/GridMap.js";
import GaugeGraph from "./GaugeGraph";
import DoughnutGraph from "./DoughnutGraph";
import {getMockPredictionMonitoringData4} from "../mockData/api/prediction/mockPredictionMonitoringData";

function Dashboard(props) {
  const columnHeaders = [
    "application id",
    "battery",
    "dev eui",
    "device name",
    "distance",
    "position",
    "insert time",
  ];
  const [bigChartData, setbigChartData] = React.useState("data1");
  const [selectedGridData, setSelectedGridData] = React.useState(null);
  const [manholeData, setManholeData] = React.useState([]);

  const setBgChartData = (name) => {
    setbigChartData(name);
  };

  React.useEffect(() => {
      setManholeData(getMockPredictionMonitoringData4());
      const interval = setInterval(() => {
          setManholeData(getMockPredictionMonitoringData4());
      }, 10000);

      return () => clearInterval(interval); // Cleanup: 컴포넌트 언마운트 시 인터벌 제거
  }, []);

  // const [mockRainData, setMockRainData] = React.useState(null);
  // const handleMockRainData = () => {
  //   setMockRainData(MockRainData);
  // };

  return (
    <>
      <div className="content-center px-3">
        <Row className="bw-dashboardContent">
          {/* 왼쪽 영역 */}
          <Col xs="6" className="bw-flexColumn">
            <Row className="bw-flex--3">
              <Col xs="3" className="bw-height--100">
                <Card className="card-chart">
                  {/*<CardHeader>*/}
                  {/*  <Row>*/}
                  {/*    <Col className="text-left" sm="6">*/}
                  {/*      <CardTitle tag="h2">1</CardTitle>*/}
                  {/*    </Col>*/}
                  {/*  </Row>*/}
                  {/*</CardHeader>*/}
                  <CardBody>
                    <div className="chart-area">
                      <Battery />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="3" className="bw-height--100">
                <Card className="card-chart">
                  {/*<CardHeader>*/}
                  {/*  <Row>*/}
                  {/*    <Col className="text-left" sm="6">*/}
                  {/*      <CardTitle tag="h2">2</CardTitle>*/}
                  {/*    </Col>*/}
                  {/*  </Row>*/}
                  {/*</CardHeader>*/}
                  <CardBody>
                    <div className="chart-area">
                      <FloodForecast></FloodForecast>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="6" className="bw-height--100">
                <Card className="card-chart">
                  {/*<CardHeader>*/}
                  {/*  <Row>*/}
                  {/*    <Col className="text-left" sm="6">*/}
                  {/*      <CardTitle tag="h2">3</CardTitle>*/}
                  {/*    </Col>*/}
                  {/*  </Row>*/}
                  {/*</CardHeader>*/}
                  <CardBody>
                    <div className="chart-area">
                      <Line
                        data={chartExample1[bigChartData]}
                        options={chartExample1.options}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="bw-flex--7">
              <Col xs="12">
                <Card className="card-chart" /*style={{ height: "415%" }}*/>
                  {/*<CardHeader>*/}
                  {/*  <Row>*/}
                  {/*    <Col className="text-left" sm="6">*/}
                  {/*      <CardTitle tag="h2">4</CardTitle>*/}
                  {/*    </Col>*/}
                  {/*  </Row>*/}
                  {/*</CardHeader>*/}
                  <CardBody>
                    <GridMap setSelectedGridData={setSelectedGridData} manholeData={manholeData} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>

          <Col xs="6" className="bw-flexColumn">
            <Row className="bw-flex--5" /*style={{ height: "100%" }}*/>
              <Col xs="6">
                <Card /*style={{ height: "95%" }}*/>
                  {/*<CardHeader>*/}
                  {/*  <Row>*/}
                  {/*    <Col className="text-left" sm="6">*/}
                  {/*      <CardTitle tag="h2">5</CardTitle>*/}
                  {/*    </Col>*/}
                  {/*  </Row>*/}
                  {/*</CardHeader>*/}
                  <CardBody>
                    <DoughnutGraph manholeData={manholeData}/>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="6">
                <Card /*style={{ height: "95%" }}*/>
                  {/*<CardHeader>*/}
                  {/*  <Row>*/}
                  {/*    <Col className="text-left" sm="6">*/}
                  {/*      <CardTitle tag="h2">6</CardTitle>*/}
                  {/*    </Col>*/}
                  {/*  </Row>*/}
                  {/*</CardHeader>*/}
                  <CardBody>
                    <ActiveDevice />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="bw-flex--5" /*style={{ height: "100%" }}*/>
              <Col xs="6">
                <Card /*style={{ height: "95%" }}*/>
                  {/*<CardHeader>*/}
                  {/*  <Row>*/}
                  {/*    <Col className="text-left" sm="6">*/}
                  {/*      <CardTitle tag="h2">7</CardTitle>*/}
                  {/*    </Col>*/}
                  {/*  </Row>*/}
                  {/*</CardHeader>*/}
                  <CardBody>
                    <GaugeGraph selectedGridData={selectedGridData} />
                  </CardBody>
                </Card>
              </Col>
              <Col xs="6">
                <Card /*style={{ height: "95%" }}*/>
                  {/*<CardHeader>*/}
                  {/*  <Row>*/}
                  {/*    <Col className="text-left" sm="6">*/}
                  {/*      <CardTitle tag="h2">8</CardTitle>*/}
                  {/*    </Col>*/}
                  {/*  </Row>*/}
                  {/*</CardHeader>*/}
                  <CardBody>
                    <UserManagement />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
          {/* 오른쪽 영역 */}
        </Row>

        {/*<Row>
          <Col xs="2">
            <Card className="card-chart">
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <CardTitle tag="h2">배터리</CardTitle>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Line
                    data={chartExample1[bigChartData]}
                    options={chartExample1.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xs="2">
            <Card className="card-chart">
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <CardTitle tag="h2">침수예측</CardTitle>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Line
                    data={chartExample1[bigChartData]}
                    options={chartExample1.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xs="4">
            <Card className="card-chart">
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <CardTitle tag="h2">그래프</CardTitle>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Line
                    data={chartExample1[bigChartData]}
                    options={chartExample1.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xs="4" lg="4" md="12" RowSpan={5} aria-rowspan={5}>
            <Card className="card-chart">
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <CardTitle tag="h2">미니맵</CardTitle>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12">
                    <div className="chart-area">
                      <ExampleMap />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="8">
            <Card className="card-chart">
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <CardTitle tag="h2">미니맵</CardTitle>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12">
                    <div className="chart-area">
                      <ExampleMap />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <Card className="card-chart">
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <h5 className="card-category">서울시 2024</h5>
                    <CardTitle tag="h2">평균 강수량</CardTitle>
                  </Col>
                  <Col sm="6">
                    <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                      <Button
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData === "data1",
                        })}
                        color="info"
                        id="0"
                        size="sm"
                        onClick={() => setBgChartData("data1")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          목록1
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-single-02" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData === "data2",
                        })}
                        onClick={() => setBgChartData("data2")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          목록2
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-gift-2" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData === "data3",
                        })}
                        onClick={() => setBgChartData("data3")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          목록3
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-tap-02" />
                        </span>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Line
                    data={chartExample1[bigChartData]}
                    options={chartExample1.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="4">
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">평균 강수량</h5>
                <CardTitle tag="h3">
                  <i className="tim-icons icon-bell-55 text-info" /> 763,215
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Line
                    data={chartExample2.data}
                    options={chartExample2.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4">
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">피해 규모</h5>
                <CardTitle tag="h3">
                  <i className="tim-icons icon-delivery-fast text-primary" />{" "}
                  ₩3,500 (백만)
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Bar
                    data={chartExample3.data}
                    options={chartExample3.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4">
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">필요 자원</h5>
                <CardTitle tag="h3">
                  <i className="tim-icons icon-send text-success" /> 12,100K
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Line
                    data={chartExample4.data}
                    options={chartExample4.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row></Row>*/}

        <DashboardFooter></DashboardFooter>
      </div>
    </>
  );
}

export default Dashboard;
