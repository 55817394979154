import React, { useState } from 'react'
import Header from '../layouts/Header/Header'
import Main from '../layouts/Content/Main'

function DesignLayout() {


    return (
        <div className="wrap">
            <Header/>
            <Main/>
        </div>
    );
}

export default DesignLayout;
