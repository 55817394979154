export const MockPredictionMonitoringData1 = {
  predictionOne: {
    current: 100,
    oneMinute: 120,
    tenMinute: 130,
    thirtyMinute: 200,
    targetRep: "정O리",
    accompanyPartner: "김O지",
  },
  predictionTwo: {
    current: 0,
    oneMinute: 0,
    tenMinute: 0,
    thirtyMinute: 0,
    targetRep: "김O장",
    accompanyPartner: "이O지",
  },
  predictionThree: {
    current: 10,
    oneMinute: 72,
    tenMinute: 96,
    thirtyMinute: 17,
    targetRep: "박O수",
    accompanyPartner: "이O지",
  },
};

export const MockPredictionMonitoringData2 = {
  predictionOne: {
    current: 120,
    oneMinute: 121,
    tenMinute: 130,
    thirtyMinute: 200,
    targetRep: "정O리",
    accompanyPartner: "김O지",
  },
  predictionTwo: {
    current: 0,
    oneMinute: 0,
    tenMinute: 0,
    thirtyMinute: 0,
    targetRep: "김O장",
    accompanyPartner: "이O지",
  },
  predictionThree: {
    current: 35,
    oneMinute: 50,
    tenMinute: 96,
    thirtyMinute: 35,
    targetRep: "박O수",
    accompanyPartner: "이O지",
  },
};

export const MockPredictionMonitoringData3 = {
  predictionOne: {
    current: 121,
    oneMinute: 122,
    tenMinute: 140,
    thirtyMinute: 190,
    targetRep: "정O리",
    accompanyPartner: "김O지",
  },
  predictionTwo: {
    current: 0,
    oneMinute: 0,
    tenMinute: 0,
    thirtyMinute: 0,
    targetRep: "김O장",
    accompanyPartner: "이O지",
  },
  predictionThree: {
    current: 50,
    oneMinute: 72,
    tenMinute: 63,
    thirtyMinute: 20,
    targetRep: "박O수",
    accompanyPartner: "이O지",
  },
};

export const getMockPredictionMonitoringData4 = () => [
  {manholeCode: "g1", water: parseFloat((Math.random() * 100).toFixed(1))},
  {manholeCode: "g2", water: parseFloat((Math.random() * 100).toFixed(1))},
  {manholeCode: "g3", water: parseFloat((Math.random() * 100).toFixed(1))},
  {manholeCode: "g4_2", water: parseFloat((Math.random() * 100).toFixed(1))},
  {manholeCode: "g5", water: parseFloat((Math.random() * 100).toFixed(1))},
  {manholeCode: "g6", water: parseFloat((Math.random() * 100).toFixed(1))},
  {manholeCode: "g6j7", water: parseFloat((Math.random() * 100).toFixed(1))},

  {manholeCode: "g7", water: parseFloat((Math.random() * 100).toFixed(1))},
  {manholeCode: "g7j1", water: parseFloat((Math.random() * 100).toFixed(1))},
  {manholeCode: "g7j3", water: parseFloat((Math.random() * 100).toFixed(1))},
  {manholeCode: "g7j4", water: parseFloat((Math.random() * 100).toFixed(1))},
  {manholeCode: "g7j6", water: parseFloat((Math.random() * 100).toFixed(1))},

  {manholeCode: "g8", water: parseFloat((Math.random() * 100).toFixed(1))},
  {manholeCode: "g9", water: parseFloat((Math.random() * 100).toFixed(1))},
  {manholeCode: "g10", water: parseFloat((Math.random() * 100).toFixed(1))},
  {manholeCode: "g11", water: parseFloat((Math.random() * 100).toFixed(1))},
  {manholeCode: "g12", water: parseFloat((Math.random() * 100).toFixed(1))},
  {manholeCode: "g12j1", water: parseFloat((Math.random() * 100).toFixed(1))},
  {manholeCode: "g12j2", water: parseFloat((Math.random() * 100).toFixed(1))},

  {manholeCode: "g13", water: parseFloat((Math.random() * 100).toFixed(1))},
  {manholeCode: "g14", water: parseFloat((Math.random() * 100).toFixed(1))},
  {manholeCode: "g15", water: parseFloat((Math.random() * 100).toFixed(1))},
  {manholeCode: "g15j1", water: parseFloat((Math.random() * 100).toFixed(1))},
  {manholeCode: "g15j2", water: parseFloat((Math.random() * 100).toFixed(1))},
  {manholeCode: "g15j3", water: parseFloat((Math.random() * 100).toFixed(1))},

  {manholeCode: "g16", water: parseFloat((Math.random() * 100).toFixed(1))},
  {manholeCode: "g17", water: parseFloat((Math.random() * 100).toFixed(1))},
  {manholeCode: "g18", water: parseFloat((Math.random() * 100).toFixed(1))},

  {manholeCode: "a1", water: parseFloat((Math.random() * 100).toFixed(1))},
  {manholeCode: "a2", water: parseFloat((Math.random() * 100).toFixed(1))},
  {manholeCode: "a6", water: parseFloat((Math.random() * 100).toFixed(1))},
  {manholeCode: "a8", water: parseFloat((Math.random() * 100).toFixed(1))},
];


export const MockPredictionMonitoringDataList = [
  MockPredictionMonitoringData1,
  MockPredictionMonitoringData2,
  MockPredictionMonitoringData3,
];
