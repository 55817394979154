// 아이콘 파일들을 ReactComponent 형태로 import (Create React App의 SVGR 설정을 활용)
import { ReactComponent as AddIcon } from 'assets/img/icon/icon__add.svg';
import { ReactComponent as AlarmIcon } from 'assets/img/icon/icon__alarm.svg';
import { ReactComponent as ArrowFillIcon } from 'assets/img/icon/icon__arrow--fill.svg';
import { ReactComponent as ArrowSolidIcon} from "assets/img/icon/icon__arrow--solid.svg";
import { ReactComponent as CancelIcon } from 'assets/img/icon/icon__cancel.svg';
import { ReactComponent as CctvIcon } from 'assets/img/icon/icon__cctv.svg';
import { ReactComponent as CctvMarkIcon } from 'assets/img/icon/icon__cctv--mark.svg';
import { ReactComponent as ChartIcon } from 'assets/img/icon/icon__chart.svg';
import { ReactComponent as ChatIcon } from 'assets/img/icon/icon__chat.svg';
import { ReactComponent as CheckIcon } from 'assets/img/icon/icon__check.svg';
import { ReactComponent as ClockIcon } from 'assets/img/icon/icon__clock.svg';
import { ReactComponent as DataIcon } from 'assets/img/icon/icon__data.svg';
import { ReactComponent as DateIcon } from 'assets/img/icon/icon__date.svg';
import { ReactComponent as DoubleArrowIcon } from 'assets/img/icon/icon__double-arrow.svg';
import { ReactComponent as DoubleArrowDownIcon } from 'assets/img/icon/icon__double-arrow--down.svg';
import { ReactComponent as DownIcon } from 'assets/img/icon/icon__down.svg';
import { ReactComponent as EmptyIcon } from 'assets/img/icon/icon__empty.svg';
import { ReactComponent as ErrorIcon } from 'assets/img/icon/icon__error1.svg';
import { ReactComponent as Error2Icon } from 'assets/img/icon/icon__error2.svg';
import { ReactComponent as GroupIcon } from 'assets/img/icon/icon__group.svg';
import { ReactComponent as GroupAddIcon } from 'assets/img/icon/icon__groupadd.svg';
import { ReactComponent as LogoFillIcon } from 'assets/img/icon/icon__logo--fill.svg';
import { ReactComponent as LogoSolidIcon } from 'assets/img/icon/icon__logo--soild.svg';
import { ReactComponent as LogoutIcon } from 'assets/img/icon/icon__logout.svg';
import { ReactComponent as ManholeIcon } from 'assets/img/icon/icon__manhole.svg';
import { ReactComponent as ManholeOpenIcon } from 'assets/img/icon/icon__manhole--open.svg';
import { ReactComponent as MapIcon } from 'assets/img/icon/icon__map.svg';
import { ReactComponent as MarkIcon } from 'assets/img/icon/icon__mark.svg';
import { ReactComponent as MenuIcon } from 'assets/img/icon/icon__menu.svg';
import { ReactComponent as NoneLineIcon } from 'assets/img/icon/icon__none-line.svg';
import { ReactComponent as NoticeIcon } from 'assets/img/icon/icon__notice.svg';
import { ReactComponent as SearchIcon } from 'assets/img/icon/icon__search.svg';
import { ReactComponent as SpeakerIcon } from 'assets/img/icon/icon__speaker.svg';
import { ReactComponent as Speaker2Icon } from 'assets/img/icon/icon__speaker2.svg';
import { ReactComponent as SystemIcon } from 'assets/img/icon/icon__system.svg';
import { ReactComponent as UserIcon } from 'assets/img/icon/icon__user.svg';
import { ReactComponent as WeatherIcon } from 'assets/img/icon/icon__weather.svg';
import { ReactComponent as WindIcon } from 'assets/img/icon/icon__weather--wind.svg';
import { ReactComponent as CloudIcon } from 'assets/img/icon/icon__weather--cloud1.svg';
import { ReactComponent as Cloud2Icon } from 'assets/img/icon/icon__weather--cloud2.svg';
import { ReactComponent as GaleIcon } from 'assets/img/icon/icon__weather--gale.svg';
import { ReactComponent as RainIcon } from 'assets/img/icon/icon__weather--rain1.svg';
import { ReactComponent as Rain2Icon } from 'assets/img/icon/icon__weather--rain2.svg';
import { ReactComponent as SnowIcon } from 'assets/img/icon/icon__weather--snow1.svg';
import { ReactComponent as Snow2Icon } from 'assets/img/icon/icon__weather--snow2.svg';
import { ReactComponent as SunnyIcon } from 'assets/img/icon/icon__weather--sunny1.svg';
import { ReactComponent as Sunny2Icon } from 'assets/img/icon/icon__weather--sunny2.svg';
import { ReactComponent as ThunderIcon } from 'assets/img/icon/icon__weather--thunder.svg';
import { ReactComponent as ThunderRainIcon } from 'assets/img/icon/icon__weather--thunderrain.svg';
import { ReactComponent as UmbrellaIcon } from 'assets/img/icon/icon__weather--umbrella.svg';

const icons = {
    add: AddIcon,
    alarm: AlarmIcon,
    arrowFill: ArrowFillIcon,
    arrowSolid: ArrowSolidIcon,
    cancel: CancelIcon,
    cctv: CctvIcon,
    cctvMark: CctvMarkIcon,
    chart: ChartIcon,
    chat: ChatIcon,
    check: CheckIcon,
    clock: ClockIcon,
    data: DataIcon,
    date: DateIcon,
    doubleArrow: DoubleArrowIcon,
    doubleArrowDown: DoubleArrowDownIcon,
    down: DownIcon,
    empty: EmptyIcon,
    error: ErrorIcon,
    error2: Error2Icon,
    group: GroupIcon,
    groupAdd: GroupAddIcon,
    logoFill: LogoFillIcon,
    logoSolid: LogoSolidIcon,
    logout: LogoutIcon,
    manhole: ManholeIcon,
    manholeOpen: ManholeOpenIcon,
    map: MapIcon,
    mark: MarkIcon,
    menu: MenuIcon,
    noneLine: NoneLineIcon,
    notice: NoticeIcon,
    search: SearchIcon,
    speaker: SpeakerIcon,
    speaker2: Speaker2Icon,
    system: SystemIcon,
    user: UserIcon,
    weather: WeatherIcon,
    wind: WindIcon,
    cloud: CloudIcon,
    cloud2: Cloud2Icon,
    gale: GaleIcon,
    rain: RainIcon,
    rain2: Rain2Icon,
    snow: SnowIcon,
    snow2: Snow2Icon,
    sunny: SunnyIcon,
    sunny2: Sunny2Icon,
    thunder: ThunderIcon,
    thunderRain: ThunderRainIcon,
    umbrella: UmbrellaIcon,
};

export default icons;
