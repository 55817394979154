import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";

export default function GaugeChart({rssiData}) {
    const chartRef = useRef(null);

    useEffect(() => {
        if (!chartRef.current) return;

        // ECharts 인스턴스 초기화
        const myChart = echarts.init(chartRef.current);

        // 차트 옵션 설정
        const option = {
            series: [
                {
                    type: "gauge",
                    startAngle: 180,
                    endAngle: 0,
                    center: ["50%", "75%"],
                    radius: "90%",
                    min: 0,
                    max: 1,
                    splitNumber: 8,
                    axisLine: {
                        lineStyle: {
                            width: 6,
                            color: [
                                [0.25, "#FF6E76"],
                                [0.5, "#FDDD60"],
                                [0.75, "#58D9F9"],
                                [1, "#7CFFB2"]
                            ]
                        }
                    },
                    pointer: {
                        icon: "path://M12.8,0.7l12,40.1H0.7L12.8,0.7z",
                        length: "12%",
                        width: 20,
                        offsetCenter: [0, "-60%"],
                        itemStyle: {
                            color: "auto"
                        }
                    },
                    axisTick: {
                        length: 12,
                        lineStyle: {
                            color: "auto",
                            width: 2
                        }
                    },
                    splitLine: {
                        length: 20,
                        lineStyle: {
                            color: "auto",
                            width: 5
                        }
                    },
                    axisLabel: {
                        color: "#aaa",
                        fontSize: 20,
                        distance: -45,
                        rotate: "tangential",
                        formatter: function (value) {
                            if (value === 0.875) {
                                return "Grade A";
                            } else if (value === 0.625) {
                                return "Grade B";
                            } else if (value === 0.375) {
                                return "Grade C";
                            } else if (value === 0.125) {
                                return "Grade D";
                            }
                            return "";
                        }
                    },
                    title: {
                        offsetCenter: [0, "-10%"],
                        fontSize: 20
                    },
                    detail: {
                        fontSize: 30,
                        offsetCenter: [0, "-35%"],
                        valueAnimation: true,
                        formatter: function (value) {
                            return Math.round(value * 100) + "";
                        },
                        color: "inherit"
                    },
                    data: [
                        {
                            value: ((100 + rssiData) * 0.01) > 100 ? 100 : ((100 + rssiData) * 0.01),
                            name: "Grade Rating"
                        }
                    ]
                }
            ]
        };

        // 옵션 설정
        myChart.setOption(option);

        // 리사이즈 이벤트 처리 (창 크기 변경 시 차트 크기 조정)
        window.addEventListener("resize", myChart.resize);

        // 언마운트 시 이벤트 정리
        return () => {
            window.removeEventListener("resize", myChart.resize);
            myChart.dispose();
        };
    }, [rssiData]);

    return <div ref={chartRef} style={{ width: "100%", height: "70%" }} />;
};


