import React from "react";
import {CardBody,Col,Table} from "reactstrap";
import {formatAnonymous, formatDateType, formatWaterLevel} from "../../utils/expressionForm.js";
import TabMenu from "../Sidebar/TabMenu";
import "../../assets/scss/manhole.scss";
import {stepColor} from "../../assets/js/stepStyle";
import Accordion from "../util/Accordion";
import {dataSpotDetailList, dataSpotDetailList2} from "../../mockData/api/prediction/mockManholeAndRepInformation";

const header = [{
    name: "지점", time:"조회시간", waterLevel:"현재수위", afterOne:"1분", afterTen:"10분", afterThirty:"30분",
    rep:"재해약자", accompanyingPartner:"동행파트너", step:"서비스 현황"
}];

//형식 변환
const formatData = (key, value) => {
    const unit1 = ['waterLevel','afterOne','afterTen','afterThirty'];
    const unit2 = ['rep','accompanyingPartner'];

    if(key === 'time') return formatDateType(value);        // 시간 형식
    if(unit1.includes(key)) return formatWaterLevel(value); // 단위 m

    if(unit2.includes(key)) { // 익명처리
        if(Array.isArray(value)) { // 맨홀에 매칭된 재해약자 2인 이상인 경우
            return value.map((item, index) => (
                <span key={index}>
                        {formatAnonymous(item)}
                    {index !== value.length-1 && <br />}
                </span>
            ));
        }
        return formatAnonymous(value);
    }
    return value || '-';
}

//메뉴 > 지도 > 지도 중앙 하단에 위치한 테이블
export const ManholeInfoTable = ({data}) => {
    if (!data || Object.keys(data).length === 0) return <p>데이터가 없습니다.</p>;

    const tableHeader = (
        <tr>
            {Object.entries(header[0]).map(([hKey, hItem]) => (
                <th className="bw-manhole_thead" key={hKey}>{hItem}</th>
            ))}
        </tr>);

    const tableRowData =
        (<tr>
            {Object.keys(header[0]).map((key) => (
                <td className="bw-manhole_td" key={key}>{formatData(key, data[key])}</td>
            ))}
        </tr>);


    return (<>
        <div className="bw-manhole-table">
            <Col xs="12" style={{margin:"auto"}} className="bw-manhole">
                <CardBody>
                    <Table className="tablesorter" responsive>
                        <thead>{tableHeader}</thead>
                        <tbody>{tableRowData}</tbody>
                    </Table>
                </CardBody>
            </Col>
        </div>
    </>);
};

//메뉴 > 지도 > 지도 좌측 상단에 위치한 테이블
export const ManholeInfoModal = ({data}) => {
    if (!data || Object.keys(data).length === 0) return <p>데이터가 없습니다.</p>;

    const tableRowData =
        Object.entries(header[0]).map(([hKey, hItem], hIndex) => (
            <tr className="bw-manhole_tr" key={`${hIndex}${hKey}`}>
                <th className="bw-manhole_thead " >{hItem}</th>
                <td className="bw-manhole_td">{formatData(hKey, data[hKey])}</td>
            </tr>
        ));

    return (<>
        <div className="bw-manhole-modal">
            <Col xs="12" className="bw-manhole">
                <CardBody>
                    <Table className="tablesorter" responsive>
                        <tbody>
                        {tableRowData}
                        </tbody>
                    </Table>
                </CardBody>
            </Col>
        </div>
    </>);
}

//메뉴 > 지도 > 우측 메뉴 단계별 리스트
export const ManholeList = ({data}) => {

    //ajax로 리스트 목록을 호출함 (지도의 좌표값 기준으로 목록 및 마커 재호출?)

    //1 step 그룹화
    const groupedData = data.reduce((acc, spot) => {
        if (!acc[spot.step]) {
            acc[spot.step] = [];
        }
        acc[spot.step].push(spot); //같은 step끼리 그룹화
        return acc;
    }, {});

    //2 내림차순 정렬
    const sortedGroupedData = Object.entries(groupedData)
        .sort(([stepA], [stepB]) => stepB - stepA);

    //3 그룹화 데이터 렌더링
    return sortedGroupedData.map(([step, spots]) => (
        <li key={`step${step}`}  data-key={step} className="bw-level__item">
            <h4 className="bw-level__title" style={{color:stepColor(step)}}>{step}단계</h4>
            <div className="bw-level__content">
                <Accordion items={spots}/>
            </div>
        </li>
    ));
}



//지도2 작업 중지
// export function ManholeInfoList({dataSpotList}) {
//     return (
//         <>
//             <div className="bw-manhole-list">
//                 <div>
//                     <TabMenu />
//                     <ManholeSpot dataSpotList={dataSpotList} />
//                 </div>
//             </div>
//         </>
//     );
// }

//지도2 작업 중지
// export function ManholeSpot({dataSpotList}) {
//     const manholeSpot = dataSpotList.map((spot, index) => (
//         <li key={index}>
//             <a href="#" >
//                 <span className="bw-manhole-spot-circle"></span>
//                 <p className="bw-manhole-spot-step">{spot.step}단계</p>
//                 <p className="bw-manhole-spot-name">{spot.name}</p>
//             </a>
//         </li>
//     ));
//
//     return (<>
//         <div className="bw-manhole-spot">
//             <ul>
//                 {manholeSpot}
//             </ul>
//         </div>
//     </>);
// }

