export const MockSensorData = {
    "last-ten-rows": [
        {
            "application_id": 1,
            "battery": 90,
            "dev_eui": "1",
            "device_name": "test",
            "distance": 650,
            "position": "1",
            "insert_time": "2021-10-08T10:00:00.000Z"
        },
        {
            "application_id": 1,
            "battery": 90,
            "dev_eui": "1",
            "device_name": "test",
            "distance": 700,
            "position": "1",
            "insert_time": "2021-10-08T10:01:00.000Z"
        },
        {
            "application_id": 1,
            "battery": 85,
            "dev_eui": "1",
            "device_name": "test",
            "distance": 800,
            "position": "1",
            "insert_time": "2021-10-08T10:02:00.000Z"
        },
        {
            "application_id": 1,
            "battery": 85,
            "dev_eui": "1",
            "device_name": "test",
            "distance": 800,
            "position": "1",
            "insert_time": "2021-10-08T10:03:00.000Z"
        },
        {
            "application_id": 1,
            "battery": 80,
            "dev_eui": "1",
            "device_name": "test",
            "distance": 750,
            "position": "1",
            "insert_time": "2021-10-08T10:04:00.000Z"
        },
        {
            "application_id": 1,
            "battery": 80,
            "dev_eui": "1",
            "device_name": "test",
            "distance": 750,
            "position": "1",
            "insert_time": "2021-10-08T10:05:00.000Z"
        },
        {
            "application_id": 1,
            "battery": 75,
            "dev_eui": "1",
            "device_name": "test",
            "distance": 700,
            "position": "1",
            "insert_time": "2021-10-08T10:06:00.000Z"
        },
        {
            "application_id": 1,
            "battery": 75,
            "dev_eui": "1",
            "device_name": "test",
            "distance": 700,
            "position": "1",
            "insert_time": "2021-10-08T10:07:00.000Z"
        },
        {
            "application_id": 1,
            "battery": 70,
            "dev_eui": "1",
            "device_name": "test",
            "distance": 650,
            "position": "1",
            "insert_time": "2021-10-08T10:08:00.000Z"
        },
        {
            "application_id": 1,
            "battery": 70,
            "dev_eui": "1",
            "device_name": "test",
            "distance": 550,
            "position": "1",
            "insert_time": "2021-10-08T10:09:00.000Z"
        },
    ]
};

export const getMockSensorData2 = () => [
    {
        id: 1,
        number: "A205",
        available: true,
        status: "normal",
        battery: (Math.random() * 100).toFixed(0),
        rssi: parseFloat((Math.random() * -100).toFixed(0))
    },
    {
        id: 2,
        number: "A206",
        available: true,
        status: "error",
        battery: (Math.random() * 100).toFixed(0),
        rssi: parseFloat((Math.random() * -100).toFixed(0))
    },
    {
        id: 3,
        number: "A207",
        available: true,
        status: "normal",
        battery: (Math.random() * 100).toFixed(0),
        rssi: parseFloat((Math.random() * -100).toFixed(0))
    },
    {
        id: 4,
        number: "A208",
        available: true,
        status: "warning",
        battery: (Math.random() * 100).toFixed(0),
        rssi: parseFloat((Math.random() * -100).toFixed(0))
    },
    {
        id: 5,
        number: "A209",
        available: true,
        status: "off",
        battery: (Math.random() * 100).toFixed(0),
        rssi: parseFloat((Math.random() * -100).toFixed(0))
    },
    {
        id: 6,
        number: "A210",
        available: true,
        status: "normal",
        battery: (Math.random() * 100).toFixed(0),
        rssi: parseFloat((Math.random() * -100).toFixed(0))
    },
    {
        id: 7,
        number: "A211",
        available: true,
        status: "error",
        battery: (Math.random() * 100).toFixed(0),
        rssi: parseFloat((Math.random() * -100).toFixed(0))
    },
    {
        id: 8,
        number: "A212",
        available: true,
        status: "normal",
        battery: (Math.random() * 100).toFixed(0),
        rssi: parseFloat((Math.random() * -100).toFixed(0))
    },
    {
        id: 9,
        number: "A213",
        available: true,
        status: "normal",
        battery: (Math.random() * 100).toFixed(0),
        rssi: parseFloat((Math.random() * -100).toFixed(0))
    }
];