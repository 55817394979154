//Map > 지도 위치 기반 spot list를 호출
export const MockManholeNameList = [
    {value: 'A6', label: 'A6'},
    {value: 'A20', label: 'A20'},
    {value: 'A1', label: 'A1'},
];
    // 'A16','A20','A1','A9','A7','A17','A21','A2','A11','A4','A12'];

//선택된 옵션값 manholeName 기준으로 ajax 호출 > 상세내용 받아옴
//addMarkerList에서 배열을 받아 map 위에 마커를 추가하기 때문
export const MockManholeMonitoring1 = [{
    dong: "자양동", //address dong?
    name: "A6", //manhole identification_code? 맨홀 식별번호?
    waterSensorNb: "LT-SWM-A0002082", //센서 기기번호
    waterSensorId: "device06", //센서 식별 아이디
    location: "서울특별시 광진구 뚝섬로30가길 18(자양동)", //설치장소
    installationDate: "2025-01-10 14:10:12.123456", //수위측정센서 설치일자?
    lon: 127.077973, //설치위치
    lat: 37.563996,
    testEquipment: true, //테스트 장비 여부 (boolean)
    note: '장비 점검 필요 250126',
}];

export const MockManholeMonitoring2 = [{
    dong: "화양동", //address dong?
    name: "A20", //manhole identification_code? 맨홀 식별번호?
    waterSensorNb: "LT-SWM-A0009457", //센서 기기번호
    waterSensorId: "device20", //센서 식별 아이디
    location: "서울특별시 광진구 능동로17길 39(화양동)", //설치장소
    installationDate: "2025-03-20 10:28:12.123456", //수위측정센서 설치일자?
    lon: 127.077903, //설치위치
    lat: 37.563996,
    testEquipment: false, //테스트 장비 여부 (boolean)
    note: '이상 없음',
}];

export const MockManholeMonitoring3 = [{
    dong: "구의동", //address dong?
    name: "A1", //manhole identification_code? 맨홀 식별번호?
    waterSensorNb: "LT-SWM-A0004311", //센서 기기번호
    waterSensorId: "device01", //센서 식별 아이디
    location: "서울특별시 광진구 자양로40길 41(구의동)", //설치장소
    installationDate: "2025-02-17 11:13:12.123456", //수위측정센서 설치일자?
    lon: 127.077973, //설치위치
    lat: 37.564996,
    testEquipment: true, //테스트 장비 여부 (boolean)
    note: '',
}];

export const MockManholeMonitoringMarker2 = [
    {},
];