import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import icons from './Icons';

/**
 * Dropdown 컴포넌트
 *
 * 사용 예시:
 * <Dropdown
 *   size="medium"                 // "small"(30px), "medium"(45px), "large"(60px)
 *   disabled={false}              // true일 경우, 클릭 불가능
 *   placeholder="Select an option"// 아무것도 선택되지 않았을 때 표시할 텍스트
 *   options={[
 *     { label: '안전단계', value: 'safe' },
 *     { label: '관심단계', value: 'concern' },
 *     { label: '주의단계', value: 'caution' },
 *     { label: '경계단계', value: 'warning' },
 *     { label: '심각단계', value: 'critical' },
 *   ]}
 *   onChange={(option) => console.log('선택된 옵션:', option)}
 * />
 */
const Dropdown = ({
                      size = 'medium',
                      disabled = false,
                      placeholder = 'Select an option',
                      options = [],
                      onChange,
                      defaultValue,
                  }) => {
    // defaultValue prop이 있으면 해당 옵션을 초기 선택 상태로 설정
    const [selected, setSelected] = useState(() => {
        if (defaultValue) {
            return options.find(option => option.value === defaultValue) || null;
        }
        return null;
    });
    const [isOpen, setIsOpen] = useState(false);
    const [dropup, setDropup] = useState(false);
    const dropdownRef = useRef(null);
    const ArrowIcon = icons.arrowSolid;

    // 드롭다운 영역 밖 클릭 시 닫기
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    // 메뉴 열릴 때, 뷰포트 하단 여유 공간 계산하여 dropup 여부 결정
    useEffect(() => {
        if (isOpen && dropdownRef.current) {
            const menuEl = dropdownRef.current.querySelector('.c-dropdown__menu');
            if (menuEl) {
                const rect = menuEl.getBoundingClientRect();
                const spaceBelow = window.innerHeight - rect.bottom;
                // 임계값 20px 이하이면 dropup 모드 적용
                setDropup(spaceBelow < 20);
            }
        } else {
            setDropup(false);
        }
    }, [isOpen]);

    const handleToggle = () => {
        if (!disabled) {
            setIsOpen(prev => !prev);
        }
    };

    const handleOptionClick = (option) => {
        setSelected(option);
        setIsOpen(false);
        if (onChange) {
            onChange(option);
        }
    };

    // 클래스 조합 (BEM)
    const classes = ['c-dropdown', `c-dropdown--${size}`];
    if (disabled) classes.push('c-dropdown--disabled');
    if (isOpen) classes.push('c-dropdown--open');
    if (dropup) classes.push('c-dropdown--dropup');

    // 회전 각도: 기본 상태에서 오른쪽 아이콘을 아래로 (rotate 90deg) 회전, 열렸을 때 위로 (rotate 270deg)
    const arrowStyle = {
        transform: isOpen ? 'rotate(270deg)' : 'rotate(90deg)',
        transition: 'transform 0.2s ease',
    };

    return (
        <div className={classes.join(' ')} ref={dropdownRef}>
            <button
                type="button"
                className="c-dropdown__selected"
                onClick={handleToggle}
                disabled={disabled}
            >
        <span className="c-dropdown__label">
          {selected ? selected.label : placeholder}
        </span>
                {ArrowIcon && (
                    <span className="c-dropdown__icon">
            <ArrowIcon style={arrowStyle} />
          </span>
                )}
            </button>
            {isOpen && (
                <ul className="c-dropdown__menu">
                    {options.map((option) => (
                        <li
                            key={option.value}
                            className={`c-dropdown__option ${selected && selected.value === option.value ? 'selected' : ''}`}
                            onClick={() => handleOptionClick(option)}
                        >
                            {option.label}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

Dropdown.propTypes = {
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
        })
    ),
    onChange: PropTypes.func,
    defaultValue: PropTypes.string,
};

export default Dropdown;
