import React, {useEffect, useState} from "react";
import { Card, CardBody, CardText, Container, Row, Col } from "reactstrap";
import { FaCheckCircle, FaExclamationTriangle, FaTimesCircle, FaPowerOff } from "react-icons/fa";

const statusIcons = {
    normal: <FaCheckCircle style={{ color: "#007bff", fontSize: "20px" }} />,
    warning: <FaExclamationTriangle style={{ color: "#ffc107", fontSize: "20px" }} />,
    error: <FaTimesCircle style={{ color: "#dc3545", fontSize: "20px" }} />,
    off: <FaPowerOff style={{ color: "#aaa", fontSize: "20px" }} />
};

export default function DeviceList({ deviceData, selectedDevice, setSelectedDevice }) {

    useEffect(() => {
        if (!deviceData || deviceData.length === 0) return;

        // 선택된 장치가 `deviceData`에 없는 경우 초기화
        const isSelectedValid = deviceData.some(device => device.id === selectedDevice?.id);
        if (!isSelectedValid) {
            setSelectedDevice(deviceData[0]);
        } else {
            setSelectedDevice(deviceData.find(device => device.id === selectedDevice?.id));
        }
    }, [deviceData]);

    const handleCardClick = (item) => {
        setSelectedDevice(item);
    };

    return (
        <Container className="mt-4">
            <h3 className="mb-3">⦁ 단말상태</h3>

            {/* 상태 필터 */}
            <Row className="mb-3 justify-content-end">
                <Col md="auto">{statusIcons.normal} 정상</Col>
                <Col md="auto">{statusIcons.warning} 이상</Col>
                <Col md="auto">{statusIcons.error} 오류</Col>
                <Col md="auto">{statusIcons.off} OFF</Col>
            </Row>

            {/* 카드 리스트 */}
            <Row>
                {deviceData?.map((item) => (
                    <Col md={12} key={item.id} className="mb-2">
                        <Card
                            onClick={() => handleCardClick(item)}
                            className="cursor-pointer shadow-lg"
                            style={{
                                border: selectedDevice?.id === item.id ? "1px solid #f8f9fa" : "1px solid #343a40",
                                transition: "0.3s ease-in-out"
                            }}
                        >
                            <CardBody className="d-flex justify-content-between align-items-center">
                                <div>
                                    <CardText className="mb-1"><strong>[단말번호]</strong> {item.number}</CardText>
                                    <CardText className="text-muted"><small>[가용상태] {item.available ? "on" : "off"}</small></CardText>
                                </div>
                                <div>
                                    {statusIcons[item.status]}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};
