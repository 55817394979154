/*!

=========================================================
* Black Dashboard React v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect, useState} from "react";
import {Card, CardBody} from "reactstrap";
import ExampleMap from "../components/OpenLayersMap/ExampleMap.js";
import AddMarkerList from "../components/OpenLayersMap/AddMarkerList";

import {ManholeInfoModal, ManholeInfoTable, ManholeList} from "../components/Table/MapManholeTable";
import {dataSpotDetailList} from "../mockData/api/prediction/mockManholeAndRepInformation";


// todo 조회 목록의 단어 통일 필요함 (현재시간 or 조회시간 어떤것이 적절한가?)

function Map() {

    const [map, setMap] = useState(null);
    const [manholeData, setManholeData] = useState([]);
    const [selectManhole, setSelectedManhole] = useState(null);

    //ajax를 통해 list 호출
    useEffect(()=> {
        setManholeData(dataSpotDetailList);
        // fetch("api/map/manhole/list")
        //     .then(res => res.json())
        //     .then(data => setManholeData(data))
        //     .catch(err => console.log(err));
    }, []);

    return (
        <>
            <div className="bw-flex">
                <div className="bw-flex--7">
                    <Card>
                        <CardBody>
                            <ExampleMap onMapReady={setMap}>
                                {map && <AddMarkerList mapRef={map}
                                                       data={manholeData}
                                                       setSelectedManhole={setSelectedManhole} />}
                                {manholeData && (
                                    <>
                                        <ManholeInfoTable data={selectManhole} />
                                        <ManholeInfoModal data={selectManhole} />
                                    </>
                                )}
                            </ExampleMap>
                        </CardBody>
                    </Card>

                </div>

                <div className="bw-flex--3">
                    <Card>
                        <CardBody style={{overflowY: "auto"}}>
                            <div className="bw-level">
                                <ul className="bw-level__list">
                                    <ManholeList data={manholeData} />
                                </ul>
                            </div>
                        </CardBody>
                    </Card>

                </div>
            </div>
        </>
    );
}




// const accordionData = [
//     {
//     title: "A6",
//     content: (
//         <Table>
//             <tbody>
//             <tr>
//                 <th>현재시간</th>
//                 <td>23:10</td>
//                 <th>현재수위</th>
//                 <td>3.2m</td>
//             </tr>
//             <tr>
//                 <th colSpan="2" rowSpan="3">예측수위</th>
//                 <th>1분</th>
//                 <td>3.3m</td>
//             </tr>
//             <tr>
//                 <th>10분</th>
//                 <td>4.0m</td>
//             </tr>
//             <tr>
//                 <th>30분</th>
//                 <td>3.0m</td>
//             </tr>
//             <tr>
//                 <th>재해약자</th>
//                 <td>이O빈(sb001)</td>
//                 <th>동행서비스</th>
//                 <td>김O철(sc007)</td>
//             </tr>
//             <tr>
//                 <th>동행서비스 알림</th>
//                 <td colSpan="3">
//                     <ToggleSelect
//                         options={["1단계", "2단계", "3단계", "4단계"]}
//                         defaultSwitchState={true} // 기본적으로 ON
//                         defaultSelectedOption="1단계" // 기본 선택 옵션
//                     />
//                 </td>
//             </tr>
//             </tbody>
//         </Table>
//     ),
//     },
//   {
//     title: "A8",
//     content: (
//       <Table>
//           <tbody>
//           <tr>
//               <th>현재시간</th>
//               <td>23:10</td>
//               <th>현재수위</th>
//               <td>3.2m</td>
//           </tr>
//           <tr>
//               <th colSpan="2" rowSpan="3">예측수위</th>
//               <th>1분</th>
//               <td>3.3m</td>
//           </tr>
//           <tr>
//               <th>10분</th>
//               <td>4.0m</td>
//           </tr>
//           <tr>
//               <th>30분</th>
//               <td>3.0m</td>
//           </tr>
//           <tr>
//               <th>재해약자</th>
//               <td>이O빈(sb001)</td>
//               <th>동행서비스</th>
//               <td>김O철(sc007)</td>
//           </tr>
//           <tr>
//               <th>동행서비스 알림</th>
//               <td colSpan="3">
//                   <ToggleSelect
//                       options={["1단계", "2단계", "3단계", "4단계"]}
//                       defaultSwitchState={true} // 기본적으로 ON
//                       defaultSelectedOption="1단계" // 기본 선택 옵션
//                   />
//               </td>
//           </tr>
//           </tbody>
//       </Table>
//     ),
//   },
//     {
//         title: "A1",
//         content: (
//             <Table>
//                 <tbody>
//                 <tr>
//                     <th>현재시간</th>
//                     <td>23:10</td>
//                     <th>현재수위</th>
//                     <td>3.2m</td>
//                 </tr>
//                 <tr>
//                     <th colSpan="2" rowSpan="3">예측수위</th>
//                     <th>1분</th>
//                     <td>3.3m</td>
//           </tr>
//           <tr>
//               <th>10분</th>
//               <td>4.0m</td>
//           </tr>
//           <tr>
//               <th>30분</th>
//               <td>3.0m</td>
//           </tr>
//           <tr>
//               <th>재해약자</th>
//               <td>이O빈(sb001)</td>
//               <th>동행서비스</th>
//               <td>김O철(sc007)</td>
//           </tr>
//                 <tr>
//                     <th>동행서비스 알림</th>
//                     <td colSpan="3">
//                         <ToggleSelect
//                             options={["1단계", "2단계", "3단계", "4단계"]}
//                             defaultSwitchState={true} // 기본적으로 ON
//                             defaultSelectedOption="1단계" // 기본 선택 옵션
//                         />
//                     </td>
//                 </tr>
//                 </tbody>
//             </Table>
//         ),
//     },
// ];
// const accordionData2 = [
//     {
//         title: "G8",
//         content: (
//             <Table>
//                 <tbody>
//                 <tr>
//                     <th>현재시간</th>
//                     <td>23:10</td>
//                     <th>현재수위</th>
//                     <td>3.2m</td>
//                 </tr>
//                 <tr>
//                     <th colSpan="2" rowSpan="3">예측수위</th>
//                     <th>1분</th>
//               <td>3.3m</td>
//           </tr>
//           <tr>
//               <th>10분</th>
//               <td>4.0m</td>
//           </tr>
//           <tr>
//               <th>30분</th>
//               <td>3.0m</td>
//           </tr>
//           <tr>
//               <th>재해약자</th>
//               <td>이O빈(sb001)</td>
//               <th>동행서비스</th>
//               <td>김O철(sc007)</td>
//           </tr>
//                 <tr>
//                     <th>동행서비스 알림</th>
//                     <td colSpan="3">
//                         <ToggleSelect
//                             options={["1단계", "2단계", "3단계", "4단계"]}
//                             defaultSwitchState={true} // 기본적으로 ON
//                             defaultSelectedOption="1단계" // 기본 선택 옵션
//                         />
//                     </td>
//                 </tr>
//                 </tbody>
//             </Table>
//         ),
//     },
//     {
//         title: "J1",
//         content: (
//             <Table>
//                 <tbody>
//                 <tr>
//                     <th>현재시간</th>
//                     <td>23:10</td>
//                     <th>현재수위</th>
//                     <td>3.2m</td>
//                 </tr>
//                 <tr>
//                     <th colSpan="2" rowSpan="3">예측수위</th>
//                     <th>1분</th>
//                     <td>3.3m</td>
//           </tr>
//           <tr>
//               <th>10분</th>
//               <td>4.0m</td>
//           </tr>
//           <tr>
//               <th>30분</th>
//               <td>3.0m</td>
//           </tr>
//           <tr>
//               <th>재해약자</th>
//               <td>이O빈(sb001)</td>
//               <th>동행서비스</th>
//               <td>김O철(sc007)</td>
//           </tr>
//                 <tr>
//                     <th>동행서비스 알림</th>
//                     <td colSpan="3">
//                         <ToggleSelect
//                             options={["1단계", "2단계", "3단계", "4단계"]}
//                             defaultSwitchState={true} // 기본적으로 ON
//                             defaultSelectedOption="1단계" // 기본 선택 옵션
//                         />
//                     </td>
//                 </tr>
//                 </tbody>
//             </Table>
//         ),
//     },
// ];
// const accordionData3 = [
//     {
//         title: "G5",
//         content: (
//             <Table>
//                 <tbody>
//                 <tr>
//                     <th>현재시간</th>
//                     <td>23:10</td>
//                     <th>현재수위</th>
//                     <td>3.2m</td>
//                 </tr>
//                 <tr>
//                     <th colSpan="2" rowSpan="3">예측수위</th>
//                     <th>1분</th>
//                     <td>3.3m</td>
//                 </tr>
//                 <tr>
//                     <th>10분</th>
//                     <td>4.0m</td>
//                 </tr>
//                 <tr>
//                     <th>30분</th>
//                     <td>3.0m</td>
//                 </tr>
//                 <tr>
//                     <th>재해약자</th>
//                     <td>이O빈(sb001)</td>
//                     <th>동행서비스</th>
//                     <td>김O철(sc007)</td>
//                 </tr>
//                 <tr>
//                     <th>동행서비스 알림</th>
//                     <td colSpan="3">
//                         <ToggleSelect
//                             options={["1단계", "2단계", "3단계", "4단계"]}
//                             defaultSwitchState={true} // 기본적으로 ON
//                             defaultSelectedOption="1단계" // 기본 선택 옵션
//                         />
//                     </td>
//                 </tr>
//                 </tbody>
//             </Table>
//         ),
//     },
// ];
// const accordionData4 = [
//     {
//         title: "G7",
//         content: (
//             <Table>
//                 <tbody>
//                 <tr>
//                     <th>현재시간</th>
//                     <td>23:10</td>
//                     <th>현재수위</th>
//                     <td>3.2m</td>
//                 </tr>
//                 <tr>
//                     <th colSpan="2" rowSpan="3">예측수위</th>
//                     <th>1분</th>
//                     <td>3.3m</td>
//                 </tr>
//                 <tr>
//                     <th>10분</th>
//                     <td>4.0m</td>
//                 </tr>
//                 <tr>
//                     <th>30분</th>
//                     <td>3.0m</td>
//                 </tr>
//                 <tr>
//                     <th>재해약자</th>
//                     <td>이O빈(sb001)</td>
//                     <th>동행서비스</th>
//                     <td>김O철(sc007)</td>
//                 </tr>
//                 <tr>
//                     <th>동행서비스 알림</th>
//                     <td colSpan="3">
//                         <ToggleSelect
//                             options={["1단계", "2단계", "3단계", "4단계"]}
//                             defaultSwitchState={true} // 기본적으로 ON
//                             defaultSelectedOption="1단계" // 기본 선택 옵션
//                         />
//                     </td>
//                 </tr>
//                 </tbody>
//             </Table>
//         ),
//     },
// ];

// function Map() {
//     return (
//         <>
//             <div className="bw-flex">
//                 <div className="bw-flex--7">
//                     <Card>
//                         <CardBody>
//                             <ExampleMap></ExampleMap>
//                         </CardBody>
//                     </Card>
//
//                 </div>
//
//                 <div className="bw-flex--3">
//                     <Card>
//                     <CardBody style={{overflowY: 'auto'}}>
//                     <div className="bw-level">
//                         <ul className="bw-level__list">
//                             <li className="bw-level__item">
//                                 <h4 className="bw-level__title bw-level__title--4">4단계 지점</h4>
//                                 <div className="bw-level__content">
//                                     <Accordion items={accordionData} />
//                                 </div>
//                             </li>
//                             <li className="bw-level__item">
//                                 <h4 className="bw-level__title bw-level__title--3">3단계 지점</h4>
//                                 <div className="bw-level__content">
//                                     <Accordion items={accordionData2}/>
//                                 </div>
//                             </li>
//                             <li className="bw-level__item">
//                                 <h4 className="bw-level__title bw-level__title--2">2단계 지점</h4>
//                                 <div className="bw-level__content">
//                                     <Accordion items={accordionData3}/>
//                                 </div>
//                             </li>
//                             <li className="bw-level__item">
//                                 <h4 className="bw-level__title bw-level__title--1">1단계 지점</h4>
//                                 <div className="bw-level__content">
//                                     <Accordion items={accordionData4}/>
//                                 </div>
//                             </li>
//                         </ul>
//                     </div>
//                 </CardBody>
//             </Card>
//
//         </div>
//       </div>
//         {/*<div className="content">*/}
//         {/*  <Row>*/}
//         {/*    <Col md="12">*/}
//         {/*      <Card className="card-plain">*/}
//         {/*        <CardHeader>Google Maps</CardHeader>*/}
//         {/*        <CardBody>*/}
//         {/*          <div*/}
//         {/*            id="map"*/}
//         {/*            className="map"*/}
//         {/*            style={{ position: "relative", overflow: "hidden" }}*/}
//         {/*          >*/}
//         {/*            <MapWrapper />*/}
//         {/*          </div>*/}
//         {/*        </CardBody>*/}
//         {/*      </Card>*/}
//         {/*    </Col>*/}
//         {/*  </Row>*/}
//         {/*</div>*/}
//     </>
//   );
// }

export default Map;
