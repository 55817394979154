import React from 'react';
import PropTypes from 'prop-types';
import Badge from './Badge';       // 앞서 만든 Badge 컴포넌트
import icons from './Icons';       // 아이콘 모듈 (예: { date: DateIcon, warning: WarningIcon, ... })

/**
 * Card 컴포넌트
 *
 * 하나의 Card 컴포넌트 내부에 다양한 콘텐츠(children)를 포함할 수 있고,
 * 상태(status) prop에 따라 전체 스타일(예: error, success, warning)을 변경할 수 있습니다.
 *
 * 사용 예시:
 * <Card status="error">            // "error", "success", "warning" 등
 *   <Card.Header
 *     iconName="notice"          // Icons.js에 등록된 아이콘 이름
 *     iconColor="#FF6347"
 *     title="배수 불량",
 *     titleVariant="error"        // "success" 또는 "error"를 전달해 제목 색상을 SCSS 클래스로 적용
 *     titleAlign="center",
 *     subTitle="센서 1"
 *     badgeProps={{
 *       text: '심각 : 6',
 *       grade: 4,
 *       variant: 'solid',
 *       size: 'small'
 *     }}
 *   />
 *   <Card.Body>
 *     <p style={{ margin: 0, fontSize: '1.5rem' }}>N/A</p>
 *   </Card.Body>
 *   <Card.Footer>
 *     <button onClick={() => console.log('자세히 보기')}>자세히 보기</button>
 *   </Card.Footer>
 * </Card>
 */
const Card = ({ status, children, ...props }) => {
    const classes = ['c-card'];
    if (status) {
        classes.push(`c-card--${status}`);
    }

    return (
        <div className={classes.join(' ')} {...props}>
            {children}
        </div>
    );
};

Card.propTypes = {
    status: PropTypes.oneOf(['error', 'success', 'warning']),
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    children: PropTypes.node,
};

/* Card.Header : 카드의 헤더 영역, 아이콘, 제목, 부제목, 배지를 포함 */
const CardHeader = ({ iconName, iconColor, title, titleVariant, titleAlign, subTitle, badgeProps }) => {
    const IconComponent = iconName ? icons[iconName] : null;
    return (
        <div className="c-card__header">
            {IconComponent && (
                <span className="c-card__icon">
          <IconComponent style={{ fill: iconColor }} />
        </span>
            )}
            <div className="c-card__title-group">
                {title && (
                    <h3 className={`c-card__title ${
                            titleVariant ? `c-card__title--${titleVariant}` : ''
                        } ${titleAlign === 'center' ? 'c-card__title--center' : ''}`}
                    >
                        {title}
                    </h3>
                )}
                {subTitle && <span className="c-card__subtitle">{subTitle}</span>}
            </div>
            {badgeProps && (
                <span className="c-card__badge">
          <Badge {...badgeProps} />
        </span>
            )}
        </div>
    );
};

CardHeader.propTypes = {
    iconName: PropTypes.string,
    iconColor: PropTypes.string,
    title: PropTypes.string,
    titleVariant: PropTypes.oneOf(['success', 'error']),
    titleAlign: PropTypes.oneOf(['center', 'left']),
    subTitle: PropTypes.string,
    badgeProps: PropTypes.object,
};

CardHeader.defaultProps = {
    titleAlign: 'left',
};

/* Card.Body : 카드의 주요 콘텐츠 영역 */
const CardBody = ({ children }) => (
    <div className="c-card__body">{children}</div>
);

CardBody.propTypes = {
    children: PropTypes.node,
};

/* Card.Footer : 카드의 하단 영역, 버튼이나 추가 정보를 표시 */
const CardFooter = ({ children }) => (
    <div className="c-card__footer">{children}</div>
);

CardFooter.propTypes = {
    children: PropTypes.node,
};

Card.Header = CardHeader;
Card.Body = CardBody;
Card.Footer = CardFooter;

export default Card;