import axios from "axios";

// API 엔드포인트 기본 URL
const API_BASE_URL = "http://127.0.0.1:8000";

export const fetchChartData = async () => {
    try {
        // 실제 API 호출
        const response = await axios.get(`${API_BASE_URL}/chart-data`);
        return response.data;
    } catch (error) {
        console.error("Failed to fetch chart data. Using fallback data.", error);

        // 기본 데이터 반환
        return {
            labels: [
                "10월 1일 00:00",
                "10월 1일 12:00",
                "10월 2일 00:00",
                "10월 2일 12:00",
                "10월 3일 00:00",
                "10월 3일 12:00",
                "10월 4일 00:00",
                "10월 4일 12:00",
                "10월 5일 00:00",
                "10월 5일 12:00",
                "10월 6일 00:00",
                "10월 6일 12:00",
            ],
            datasets: [
                {
                    label: "실측 수위",
                    data: [100, 70, 90, 70, 85, 60, 75, 60, 90, 80, 110, 100],
                },
                {
                    label: "10분 후",
                    data: [80, 120, 105, 110, 95, 105, 90, 100, 80, 95, 70, 120],
                },
                {
                    label: "30분 후",
                    data: [60, 80, 65, 130, 80, 105, 90, 130, 70, 115, 60, 130],
                },
            ],
        };
    }
};
