import {useEffect, useRef, useState} from "react";
import Chart from "chart.js/auto";

export default function ({selectedGridData}) {
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const water = selectedGridData?.water?.[selectedIndex] ?? 0;

    useEffect(()=>{
        setSelectedIndex(0);
    },[selectedGridData])

    useEffect(() => {
        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy(); // 기존 차트 제거
        }

        const ctx = chartRef.current.getContext("2d");
        ctx.clearRect(0, 0, chartRef.current.width, chartRef.current.height);
        chartInstanceRef.current = new Chart(ctx, {
            type: "doughnut",
            data: {
                labels: [" 현재 수위", " 남은 용량"],
                datasets: [
                    {
                        data: [water, 100 - water],
                        backgroundColor: [
                            water > 90 ? "#dc3545" :
                                water > 70 ? "#fd7e14" :
                                    water > 50 ? "#ffc107" :
                                        water > 30 ? "#007bff" : "#aaa", "#ddd"
                        ],
                        borderWidth: 0,
                        cutout: "50%", // 내부 원 크기
                    },
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                rotation: -90,
                circumference: 180,
                cutout: "50%",
                plugins: {
                    legend: {display: true}, // 범례 표시
                    tooltip: {enabled: true}, // 툴팁 활성화
                    title: { // 차트 제목 추가
                        display: true,
                        text: water > 90 ? "심각 단계" :
                            water > 70 ? "경계 단계" :
                                water > 50 ? "주의 단계" :
                                    water > 30 ? "관심 단계" : "안전 단계", // 제목 텍스트
                        font: {
                            size: 40, // 제목 글씨 크기
                            weight: "bold" // 글씨 스타일
                        },
                        color: "#ddd", // 제목 색상
                        padding: 20 // 제목과 차트 사이 간격
                    }
                }
            },
            plugins: [centerTextPlugin(water)] // 텍스트 출력 플러그인 추가
        });

        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
                chartInstanceRef.current = null;
            }
        };
    }, [selectedGridData, selectedIndex]);

    return (
        <div style={{ textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div style={{ width: "100%", height: "100%", position: "relative" }}>
                <canvas ref={chartRef} height="400"></canvas>
            </div>
            <div style={{ marginTop: "10px" }}>
                {selectedGridData?.manhole_code?.map((name, index) => (
                    <button
                        key={index}
                        onClick={() => setSelectedIndex(index)}
                        style={{
                            margin: "5px",
                            padding: "8px 12px",
                            backgroundColor: index === selectedIndex ? "#007bff" : "#ccc",
                            color: "white",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer"
                        }}
                    >
                        {name}
                    </button>
                ))}
            </div>
        </div>
    );
}

const centerTextPlugin = (value) => ({
    id: "centerText",
    beforeDraw: (chart) => {
        const {ctx, chartArea} = chart;
        if (!chartArea) return;

        const {width, height} = chartArea;
        const centerX = width / 2;
        const centerY = height / 2;

        ctx.save();
        ctx.fillStyle = "#ddd"; // 텍스트 색상
        ctx.font = `bold ${Math.min(width, height) * 0.2}px Arial`; // 텍스트 스타일
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText(`${value}%`, centerX, centerY * 2.5); // 중앙에 텍스트 추가
        ctx.restore();

        // ctx.fillStyle = "#ddd"; // 텍스트 색상
        // ctx.font = `bold ${Math.min(width, height) * 0.08}px Arial`; // 텍스트 스타일
        // ctx.textAlign = "center";
        // ctx.textBaseline = "middle";
        // ctx.fillText("0", width * 0.13, height * 1.25);
        // ctx.fillText("100", width * 0.87, height + 100);
        // ctx.restore();
    }
});
