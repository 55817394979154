import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import icons from '../../components/util/Icons';

/**
 * Menu 컴포넌트
 *
 * - 햄버거 버튼(클릭 영역: 70×70px, 내부 선: 32×4px, 간격: 6px)
 *   를 클릭하면 우측에서 400px 너비의 메뉴가 슬라이드되어 나타납니다.
 * - 메뉴 항목은 아이콘(클래스명을 통해 아이콘을 지정)과 텍스트로 구성되며,
 *   클릭 시 해당 경로로 이동합니다.
 */
const Menu = () => {
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef(null);

    const handleToggle = () => {
        setIsOpen(prev => !prev);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    // 메뉴 항목 데이터
    const menuItems = [
        { label: '시설관리', icon: 'system', to: '/facility-management' },
        { label: '지도', icon: 'map', to: '/map' },
        { label: '데이터', icon: 'data', to: '/data' },
        { label: '관리자', icon: 'user', to: '/user-management' },
        { label: '로그아웃', icon: 'logout', to: '/logout' },
    ];

    return (
        <div className="c-menu" ref={menuRef}>
            {/* 햄버거 버튼 */}
            <button
                className={`c-menu__hamburger ${isOpen ? 'open' : ''}`}
                onClick={handleToggle}
            >
                <span className="c-menu__line"></span>
                <span className="c-menu__line"></span>
                <span className="c-menu__line"></span>
            </button>

            {/* 슬라이드 메뉴 오버레이 */}
            <div className={`c-menu__overlay ${isOpen ? 'open' : ''}`}>
                <ul className="c-menu__list">
                    {menuItems.map((item) => {
                        const IconComponent = icons[item.icon]; // Icons 모듈에서 해당 아이콘 컴포넌트를 가져옴
                        return (
                            <li className="c-menu__item" key={item.to}>
                                <Link to={item.to} onClick={() => setIsOpen(false)}>
                                    {IconComponent && (
                                        <span className="c-menu__icon">
                                          <IconComponent />
                                        </span>
                                    )}
                                    <span className="c-menu__label">{item.label}</span>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default Menu;