import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

/**
 * Switch 컴포넌트
 *
 * 사용 예시:
 * <Switch
 *   id="my-switch"              // 선택사항: 고유 ID (없으면 자동 생성)
 *   checked={true}              // 스위치의 선택 여부 (boolean)
 *   onChange={(checked) => console.log(checked)}  // 스위치 상태 변경 시 호출되는 함수
 *   disabled={false}            // 비활성화 여부 (boolean)
 *   label="스위치 레이블"        // 선택사항: 스위치 옆에 표시할 텍스트
 * />
 *
 * 설명:
 * - 이 컴포넌트는 접근성을 위해 input과 label을 분리하여 사용합니다.
 * - id prop이 전달되지 않으면, 내부적으로 고유한 id를 생성합니다.
 * - label 클릭 시 해당 스위치가 토글됩니다.
 */

const Switch = ({ id, checked, onChange, disabled, label }) => {
    // id가 전달되지 않았다면, 고유 id 생성 (주의: 매 렌더링마다 바뀌면 안 되므로 useMemo 사용)
    const switchId = useMemo(() => id || `switch-${Math.random().toString(36).substr(2, 9)}`, [id]);

    const handleChange = (e) => {
        if (!disabled && onChange) {
            onChange(e.target.checked);
        }
    };

    return (
        <div className="c-switch-wrapper">
            <input
                id={switchId}
                type="checkbox"
                className="c-switch__input"
                checked={checked}
                onChange={handleChange}
                disabled={disabled}
            />
            <label htmlFor={switchId} className={`c-switch ${disabled ? 'c-switch--disabled' : ''}`}>
                <span className="c-switch__slider" />
                {label && <span className="c-switch__label">{label}</span>}
            </label>
        </div>
    );
};

Switch.propTypes = {
    id: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    label: PropTypes.string,
};

Switch.defaultProps = {
    checked: false,
    disabled: false,
};

export default Switch;
