import { Map, View } from "ol";
import { Attribution, defaults as defaultControls } from "ol/control.js";
import TileLayer from "ol/layer/Tile";
import { fromLonLat } from "ol/proj";
import OSM from "ol/source/OSM";
import React, {useEffect, useRef} from "react";
import "ol/ol.css";


const attribution = new Attribution({
  collapsible: false,
});

const ExampleMap = ({children, style, onMapReady}) => {
  const mapRef = useRef(null); //지도만 반환

  useEffect(() => {
    // 중곡1동 경도와 위도
    const seoulCoordinates = fromLonLat([127.078501, 37.563737]);

    // OpenLayers 맵 생성
    const map = new Map({
      controls: defaultControls({ attribution: false }).extend([attribution]),
      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      view: new View({
        center: seoulCoordinates,
        zoom: 18,
      }),
    });

    const stopPageScroll = (e) => e.preventDefault();

    const mapContainer = mapRef.current;
    mapContainer.addEventListener("wheel", stopPageScroll, { passive: false });

    if (onMapReady) onMapReady(map);

    return () => {
      mapContainer.removeEventListener("wheel", stopPageScroll);
      map.setTarget(null);
    };
  }, [onMapReady]);

  return (
      <>
        <div ref={mapRef} style={{width: "100%", height: "100%",position:"relative", ...style}}>
          {children}
        </div>
      </>

  );
};

export default ExampleMap;
