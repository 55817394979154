import React, { useId } from "react";

const ToggleSwitch = ({ isChecked, onChange }) => {
    const uniqueId = useId(); // React가 자동 생성하는 고유 ID

    return (
        <div className="toggle">
            <input
                type="checkbox"
                id={`toggle-${uniqueId}`}
                className="toggle__checkbox"
                checked={isChecked}
                onChange={onChange}
            />
            <label className="toggle__label" htmlFor={`toggle-${uniqueId}`}></label>
        </div>
    );
};

export default ToggleSwitch;
