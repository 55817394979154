import React from 'react';
import PropTypes from 'prop-types';
import Switch from './Switch';
import Dropdown from './Dropdown';

/**
 * StepLine 컴포넌트
 *
 * 예시 데이터 구조:
 * const stepData = [
 *   { label: "현재시간", value: "2025.03.10 23:10", subLabel: "현재수위", subValue: "5.2m" },
 *   { label: "주소", value: "서울특별시 광진구 긴고랑로 12" },
 *   {
 *     label: "예측수위",
 *     items: [
 *       { time: "1분", value: "6.2m" },
 *       { time: "10분", value: "6.5m" },
 *       { time: "30분", value: "6.9m" },
 *     ]
 *   },
 *   { label: "재해약자", value: "이*말", subLabel: "동행파트너", subValue: "김*원" },
 *   { label: "돌봄공무원", value: "이*말" },
 *   // 동행서비스 알림 항목: type이 alert이면 Switch와 Dropdown이 렌더링됨
 *   { type: "alert", label: "동행서비스 알림", switchProps: { checked: true }, dropdownProps: { size: "small", options: [
 *         { label: '옵션1', value: 'option1' },
 *         { label: '옵션2', value: 'option2' },
 *       ], placeholder: "옵션 선택" } }
 * ];
 *
 * Props:
 * - stateLevel: 0~4, 단계에 따라 원과 선의 색상이 결정됩니다.
 * - data: 각 항목의 정보 배열 (위 예시와 유사한 구조)
 * - size: "default" (기본값) 또는 "small" 사이즈를 지정합니다.
 */
function StepLine({ stateLevel = 0, data = [], size = 'default' }) {
    return (
        <div className={`c-stepline c-stepline--state${stateLevel} ${size === 'small' ? 'c-stepline--small' : ''}`}>
            {data.map((item, idx) => {
                // alert 타입이면 동행서비스 알림 항목으로 처리
                if (item.type === 'alert') {
                    return (
                        <div className="c-stepline__item" key={idx}>
                            {/* 왼쪽 원 (circle) */}
                            <div className="c-stepline__circle"></div>
                            {/* 세로 선: 마지막 항목 제외 */}
                            {idx < data.length - 1 && (
                                <div className="c-stepline__line"></div>
                            )}
                            <div className="c-stepline__content">
                                <div className="c-stepline__label-value">
                                    <div className="c-stepline__label-value-item">
                                        <span className="c-stepline__label">{item.label}</span>
                                        <div className="c-stepline__alert-controls">
                                            {/* onChange 핸들러가 포함된 switchProps가 전달되어야 토글이 가능합니다. */}
                                            <Switch {...item.switchProps} />
                                            <Dropdown {...item.dropdownProps} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }

                // 일반 스텝 항목 렌더링
                return (
                    <div className="c-stepline__item" key={idx}>
                        {/* 왼쪽 원 (circle) */}
                        <div className="c-stepline__circle"></div>
                        {/* 세로 선: 마지막 항목 제외 */}
                        {idx < data.length - 1 && (
                            <div className="c-stepline__line"></div>
                        )}
                        {/* 오른쪽 내용 */}
                        <div className="c-stepline__content">
                            <div className="c-stepline__label-value">
                                <span className="c-stepline__label-value-item">
                                  <span className="c-stepline__label">{item.label}</span>
                                    {item.value && (
                                        <span className="c-stepline__value">{item.value}</span>
                                    )}
                                </span>
                                {item.subLabel && item.subValue && (
                                    <span className="c-stepline__label-value-item">
                                        <span className="c-stepline__label">{item.subLabel}</span>
                                        <span className="c-stepline__value">{item.subValue}</span>
                                    </span>
                                )}
                            </div>
                            {item.items && item.items.length > 0 && (
                                <div className="c-stepline__list">
                                    {item.items.map((subItem, subIdx) => (
                                        <div key={subIdx} className="c-stepline__list-item">
                                            <span className="c-stepline__label">{subItem.time}</span>
                                            <span className="c-stepline__value">{subItem.value}</span>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

StepLine.propTypes = {
    stateLevel: PropTypes.oneOf([0, 1, 2, 3, 4]),
    data: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string,
            label: PropTypes.string,
            value: PropTypes.string,
            subLabel: PropTypes.string,
            subValue: PropTypes.string,
            items: PropTypes.arrayOf(
                PropTypes.shape({
                    time: PropTypes.string,
                    value: PropTypes.string,
                })
            ),
            switchProps: PropTypes.object,
            dropdownProps: PropTypes.object,
        })
    ),
    size: PropTypes.oneOf(['default', 'small']),
};

export default StepLine;