import React from "react";

export const TextareaCustom = ({content, style, onChange}) => {
    return (<>
        <textarea value={content ?? ""}
                  onChange={(e) => onChange &&onChange(e.target.value)}
                  style={{width: "100%",
                      display:"flex",
                      background: "#27293D",
                      color: "rgba(255, 255, 255, 0.7)",
                      overflowY: "auto", ...style}}
                  id="message"
                  rows="4"
                  className="rounded-md bw-scrollbar"
                  placeholder="메모를 입력하세요"></textarea>
    </>);
};