import React, { useEffect, useRef, useMemo } from 'react';
import Chart from 'chart.js/auto';

/**
 * DoughnutChart
 *
 * 사용 예시:
 * <DoughnutChart manholeData={someArray} />
 *
 * - manholeData: 예) [{ water: 95 }, { water: 45 }, ...]
 * - water 값에 따라 5단계(안전, 관심, 주의, 경계, 심각)로 분류
 * - 도넛 차트로 표시 (cutout: "40%")
 * - 차트 우측에 색상별 범례 표시
 */
export default function DoughnutChart({ manholeData }) {
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);

    // water 값에 따라 a~e 구간(심각~안전)을 카운트
    // a(>90), b(>70), c(>50), d(>30), e(<=30)
    const categorizedData = useMemo(() => {
        const data = { a: 0, b: 0, c: 0, d: 0, e: 0 };
        if (manholeData?.length) {
            manholeData.forEach(({ water }) => {
                if (water > 90) data.a += 1;      // 심각단계
                else if (water > 70) data.b += 1; // 경계단계
                else if (water > 50) data.c += 1; // 주의단계
                else if (water > 30) data.d += 1; // 관심단계
                else data.e += 1;                // 안전단계
            });
        }
        return data;
    }, [manholeData]);

    useEffect(() => {
        // 기존 차트가 있으면 제거
        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }

        const ctx = chartRef.current.getContext('2d');

        chartInstanceRef.current = new Chart(ctx, {
            type: 'doughnut',
            data: {
                labels: ['안전단계', '관심단계', '주의단계', '경계단계', '심각단계'],
                datasets: [
                    {
                        // 순서: e(안전), d(관심), c(주의), b(경계), a(심각)
                        data: [
                            categorizedData.e,
                            categorizedData.d,
                            categorizedData.c,
                            categorizedData.b,
                            categorizedData.a,
                        ],
                        backgroundColor: [
                            '#7e9eb5', // 안전단계 (회색)
                            '#3dd990', // 관심단계 (초록)
                            '#0094fa', // 주의단계 (파랑)
                            '#ffd419', // 경계단계 (노랑)
                            '#ff6462', // 심각단계 (빨강)
                        ],
                        borderWidth: 0,
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                rotation: 0,
                circumference: 360,
                cutout: '40%', // 도넛 두께 (0%~90%)
                plugins: {
                    legend: {
                        display: true,
                        position: 'right', // 범례를 우측에 표시
                    },
                    tooltip: { enabled: true },
                    // title: {
                    //     display: true,
                    //     text: '광진구 침수 위험 상황',
                    //     font: { size: 16, weight: 'bold' },
                    //     color: '#111',
                    //     padding: 20,
                    // },
                },
            },
        });

        return () => {
            // 언마운트 시 차트 제거
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
                chartInstanceRef.current = null;
            }
        };
    }, [categorizedData]);

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <canvas ref={chartRef} />
        </div>
    );
}
