import React, {useEffect, useRef, useState} from "react";
import L from "leaflet";
import {statesData} from "./geojson.js";
import "leaflet/dist/leaflet.css";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
    shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
});

export default function GridMap({setSelectedGridData, manholeData}) {
    const mapRef = useRef(null);
    const [geoData, setGeoData] = useState(statesData);
    const junggokIntersection = [37.5634607293747, 127.079348661082];

    // 지도 초기화 OSM
    useEffect(() => {
        if (!mapRef.current) {
            const map = L.map("gridMap", {
                center: junggokIntersection,
                maxBounds: [
                    [37.56128, 127.07596],
                    [37.56535, 127.08274],
                ],
                zoom: 18,
            });

            L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
                maxZoom: 19,
                minZoom: 17,
                attribution: '&copy; OpenStreetMap contributors'
            }).addTo(map);
            mapRef.current = map; // 맵 인스턴스 저장
        }
    }, []);

    // 그리드 레이어 추가
    useEffect(() => {
        if (!mapRef.current) return;

        const map = mapRef.current;

        // 기존 GeoJSON 레이어 제거
        map.eachLayer((layer) => {
            if (layer instanceof L.GeoJSON) {
                map.removeLayer(layer);
            }
        });

        const geojsonLayer = L.geoJson(geoData, {
            style: (feature) => ({
                weight: 0.2,
                color: "white",
                dashArray: "3",
                fillOpacity: 0.5,
                fillColor: getColor(risk(feature)),
            }),
            onEachFeature: (feature, layer) => {
                layer.bindPopup(setPopupContent(feature));

                layer.on("popupopen", () => {
                    // 버튼 이벤트 바인딩
                    const button = document.getElementById(`button-${feature.id}`);
                    const select = document.getElementById(`select-${feature.id}`);

                    if (button && select) {
                        button.addEventListener("click", () => {
                            const selectedValue = select.value;
                            alert(`수위: ${feature.properties.water ?? 0}, 단계: ${selectedValue}`);
                        });
                    }
                });

                layer.on({
                    mouseover: (e) => highlightFeature(e, layer),
                    mouseout: (e) => geojsonLayer.resetStyle(e.target),
                    click: (e) => {
                        setSelectedGridData(feature.properties);
                        // zoomToFeature(e, layer);
                    },
                });
            },
        }).addTo(map);

        function highlightFeature(e, layer) {
            layer.setStyle({
                weight: 3,
                color: "#666",
                dashArray: "",
                fillOpacity: 0.7,
            });
        }

        function zoomToFeature(e) {
            map.fitBounds(e.target.getBounds());
        }

        return () => {
            // Cleanup: 언마운트 시 레이어 제거
            map.removeLayer(geojsonLayer);
        };
    }, [geoData]); // geoData가 변경될 때마다 실행

    const getColor = (d) => {
        return d > 90 ? "#dc3545"
            : d > 70 ? "#fd7e14"
                : d > 50 ? "#ffc107"
                    : d > 30 ? "#007bff"
                        : "transparent";
    };

    const risk = (feature) => {
        return feature.properties.water?.length > 0 ? Math.max(...feature.properties.water.filter(Number.isFinite)) : 0;
    }

    const setPopupContent = (feature) => {
        let popupContent = `
                <div>
                    <b>Row:</b> ${feature.properties.row_index}<br/>
                    <b>Column:</b> ${feature.properties.col_index}<br/>
                    <b>표고:</b> ${feature.properties.dn}<br/>`;
        if (feature.properties.manhole_code.length > 0) {
            popupContent += `
                    <b>맨홀:</b> ${feature.properties.manhole_code.join(", ") || "None"}<br/>
                    `;
        }
        if (feature.properties.water?.length > 0) {
            popupContent += `
                    <b>수위:</b> ${feature.properties.water.join(", ") || "None"}<br/>
                    `
        }
        popupContent += `
                <br/><b>동행서비스 
                <select id="select-${feature.id}">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                </select>
                단계 <button id="button-${feature.id}">발송</button></b></div>`;
        return popupContent;
    }

    useEffect(() => {
        manholeData.forEach(({manholeCode, water}) => {
            updateFeature(manholeCode, water);
        });
    }, [manholeData]);

    const updateFeature = (id, newDn) => {
        setGeoData((prevGeoData) => ({
            ...prevGeoData,
            features: prevGeoData.features.map((feature) => {
                if (
                    feature.properties.manhole_code &&
                    Array.isArray(feature.properties.manhole_code) &&
                    feature.properties.manhole_code.includes(id)
                ) {
                    const updatedWaterList = feature.properties.water || new Array(feature.properties.manhole_code.length).fill(0);
                    const index = feature.properties.manhole_code.indexOf(id);
                    if (index !== -1) {
                        updatedWaterList[index] = newDn;
                    }

                    return {
                        ...feature,
                        properties: {
                            ...feature.properties,
                            water: updatedWaterList,
                        },
                    };
                }
                return feature;
            }),
        }));
    };

    // Interval로 10초마다 버튼 동작 실행
    // useEffect(() => {
    //     updateFeatureByManholeId();
    //
    //     const interval = setInterval(() => {
    //         updateFeatureByManholeId();
    //     }, 10000);
    //
    //     return () => clearInterval(interval); // Cleanup: 컴포넌트 언마운트 시 인터벌 제거
    // }, []);

    return (
        <div id="gridMap" style={{width: "100%", height: "100%"}}/>
    );
}
