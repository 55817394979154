import React, { useState } from 'react';
import PropTypes from 'prop-types';
import icons from './Icons';

// 단계별 색상 매핑 (stateLevel 0~4)
const stateLevelColorMap = {
    0: '#e5e9ec',   // safe
    1: '#3dd990',   // concern
    2: '#0094fa',   // caution
    3: '#ffd419',   // warning
    4: '#ff6462',   // danger
  };
  
  function AccordionItem({ title, stateLevel = 0, content, size = 'default', staticMode = false, defaultOpen = false }) {
    // 초기 상태는 defaultOpen prop에 따라 설정
    const [isOpen, setIsOpen] = useState(defaultOpen);
    const levelColor = stateLevelColorMap[stateLevel] || '#e5e9ec';
  
    // Icons 모듈에서 arrowFill 컴포넌트를 가져옵니다.
    const ArrowFillIcon = icons.arrowFill;
  
    // staticMode가 false일 때만 토글 가능
    const toggleAccordion = () => {
      if (!staticMode) {
        setIsOpen(!isOpen);
      }
    };
  
    return (
      <div className="c-accordion">
        <button
          className={`c-accordion__button ${size === 'small' ? 'c-accordion__button--small' : ''} ${staticMode ? 'defaultCursor' : ''}`}
          onClick={toggleAccordion}
          style={{ borderColor: levelColor, backgroundColor: levelColor }}
        >
          <span className={`c-accordion__title ${stateLevel !== 0 ? 'white' : ''}`}>
            {title}
          </span>
          {/* staticMode일 때는 아이콘 없이 단순 버튼 형태 */}
          {!staticMode && (
            <span className={`c-accordion__icon ${stateLevel !== 0 ? 'white' : ''}`}>
              {ArrowFillIcon && (
                <ArrowFillIcon
                  style={{
                    transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s',
                  }}
                />
              )}
            </span>
          )}
        </button>
        {((!staticMode && isOpen) || (staticMode && defaultOpen)) && (
          <div className="c-accordion__content">{content}</div>
        )}
      </div>
    );
  }
  
  AccordionItem.propTypes = {
    title: PropTypes.string.isRequired,
    stateLevel: PropTypes.oneOf([0, 1, 2, 3, 4]),
    content: PropTypes.node,
    size: PropTypes.oneOf(['default', 'small']),
    staticMode: PropTypes.bool,
    defaultOpen: PropTypes.bool,
  };
  
  export default AccordionItem;