import React from 'react';
import {chartExample1} from "../../variables/charts";
import {Line} from "react-chartjs-2";
import DoughnutGraph from "../../views/DoughnutGraphD";
import HalfGaugeChart from "../../views/GaugeGraphD";
import {getMockPredictionMonitoringData4} from "../../mockData/api/prediction/mockPredictionMonitoringData";
import Tabs from "../../components/util/Tabs";
import Badge from "../../components/util/Badge";
import AccordionItem from '../../components/util/AccordionItem';
import StatesHeading from '../../components/util/StatesHeading';
import StepLine from '../../components/util/StepLine';
import icons from '../../components/util/Icons';
import GroupMonitoringCard from "../../components/util/GroupMonitoringCard";
import Card from "../../components/util/Card";
import BadgeGroup from "../../components/util/BadgeGroup";
import Table from '../../components/util/Table';


// 10분 20분 30분
const tabData = [
    {
        id: 'tab1',
        label: '10분',
    },
    {
        id: 'tab2',
        label: '20분',
    },
    {
        id: 'tab3',
        label: '30분',
    },
];

// table data
const columns = [
    { key: 'point', label: '지점' },
    { key: 'center', label: '센터' },
    { key: 'status', label: '상태' },
    { key: 'time', label: '시간' },
];

const data = [
    { point: 'A206', center: 'C0', status: '이상', time: '2024-04-01 09:06:00' },
    { point: 'A207', center: 'C1', status: '정상', time: '2024-04-01 09:07:00' },
    { point: 'A208', center: 'C2', status: '경고', time: '2024-04-01 09:08:00' },
];

const columns2 = [
    { key: 'partner', label: '파트너' },
    { key: 'log', label: '출동기록' },
];

const data2 = [
    { partner: '2024-04-01 09:06:00', log: '이*빈 (SD001)'},
    { partner: '2024-04-01 09:06:00', log: '이*빈 (SD001)'},
    { partner: '2024-04-01 09:06:00', log: '이*빈 (SD001)'},
];

function MainLayout() {
    const [bigChartData, setbigChartData] = React.useState("data1");
    const [selectedGridData, setSelectedGridData] = React.useState(null);
    const [manholeData, setManholeData] = React.useState([]);
    const [switchOn, setSwitchOn] = React.useState(true);

    const setBgChartData = (name) => {
        setbigChartData(name);
    };

    React.useEffect(() => {
        setManholeData(getMockPredictionMonitoringData4());
        const interval = setInterval(() => {
            setManholeData(getMockPredictionMonitoringData4());
        }, 10000);

        return () => clearInterval(interval); // Cleanup: 컴포넌트 언마운트 시 인터벌 제거
    }, []);

    // StepLine 예시 데이터
    const stepData = [
        { label: "현재시간", value: "2025.03.10 23:10", subLabel: "현재수위", subValue: "5.2m" },
        { label: "주소", value: "서울특별시 광진구 긴고랑로 12" },
        {
            label: "예측수위",
            items: [
                { time: "1분", value: "6.2m" },
                { time: "10분", value: "6.5m" },
                { time: "30분", value: "6.9m" },
            ]
        },
        { label: "재해약자", value: "이*말", subLabel: "동행파트너", subValue: "김*원" },
        { label: "돌봄공무원", value: "이*말" },
        {
            type: "alert",
            label: "동행서비스 알림",
            switchProps: {
                checked: switchOn,
                onChange: (newVal) => {
                    console.log("Switch toggled:", newVal);
                    setSwitchOn(newVal);
                }
            },
            dropdownProps: {
                size: "small",
                options: [
                    { label: '안전단계', value: 'safe' },
                    { label: '관심단계', value: 'concern' },
                    { label: '주의단계', value: 'caution' },
                    { label: '경계단계', value: 'warning' },
                    { label: '심각단계', value: 'critical' },
                ],
                placeholder: "경계단계"
            }
        }
    ];

    const ChartIcon = icons.chart;
    const CCTVIcons = icons.cctv;
    const GroupIcons = icons.group;


    return (
        <div className="main-layout">
            {/* 왼쪽: 차트 영역 */}
            <div className="main-layout__left">
                {/* 첫 번째 차트 묶음 */}
                <div className="chart-group">
                    <div className="chart-group__item">
                        <div className="chart">
                            <div className="chart__header">
                                <h4 className="chart__title"><span>2025.3.22 PM 10:30:55</span></h4>
                            </div>
                            <div className="chart__content">
                                <div className="doughnut">
                                    <DoughnutGraph manholeData={manholeData}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="chart-group__item">
                        <div className="chart">
                            <div className="chart__header">
                                <h4 className="chart__title">위험지역</h4>
                                <div className="chart__badge">
                                    <Badge text="주의단계" grade={2} variant="solid" size="small"/>
                                </div>
                            </div>
                            <div className="chart__content">
                                <div className="halfGauge">
                                    <HalfGaugeChart selectedGridData={selectedGridData}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="chart-group__item">
                        <div className="chart nonPadding">
                            <div className="chart__content">
                                <div className="line">
                                    <Line
                                        data={chartExample1[bigChartData]}
                                        options={chartExample1.options}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="chart-group__item">
                        <div className="chart nonPadding" style={{padding: '0.625rem'}}>
                            <div className="chart__header">
                                <StatesHeading stateLevel={3}>
                                    경계단계
                                </StatesHeading>
                            </div>
                            <div className="chart__content">
                                <AccordionItem
                                    title="수위 및 예측 정보"
                                    size="small"
                                    staticMode="true"
                                    stateLevel ="3"  // 단계에 따라 색상이 적용됩니다.
                                    defaultOpen={true}
                                    content={<StepLine stateLevel={3} data={stepData} size="small" />}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* 두 번째 차트 묶음 */}
                <div className="chart-group">
                    <div className="chart-group__item">
                        <div className="chart">
                            <div className="chart__header">
                                <h4 className="chart__title">예측데이터</h4>
                                <div className="chart__console">
                                    <Tabs
                                        tabs={tabData}
                                        defaultTab="tab1"
                                        onTabChange={(id) => console.log("활성 탭:", id)}
                                    />
                                </div>
                            </div>
                            <div className="chart__content">
                                <div className="doughnut">
                                    <DoughnutGraph manholeData={manholeData}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="chart-group__item">
                        <div className="chart">
                            <div className="chart__header">
                                <h4 className="chart__title">위험지역</h4>
                                <div className="chart__badge">
                                    <Badge text="경계단계" grade={3} variant="solid" size="small"/>
                                </div>
                            </div>
                            <div className="chart__content">
                                <div className="halfGauge">
                                    <HalfGaugeChart selectedGridData={selectedGridData}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="chart-group__item">
                        <div className="chart nonPadding">
                            <div className="chart__content">
                                <div className="line">
                                    <Line
                                        data={chartExample1[bigChartData]}
                                        options={chartExample1.options}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="chart-group__item">
                        <div className="chart nonPadding" style={{padding: '0.625rem'}}>
                            <div className="chart__header">
                                <StatesHeading stateLevel={1}>
                                    관심단계
                                </StatesHeading>
                            </div>
                            <div className="chart__content">
                                <AccordionItem
                                    title="수위 및 예측 정보"
                                    size="small"
                                    staticMode="true"
                                    stateLevel="1"  // 단계에 따라 색상이 적용됩니다.
                                    defaultOpen={true}
                                    content={<StepLine stateLevel={1} data={stepData} size="small"/>}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* 오른쪽: 모니터링 영역 */}
            <div className="main-layout__right">
                <div className="main-layout__sensors">
                    <div className="main-layout__box">
                        <div className="main-layout__box-header">
                            <h3 className="main-layout__box-heading"><span
                                className="main-layout__icon">{ChartIcon && <ChartIcon/>}</span>지점별 모니터링</h3>
                        </div>
                        <div className="main-layout__box-content">
                            <div className="main-monitoring">
                                <div className="main-monitoring__console">
                                    <div className="main-monitoring__count">
                                        <p className="main-monitoring__count-total">총 <span>30</span>개소</p>
                                        <ul>
                                            <li>관심: <span>0</span></li>
                                            <li>주의: <span>2</span></li>
                                            <li>경계: <span>10</span></li>
                                            <li>심각: <span>18</span></li>
                                        </ul>
                                    </div>
                                    <div className="main-monitoring__time">
                                        <Tabs
                                            tabs={tabData}
                                            defaultTab="tab1"
                                            onTabChange={(id) => console.log("활성 탭:", id)}
                                        />
                                    </div>
                                </div>
                                <div className="main-monitoring__content">
                                    <div className="monitoring-group">
                                        <div className="monitoring-group__total">
                                            <GroupMonitoringCard
                                                title="효서로 145번길"
                                                totalPoints={6}
                                                badges={[
                                                    {text: "정상 : 6", grade: 0, variant: "solid", size: "small"},
                                                    {text: "이상 : 1", grade: 4, variant: "solid", size: "small"},
                                                ]}
                                            />
                                        </div>
                                        <div className="monitoring-group__list">
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="monitoring-group">
                                        <div className="monitoring-group__total">
                                            <GroupMonitoringCard
                                                title="효서로 145번길"
                                                totalPoints={6}
                                                badges={[
                                                    {text: "정상 : 6", grade: 0, variant: "solid", size: "small"},
                                                    {text: "이상 : 1", grade: 4, variant: "solid", size: "small"},
                                                ]}
                                            />
                                        </div>
                                        <div className="monitoring-group__list">
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="monitoring-group">
                                        <div className="monitoring-group__total">
                                            <GroupMonitoringCard
                                                title="효서로 145번길"
                                                totalPoints={6}
                                                badges={[
                                                    {text: "정상 : 6", grade: 0, variant: "solid", size: "small"},
                                                    {text: "이상 : 1", grade: 4, variant: "solid", size: "small"},
                                                ]}
                                            />
                                        </div>
                                        <div className="monitoring-group__list">
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="monitoring-group">
                                        <div className="monitoring-group__total">
                                            <GroupMonitoringCard
                                                title="효서로 145번길"
                                                totalPoints={6}
                                                badges={[
                                                    {text: "정상 : 6", grade: 0, variant: "solid", size: "small"},
                                                    {text: "이상 : 1", grade: 4, variant: "solid", size: "small"},
                                                ]}
                                            />
                                        </div>
                                        <div className="monitoring-group__list">
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="monitoring-group">
                                        <div className="monitoring-group__total">
                                            <GroupMonitoringCard
                                                title="효서로 145번길"
                                                totalPoints={6}
                                                badges={[
                                                    {text: "정상 : 6", grade: 0, variant: "solid", size: "small"},
                                                    {text: "이상 : 1", grade: 4, variant: "solid", size: "small"},
                                                ]}
                                            />
                                        </div>
                                        <div className="monitoring-group__list">
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="monitoring-group">
                                        <div className="monitoring-group__total">
                                            <GroupMonitoringCard
                                                title="효서로 145번길"
                                                totalPoints={6}
                                                badges={[
                                                    {text: "정상 : 6", grade: 0, variant: "solid", size: "small"},
                                                    {text: "이상 : 1", grade: 4, variant: "solid", size: "small"},
                                                ]}
                                            />
                                        </div>
                                        <div className="monitoring-group__list">
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="monitoring-group">
                                        <div className="monitoring-group__total">
                                            <GroupMonitoringCard
                                                title="효서로 145번길"
                                                totalPoints={6}
                                                badges={[
                                                    {text: "정상 : 6", grade: 0, variant: "solid", size: "small"},
                                                    {text: "이상 : 1", grade: 4, variant: "solid", size: "small"},
                                                ]}
                                            />
                                        </div>
                                        <div className="monitoring-group__list">
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="monitoring-group">
                                        <div className="monitoring-group__total">
                                            <GroupMonitoringCard
                                                title="효서로 145번길"
                                                totalPoints={6}
                                                badges={[
                                                    {text: "정상 : 6", grade: 0, variant: "solid", size: "small"},
                                                    {text: "이상 : 1", grade: 4, variant: "solid", size: "small"},
                                                ]}
                                            />
                                        </div>
                                        <div className="monitoring-group__list">
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="monitoring-group__item">
                                                <Card>
                                                    <Card.Header
                                                        title="타이틀"
                                                        titleVariant="success"
                                                    />
                                                    <Card.Body>
                                                        <BadgeGroup
                                                            items={[
                                                                {
                                                                    title: '현재',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                                {
                                                                    title: '예측',
                                                                    badgeProps: {
                                                                        text: '주의',
                                                                        grade: 2,
                                                                        variant: 'solid',
                                                                        size: 'small',
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-layout__notice">
                    <div className="main-layout__box">
                        <div className="main-layout__box-header">
                            <h3 className="main-layout__box-heading icons"><span
                                className="main-layout__icon">{CCTVIcons && <CCTVIcons/>}</span>CCTV</h3>
                        </div>
                        <div className="main-layout__box-content paddingTop">
                            <Table columns={columns} data={data} size="40" align="center" />
                        </div>
                    </div>
                    <div className="main-layout__box">
                        <div className="main-layout__box-header">
                            <h3 className="main-layout__box-heading icons"><span
                                className="main-layout__icon">{GroupIcons && <GroupIcons/>}</span>동행서비스 현황</h3>
                        </div>
                        <div className="main-layout__box-content paddingTop">
                            <Table columns={columns2} data={data2} size="40" align="center" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainLayout;
