import React from 'react';
import PropTypes from 'prop-types';

/**
 * InputText 컴포넌트
 *
 * 사용 예시:
 * <InputText
 *   type="password"         // "text" (기본값), "password", 등
 *   size="small"            // "small" (높이 30px, font-size 12px),
 *                           // "medium" (높이 45px, font-size 20px, 기본값),
 *                           // "large" (높이 60px, font-size 20px)
 *   variant="error"         // 상태 variant (예: error, success 등; 선택 사항)
 *   value={value}
 *   onChange={(e) => setValue(e.target.value)}
 *   placeholder="값을 입력하세요"
 * />
 *
 * props:
 * - type: input의 타입 (예: "text", "password", "email" 등). 기본값은 "text".
 * - size: "small", "medium", "large" 중 하나로, 입력창의 크기를 결정합니다.
 *         기본값은 "medium"입니다.
 * - variant: 상태에 따른 variant (예: error, success, disabled 등)
 * - value: 입력값
 * - onChange: 값 변경 시 호출되는 이벤트 핸들러
 * - placeholder: placeholder 문구
 * - ...props: 나머지 input 속성 (예: name, id 등)
 */
const InputText = ({
                       type = 'text',
                       size,
                       variant,
                       value,
                       onChange,
                       placeholder,
                       ...props
                   }) => {
    const isDisabled = variant === 'disabled';

    // 기본 클래스 "c-input"에 size 및 variant modifier를 추가합니다.
    const classes = ['c-input'];
    classes.push(`c-input--${size ? size : 'medium'}`);
    if (variant) {
        classes.push(`c-input--${variant}`);
    }

    return (
        <input
            type={type}
            className={classes.join(' ')}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            disabled={isDisabled}
            {...props}
        />
    );
};

InputText.propTypes = {
    type: PropTypes.string,
    size: PropTypes.oneOf(['small', 'medium', 'large', 'login']),
    variant: PropTypes.oneOf([
        'active',
        'typing',
        'disabled',
        'error',
        'placeholder',
        'success',
    ]),
    value: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
};

export default InputText;
