
import React, {useEffect, useState} from "react";
import {Table} from "reactstrap";
import {TextareaCustom} from "../util/TextareaCustom";



export const MonitoringInfoTable = ({monitoringInfo}) => {

    const [editData, setEditData] = useState({
        name: null,
        dong: null,
        waterSensorNb: null,
        waterSensorId: null,
        location: null,
        installationDate: null,
        lon: null,
        lat: null,
        testEquipment: null,
        note: null,
    });

    useEffect(() => {
        if(monitoringInfo && monitoringInfo.length > 0) {
            setEditData({
                name: monitoringInfo?.[0]?.name ?? null,
                dong: monitoringInfo?.[0]?.dong ?? null,
                waterSensorNb: monitoringInfo?.[0]?.waterSensorNb ?? null,
                waterSensorId: monitoringInfo?.[0]?.waterSensorId ?? null,
                location: monitoringInfo?.[0]?.location ?? null,
                installationDate: monitoringInfo?.[0]?.installationDate ?? null,
                lon: monitoringInfo?.[0]?.lon ?? null,
                lat: monitoringInfo?.[0]?.lat ?? null,
                testEquipment:monitoringInfo?.[0]?.testEquipment ?? null,
                note: monitoringInfo?.note ?? null,
            });
        }
    }, [monitoringInfo]);

    const handleChange = (key, value) => {
        setEditData((prev) => ({...prev, [key]: value}));
    };

    const editApply = async (e) => {
        if(!monitoringInfo || monitoringInfo.length === 0) {
            e.preventDefault();
            return;
        }

        console.log(editData);
        // const res = await $.ajax(`api/monitoring/info/edit/${monitoringInfo[0]?.name}`, 'POST', editData, function() {
        //
        // });
    }


    return (<>
        <Table className="tablesorter" responsiv style={{margin: "15px 0"}}>
            <tbody>
            <tr>
                <th>측정소명</th>
                <td>{editData.dong || "-"}</td>
                <th>측정소아이디</th>
                <td>{editData.name || "-"}</td>
            </tr>
            <tr>
                <th>기기번호</th>
                <td>{editData.waterSensorNb || "-"}</td>
                <th>센서아이디</th>
                <td>{editData.waterSensorId || "-"}</td>
            </tr>
            <tr>
                <th>설치장소</th>
                <td><TextareaCustom style={{width:"100%", height:"100px"}}
                                    content={editData.location || "-"}
                                    onChange={(value) => handleChange("location", value)}/></td>
                <th>설치일</th>
                <td>{editData.installationDate || "-"}</td>
            </tr>
            <tr>
                <th>설치좌표</th>
                <td><span>{editData.lon || "-"}</span> /
                    <span> {editData.lat || "-"}</span></td>
                <th>테스트장비여부</th>
                <td>{editData.testEquipment === true ? "Y" :
                    monitoringInfo?.[0]?.testEquipment === false ? "F" : "-"}</td>
            </tr>
            <tr>
                <th>메모</th>
                <td colSpan="3"><TextareaCustom content={editData.note}
                                                onChange={(value) => handleChange("note", value)}/></td>
            </tr>
            <tr>
                <td colSpan="3">※ 지도 좌표를 기반으로 맨홀 목록을 불러옵니다.</td>
                <td colSpan="1"><button style={{float:"right"}} className="btn btn-secondary" onClick={editApply}>수정</button></td>
            </tr>
            </tbody>
        </Table>
    </>);
};
