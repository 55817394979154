import React from "react";

export default function () {
  return (
    <iframe
      src="https://thingsboard.broadwave.co.kr/dashboard/1d30bcc0-9da6-11ef-87e1-2d20a6723cf2?publicId=51cfe690-8ac3-11ef-87e1-2d20a6723cf2"
      style={{
        width: "100%",
        height: "100%",
        border: "none",
      }}
      title="ThingsBoard Dashboard"
    />
  );
}
