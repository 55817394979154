import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";

/**
 * HalfGaugeChart
 *
 * 사용 예시:
 * <HalfGaugeChart selectedGridData={someData} />
 *
 * - selectedGridData?.water?.[selectedIndex] => 현재 수위
 * - 버튼을 통해 manhole_code 중 하나를 선택하면 selectedIndex가 바뀌어 차트가 갱신됩니다.
 * - 최대값은 200으로 고정.
 * - 반원 형태로 (water)만큼 파란색, (200 - water)는 회색으로 표시.
 */
export default function HalfGaugeChart({ selectedGridData }) {
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);
    const [selectedIndex, setSelectedIndex] = useState(0);

    // 현재 water 값 (0~200)
    const water = selectedGridData?.water?.[selectedIndex] ?? 0;

    // selectedGridData가 바뀔 때 인덱스 초기화
    useEffect(() => {
        setSelectedIndex(0);
    }, [selectedGridData]);

    useEffect(() => {
        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy(); // 기존 차트 제거
        }

        const ctx = chartRef.current.getContext("2d");
        const maxValue = 200;
        const clampedWater = Math.min(water, maxValue);
        const remain = maxValue - clampedWater;

        chartInstanceRef.current = new Chart(ctx, {
            type: "doughnut",
            data: {
                labels: ["현재 수위", "남은 용량"],
                datasets: [
                    {
                        data: [water, 100 - water],
                        backgroundColor: [
                            water > 90 ? "#ff6462" :
                                water > 70 ? "#ffd419" :
                                    water > 50 ? "#0094fa" :
                                        water > 30 ? "#3dd990" : "#7e9eb5", "#e5e9ec"
                        ],
                        borderWidth: 0,
                        cutout: "50%", // 내부 원 크기
                    },
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                rotation: -90,       // 차트 시작 각도(위쪽)
                circumference: 180,  // 반원만 사용
                cutout: "50%",
                plugins: {
                    legend: { display: false }, // 범례 숨김
                    tooltip: { enabled: true }, // 툴팁 활성화
                },
            },
            plugins: [centerTextPlugin(clampedWater, maxValue)],
        });

        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
                chartInstanceRef.current = null;
            }
        };
    }, [selectedGridData, selectedIndex, water]);

    return (
        <div style={{ textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div style={{ width: "100%", height: "100%", position: "relative" }}>
                <canvas ref={chartRef} height="100%"></canvas>
            </div>

            {/* manhole_code 버튼 목록 */}
            <div style={{ marginTop: "10px" }}>
                {selectedGridData?.manhole_code?.map((name, index) => (
                    <button
                        key={index}
                        onClick={() => setSelectedIndex(index)}
                        style={{
                            margin: "5px",
                            padding: "8px 12px",
                            backgroundColor: index === selectedIndex ? "#007bff" : "#ccc",
                            color: "white",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer"
                        }}
                    >
                        {name}
                    </button>
                ))}
            </div>
        </div>
    );
}

/**
 * 중앙에 water% 표시, 왼쪽에 0, 오른쪽에 max(200) 표시
 */
// function centerTextPlugin(value, maxValue) {
//     const percent = ((value / maxValue) * 100).toFixed(0);
//     return {
//         id: "centerText",
//         afterDraw: (chart) => {
//             const { ctx, chartArea } = chart;
//             if (!chartArea) return;
//             const { left, right, top, bottom } = chartArea;
//             const width = right - left;
//             const height = bottom - top;
//             const centerX = left + width / 2;
//             const centerY = top + height / 2;
//
//             // 중앙 퍼센트 표시
//             ctx.save();
//             ctx.fillStyle = "#333";
//             ctx.font = "bold 24px Arial";
//             ctx.textAlign = "center";
//             ctx.textBaseline = "middle";
//             ctx.fillText(`${percent}%`, centerX, centerY + 10);
//             ctx.restore();
//
//             // 왼쪽 0
//             ctx.save();
//             ctx.fillStyle = "#333";
//             ctx.font = "12px Arial";
//             ctx.textAlign = "left";
//             ctx.textBaseline = "middle";
//             ctx.fillText("0", left, bottom);
//             ctx.restore();
//
//             // 오른쪽 maxValue
//             ctx.save();
//             ctx.fillStyle = "#333";
//             ctx.font = "12px Arial";
//             ctx.textAlign = "right";
//             ctx.textBaseline = "middle";
//             ctx.fillText(`${maxValue}`, right, bottom);
//             ctx.restore();
//         }
//     };
// }
const centerTextPlugin = (value) => ({
    id: "centerText",
    beforeDraw: (chart) => {
        const {ctx, chartArea} = chart;
        if (!chartArea) return;

        const {width, height} = chartArea;
        const centerX = width / 2;
        const centerY = height / 2;

        ctx.save();
        ctx.fillStyle = "#111"; // 텍스트 색상
        ctx.font = `bold ${Math.min(width, height) * 0.2}px SUIT`; // 텍스트 스타일
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText(`${value}%`, centerX, centerY * 2.5); // 중앙에 텍스트 추가
        ctx.restore();

        // ctx.fillStyle = "#ddd"; // 텍스트 색상
        // ctx.font = `bold ${Math.min(width, height) * 0.08}px Arial`; // 텍스트 스타일
        // ctx.textAlign = "center";
        // ctx.textBaseline = "middle";
        // ctx.fillText("0", width * 0.13, height * 1.25);
        // ctx.fillText("100", width * 0.87, height + 100);
        // ctx.restore();
    }
});
