import React from 'react';
import PropTypes from 'prop-types';

/**
 * StatesHeading.js
 *
 * 사용 예시:
 * <StatesHeading stateLevel={3}>
 *   경계단계
 * </StatesHeading>
 *
 * 설명:
 * - stateLevel (0~4)만 prop으로 받아, 해당 값에 따라 CSS 클래스가 적용됩니다.
 * - CSS에서 각 상태별 색상과 제목 앞의 점(•) 스타일을 지정합니다.
 * - 폰트 크기는 16px로 고정되어 있습니다.
 */


function StatesHeading({ stateLevel = 0, children }) {
    return (
        <h3 className={`c-states-heading c-states-heading--state${stateLevel}`}>
            {children}
        </h3>
    );
}

StatesHeading.propTypes = {
    /** 상태 레벨 (0~4) */
    stateLevel: PropTypes.oneOf([0, 1, 2, 3, 4]),
    /** 제목 내용 */
    children: PropTypes.node,
};

export default StatesHeading;
