/*!

=========================================================
* Black Dashboard React v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import AdminLayout from "layouts/Admin/Admin.js";
import Login from "./views/Login";
import DesignLayout from "./views/DesignLayout";

// import "assets/scss/black-dashboard-react.scss";
// import "assets/demo/demo.css";
// import "assets/css/nucleo-icons.css";
// import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/css/design/common.css";
import "./assets/css/design/components.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";

import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <QueryClientProvider client={queryClient}>
    <ThemeContextWrapper>
      <BackgroundColorWrapper>
        <BrowserRouter>
          <Routes>
            <Route path="/admin/*" element={<AdminLayout />} />
            <Route path="/login/*" element={<Login />} />
            <Route path="/design/*" element={<DesignLayout />} />
            <Route path="*" element={<Navigate to="/design" replace />} />
          </Routes>
        </BrowserRouter>
      </BackgroundColorWrapper>
    </ThemeContextWrapper>
  </QueryClientProvider>,
);
