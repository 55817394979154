import React from 'react';
import PropTypes from 'prop-types';
import Badge from './Badge'; // 이미 만들어둔 Badge 컴포넌트

/**
 * GroupMonitoringCard.js
 *
 * 사용 예시:
 * <GroupMonitoringCard
 *   title="효서로 145번길"
 *   totalPoints={6}
 *   badges={[
 *     { text: "정상 : 6", grade: 0, variant: "solid", size: "small" },
 *     { text: "관심 : 6", grade: 1, variant: "solid", size: "small" },
 *     { text: "주의 : 6", grade: 2, variant: "solid", size: "small" },
 *     { text: "경계 : 6", grade: 3, variant: "solid", size: "small" },
 *     // 최대 4개까지만 사용
 *   ]}
 * />
 *
 * 설명:
 * - title: 상단 제목 (예: 도로명 주소)
 * - totalPoints: "지점: X" 형태로 표시
 * - badges: Badge 컴포넌트에 전달할 props(최대 4개)
 * - 배지는 2×2 그리드로 가운데 정렬됩니다.
 */

function GroupMonitoringCard({ title, totalPoints, badges = [] }) {
    return (
        <div className="c-group-monitoring">
            {/* 상단 제목 */}
            <h3 className="c-group-monitoring__title">{title}</h3>

            <div className="c-group-monitoring__content">
                {/* 총 지점 수 */}
                <div className="c-group-monitoring__info">
                    <span className="c-group-monitoring__label">지점: {totalPoints}</span>
                </div>

                {/* 배지 영역 */}
                <div className="c-group-monitoring__badges">
                    {badges.map((badgeProps, idx) => (
                        <Badge key={idx} {...badgeProps} />
                    ))}
                </div>
            </div>
        </div>
    );
}

GroupMonitoringCard.propTypes = {
    /** 상단 제목 (도로명 주소 등) */
    title: PropTypes.string.isRequired,
    /** 총 지점 수 */
    totalPoints: PropTypes.number.isRequired,
    /** 배지 정보 배열 (최대 4개) */
    badges: PropTypes.arrayOf(PropTypes.object),
};

export default GroupMonitoringCard;
