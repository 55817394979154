import React, { useEffect, useRef, useMemo } from "react";
import Chart from "chart.js/auto";

export default function ({ manholeData }) {
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);
    const categorizedData = useMemo(() => {
        const data = {
            "a": 0,
            "b": 0,
            "c": 0,
            "d": 0,
            "e": 0
        };

        if (manholeData?.length) {
            manholeData.forEach(({ water }) => {
                if (water > 90) {
                    data["a"] += 1;
                } else if (water > 70) {
                    data["b"] += 1;
                } else if (water > 50) {
                    data["c"] += 1;
                } else if (water > 30) {
                    data["d"] += 1;
                } else {
                    data["e"] += 1;
                }
            });
        }
        return data;
    }, [manholeData]);

    useEffect(() => {
        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy(); // 기존 차트 제거
        }

        const ctx = chartRef.current.getContext("2d");
        chartInstanceRef.current = new Chart(ctx, {
            type: "doughnut",
            data: {
                labels: [" 안전 단계", " 관심 단계", " 주의 단계", " 경계 단계", " 심각 단계"],
                datasets: [
                    {
                        data: [
                            categorizedData["e"],
                            categorizedData["d"],
                            categorizedData["c"],
                            categorizedData["b"],
                            categorizedData["a"]
                        ],
                        backgroundColor: ["#aaa", "#007bff", "#ffc107", "#fd7e14", "#dc3545"],
                        borderWidth: 0,
                        cutout: "50%",
                    },
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                rotation: 0,
                circumference: 360,
                cutout: "0%",
                plugins: {
                    legend: { display: true }, // 범례 표시
                    tooltip: { enabled: true }, // 툴팁 활성화
                    title: {
                        display: true,
                        text: "광진구 침수 위험 상황", // 제목 텍스트
                        font: {
                            size: 40, //
                            weight: "bold"
                        },
                        color: "#ddd",
                        padding: 20
                    }
                }
            },
            plugins: [centerTextPlugin(categorizedData)]
        });

        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
                chartInstanceRef.current = null;
            }
        };
    }, [categorizedData]);

    return <canvas ref={chartRef}></canvas>;
}

const centerTextPlugin = (data) => ({
    id: "centerText",
    // beforeDraw: (chart) => {
    //     const { ctx, chartArea } = chart;
    //     if (!chartArea) return;
    //
    //     const { width, height } = chartArea;
    //     const centerX = width / 2;
    //     const centerY = height / 2;
    //
    //     ctx.save();
    //     ctx.fillStyle = "#ddd";
    //     ctx.font = `bold ${Math.min(width, height) * 0.01}rem Arial`;
    //     ctx.textAlign = "center";
    //     ctx.textBaseline = "middle";
    //     ctx.fillText(`${chart.data.datasets[0].data[4]}`, centerX, centerY * 1.6);
    //     ctx.restore();
    // }
});