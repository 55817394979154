import SimpleTable from "../components/Table/SimpleTable.js";

export default function () {
  const name = "동행서비스 로그";
  const header = ["일시", "이름(아이디)", "활동여부"];
  const data = [
    {
      "일시": "240204 09:06:00",
      "이름(아이디)": "이O빈(SD001)",
      "활동여부": "HH2 단계 발송",
    },
    {
      "일시": "240204 09:06:00",
      "이름(아이디)": "이O빈(SD001)",
      "활동여부": "HH2 단계 발송",
    },
    {
      "일시": "240204 09:06:00",
      "이름(아이디)": "이O빈(SD001)",
      "활동여부": "HH2 단계 발송",
    },
    {
      "일시": "240204 09:06:00",
      "이름(아이디)": "이O빈(SD001)",
      "활동여부": "HH2 단계 발송",
    },
  ];
  return SimpleTable(name, header, data);
}
