import { Map, View } from "ol";
import { Attribution, defaults as defaultControls } from "ol/control.js";
import TileLayer from "ol/layer/Tile";
import { fromLonLat } from "ol/proj";
import OSM from "ol/source/OSM";
import React, { useEffect, useRef } from "react";
import "ol/ol.css";


import {dataSpotDetailList, ManholeInfoByList} from "../../mockData/api/prediction/mockManholeAndRepInformation";
import {CardBody} from "reactstrap";
import {ManholeInfoModal, ManholeInfoTable, ManholeList} from "../Table/MapManholeTable";

const attribution = new Attribution({
  collapsible: false,
});

export default function ({bwProp = {}}) {
  console.log(bwProp);
  const mapRef = useRef(null);

  useEffect(() => {
    // 서울의 경도와 위도
    const seoulCoordinates = fromLonLat([126.977, 37.565]);

    // OpenLayers 맵 생성
    const map = new Map({
      controls: defaultControls({ attribution: false }).extend([attribution]),
      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      view: new View({
        center: seoulCoordinates,
        zoom: 14,
      }),
    });

    const stopPageScroll = (e) => {
      e.preventDefault();
    };

    const mapContainer = mapRef.current;
    mapContainer.addEventListener("wheel", stopPageScroll, { passive: false });

    return () => {
      mapContainer.removeEventListener("wheel", stopPageScroll);
      map.setTarget(null);
    };
  }, []);

  // 지도2 작업 중지
  return (<>
    <div className="bw-flex" style={{padding:"10px"}}>
      <div className="bw-flex--7 bw-flex-custom">
        <div className="card">
          <CardBody>
            <div ref={mapRef} style={{width: "100%", height: "100%", position: "relative"}}>
              <ManholeInfoTable/>
              <ManholeInfoModal/>
            </div>
          </CardBody>
        </div>
      </div>
      <div className="bw-flex--3">
        <div className="card">
          <CardBody>
            <ManholeList dataSpotDetailList={dataSpotDetailList} />
          </CardBody>
        </div>
      </div>
    </div>
  </>);

}

