import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from './Checkbox';

/**
 * CheckboxGroup 컴포넌트
 *
 * 사용 예시:
 * <CheckboxGroup
 *   options={[
 *     { label: '옵션 A', value: 'A' },
 *     { label: '옵션 B', value: 'B' },
 *     { label: '옵션 C', value: 'C' },
 *   ]}
 *   selectedValues={['A', 'C']}
 *   onChange={(newSelected) => console.log('선택된 값들:', newSelected)}
 *   disabled={false}
 * />
 */
const CheckboxGroup = ({ options, selectedValues, onChange, disabled }) => {
    const handleChange = (value, checked) => {
        if (checked) {
            onChange([...selectedValues, value]);
        } else {
            onChange(selectedValues.filter(item => item !== value));
        }
    };

    return (
        <div className="c-checkbox-group">
            {options.map(option => (
                <Checkbox
                    key={option.value}
                    label={option.label}
                    checked={selectedValues.includes(option.value)}
                    onChange={(checked) => handleChange(option.value, checked)}
                    disabled={disabled}
                />
            ))}
        </div>
    );
};

CheckboxGroup.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
        })
    ).isRequired,
    selectedValues: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

CheckboxGroup.defaultProps = {
    selectedValues: [],
    disabled: false,
};

export default CheckboxGroup;
