import React from "react";
import {formatDateType, formatHumidity, formatOpenCheck, formatWaterLevel} from "../../utils/expressionForm";

export const DataMngTable = ({dataRows}) => {
    return (<>
        <tbody>
        {dataRows.map((row, index) => (
            <tr key={`tr${index+1}`}>
                {createTd(row)}
            </tr>
        ))}
        </tbody>
    </>);
};

function createTd(row) {
    return (<>
        <td className="time">{formatDateType(row.time)}</td>
        <td className="waterLevel">{formatWaterLevel(row.waterLevel)}</td>
        <td className="afterOne">{formatWaterLevel(row.afterOne)}</td>
        <td className="afterTen">{formatWaterLevel(row.afterTen)}</td>
        <td className="afterThirty">{formatWaterLevel(row.afterThirty)}</td>
        <td className="temperature">{row.temperature}°C</td>
        <td className="humidity">{formatHumidity(row.humidity)}</td>
        <td className="open">{formatOpenCheck(row.open)}</td>
    </>);
}
